import { useContext, useState } from 'react';
import { CommandBatch } from 'commands/Commands';
import { EditContext } from 'context/EditContext';
import MediaPickerDrawer from 'components/Media/MediaPickerDrawer';
import { useEffect } from 'react';
import MediaSvg from 'components/Svg/MediaSvg';
import CloseSvg from 'components/Svg/CloseSvg';
import Image from 'components/Image';
import { SetLocalMedia, SetUmbracoMedia } from 'commands/media/ChangeMediaField';
import { IMedia } from 'models/media';
import { isImageExtension } from 'components/util/isImageExtension';

type Props = {
  commands?: CommandBatch;
  mediaType: string;
  value: string;
  id: string;
  label: string | null;
  isField?: boolean;
  mediaName?: string | null;
  onSelectLocalMedia?: (media: IMedia) => void;
  onSelectUmbracoMedia?: (media: IMedia) => void;
  onClearMedia?: () => void;
};

const MediaInput = ({
  commands,
  mediaType,
  value,
  label,
  id,
  onSelectLocalMedia,
  onSelectUmbracoMedia,
  onClearMedia,
  mediaName = null,
  isField = false
}: Props) => {
  const [toggle, setToggle] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<string>(value);
  const { setDirty } = useContext(EditContext);
  const [selectedMediaName, setSelectedMediaName] = useState(mediaName);

  const handleClearMedia = () => {
    setSelectedMedia('');
    onClearMedia ? onClearMedia() : commands?.add(new SetUmbracoMedia(id, { umbracoId: null, url: null }));
  };

  const selectLocalMediaHandler = (media: IMedia) => {
    onSelectLocalMedia ? onSelectLocalMedia(media) : commands?.add(new SetLocalMedia(id, media.id));
    updateCurrent(media);
  };
  const selectUmbracoMediaHandler = (media: IMedia) => {
    onSelectUmbracoMedia ? onSelectUmbracoMedia(media) : commands?.add(new SetUmbracoMedia(id, { umbracoId: parseInt(media.id), url: media.url }));
    updateCurrent(media);
  };

  const isImageExtensionPDF = (extension: string) => {
    if (extension == null) {
      return false;
    }
    const imageExtensions = '.pdf';
    return extension.includes(imageExtensions);
  };

  const updateCurrent = (media: IMedia) => {
    setSelectedMediaName(media.name);
    setSelectedMedia(media.url);
    setDirty(true);
    setToggle(false);
  };

  useEffect(() => {
    setSelectedMedia(value);
  }, [value]);

  useEffect(() => {
    return () => {
      if (value) {
        setSelectedMedia('');
      }
    };
  }, [value]);

  const fileNameOrImage = isImageExtension(selectedMedia!) ? (
    <>
      <p>
        <b>File name:</b> {selectedMediaName}
      </p>
      <Image src={selectedMedia} className='object-cover' style={{ maxWidth: '50%', height: '350px' }} alt='selected media item' />
    </>
  ) : isImageExtensionPDF(selectedMedia!) ? (
    <p>
      <u>
        <a style={{ color: 'blue' }} href={selectedMedia} target='_blank' rel='noreferrer'>
          {selectedMediaName}
        </a>
      </u>
    </p>
  ) : (
    <p>No media to display</p>
  );

  return (
    <div className='py-5'>
      {fileNameOrImage}
      {selectedMedia ? (
        <button
          onClick={handleClearMedia}
          type='button'
          className='inline-flex
      mx-2 my-2  items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white  bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <CloseSvg />
          Clear {mediaType ? mediaType : label}
        </button>
      ) : (
        <button
          onClick={() => setToggle(true)}
          type='button'
          className='inline-flex
      mx-2 my-2  items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white  bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <MediaSvg />
          Choose a {mediaType ? mediaType : label}
        </button>
      )}
      {toggle && (
        <MediaPickerDrawer
          toggleHandler={() => setToggle(false)}
          onLocalMediaSelected={selectLocalMediaHandler}
          onUmbracoMediaSelected={selectUmbracoMediaHandler}
        />
      )}
    </div>
  );
};

export default MediaInput;
