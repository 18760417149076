import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import InformationCard from 'components/InformationCard';
import Layout from 'components/Layout/Layout';
import ChevronRight from 'components/Svg/ChevronRight';

const BusinessNotFoundScreen = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  React.useEffect(() => {
    if (user.corporateUser) {
      setTimeout(() => {
        history.push('/admin/businesses');
      }, 3000);
    }
  }, [user, history]);
  return (
    <Layout pageTitle='Business not found'>
      <div className='max-w-7xl mx-auto'>
        <InformationCard>
          Cannot find business, it probably doesn't exist.
          {!user.corporateUser ? ' Please select a business below!' : ''}
        </InformationCard>

        <div>
          {!user.corporateUser ? (
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
              <table className='min-w-full  divide-y divide-gray-200'>
                <thead className='bg-blue-tint'>
                  <tr>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Business Name
                    </th>

                    <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      {' '}
                    </th>
                  </tr>
                </thead>

                {user.businesses.map(business => (
                  <tr
                    key={business}
                    onClick={() => history.push(`/${business}`)}
                    className='transition cursor-pointer bg-white hover:bg-gray-50 text-gray-600 hover:text-blue-lm'
                  >
                    <td className='px-6 py-4 whitespace-nowrap'>
                      <div className='flex items-center'>
                        <div className='text-lg font-medium'>{business.charAt(0).toUpperCase() + business.slice(1)}</div>
                      </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                      <div className='flex justify-end'>
                        <div className='text-lg font-medium '>
                          <ChevronRight />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          ) : (
            <h3 className='text-lg font-medium text-gray-600'>You will be redirected to the businesses screen in 3 seconds</h3>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default BusinessNotFoundScreen;
