import React, { useState, useContext } from 'react';
import MediaPickerDrawer from 'components/Media/MediaPickerDrawer';
import { EditContext } from 'context/EditContext';
import { UserContext } from 'context/UserContext';

import { CommandBatch, ICommand } from 'commands/Commands';
import { ChangeColor } from 'commands/business/Colors/ChangeColor';
import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';
import Image from 'components/Image';
import { IMedia } from 'models/media';
import TrashSvg from 'components/Svg/TrashSvg';

type Props<TCommand extends ICommand> = {
  initialVal: string | null;
  commands: CommandBatch;
  commandFactory: (value: string) => TCommand;
  label: string;
};

const BusinessImagePicker = ({ initialVal, commands, label, commandFactory }: Props<ChangeColor>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialVal ? initialVal : '');
  const { user } = useContext(UserContext);
  const { setDirty } = useContext(EditContext);
  const handleSelectedValue = (Media: IMedia) => {
    setValue(Media.url);
    commands.add(commandFactory(Media.url));
    setToggle(false);
    setDirty(true);
  };

  const handleClearValue = () => {
    setValue('');
    commands.add(commandFactory(''));
    setDirty(true);
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center items-center'>
        <h3 className='block text-lg text-gray-700 p-1 '>{label}:</h3>
        <div className='flex items-center justify-center'>
          <Image className='rounded-full w-12 h-12 object-cover' src={value} alt='business logo' />
          {isEditing ? (
            <>
              <button
                className='items-center px-4 py-2  my-2  mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
                onClick={() => setToggle(true)}
              >
                Select a {label}
              </button>
              {value !== '' ? (
                <button className='flex text-red-500 items-center space-x-2 mx-4 hover:opacity-50 transition-all' onClick={handleClearValue}>
                  remove <TrashSvg />
                </button>
              ) : null}
            </>
          ) : null}
          {user.corporateUser && <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} />}
        </div>
      </div>
      {toggle && <MediaPickerDrawer toggleHandler={() => setToggle(false)} onLocalMediaSelected={handleSelectedValue} onUmbracoMediaSelected={handleSelectedValue} />}
    </>
  );
};

export default BusinessImagePicker;
