import { Command } from 'commands/Commands';

export class RequestApproval extends Command {
  type: string = 'Partner.RequestApproval';
  priority: number = 150;
}

export class Approve extends Command {
  type: string = 'Partner.Approve';
  priority: number = 150;
}

export class Reject extends Command {
  type: string = 'Partner.Reject';
  priority: number = 150;
}
