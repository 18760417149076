import React, { useState } from 'react';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import ChevronRight from 'components/Svg/ChevronRight';
import ChildItemSvg from 'components/Svg/ChildItemSvg';
import ClipboardCheckSvg from 'components/Svg/ClipboardCheckSvg';
import EyeSvg from 'components/Svg/EyeSvg';
import PencilSvg from 'components/Svg/PencilSvg';
import { previewUrlGenerator } from 'components/util/previewUrlGenerator';

import { BusinessApprovalSummary } from 'models/approval';
import { Link } from 'react-router-dom';
import { generateStartEditPath } from 'components/util/generateStartEditPath';
import { showPreview } from 'components/util/showPreview';
import TableData from 'components/Table/TableData';

const ApprovalLogItem = ({ slug, approvalsRequired }: BusinessApprovalSummary) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const { data: businessAuth } = useBusinessAuth(slug);

  return (
    <>
      <tr className='px-6 py-4 whitespace-nowrap cursor-pointer bg-white hover:bg-gray-50' onClick={() => setShowDropDown(!showDropDown)}>
        <TableData>{slug}</TableData>
        <TableData>/{slug}</TableData>
        <TableData className='px-2 inline-flex text-xs font-semibold rounded-full text-blue-lm items-center'>
          <ClipboardCheckSvg className='ml-1' /> {approvalsRequired.length} Changes Awaiting Approval
        </TableData>
        <td className='px-6 py-4 whitespace-nowrap '>
          <div className='flex items-center justify-end'>
            <div className={`font-light text-gray-900 ${showDropDown ? 'transform rotate-90' : ''} transition`}>
              <ChevronRight className={'text-gray-400'} />
            </div>
          </div>
        </td>
      </tr>
      {showDropDown && businessAuth ? (
        <tr>
          <td colSpan={4} className='p-3  bg-blue-tint'>
            <table className='min-w-full divide-y divide-gray-200 pb-5'>
              <tbody className='bg-blue-tint divide-y divide-gray-200'>
                {approvalsRequired.map(detail => (
                  <tr key={detail.entityType + detail.identifier + slug} className='items-center '>
                    <td className=' items-center  py-3 text-left text-xs font-medium text-gray-500 '>
                      <div className='flex item-center'>
                        <ChildItemSvg />
                        <span className='px-2 py-1 '>{detail.title}</span>
                        <span className='bg-white ml-3 px-2 py-1 rounded-full text-blue-lm '>{detail.entityType}</span>
                      </div>
                    </td>

                    <td className='px-3 py-3 text-left text-xs font-medium text-gray-500 text-right'>
                      <div>
                        <Link
                          to={
                            detail.entityType === 'Media'
                              ? { pathname: `/${slug}/media`, state: { open: true, id: detail.identifier } }
                              : `/${slug}/${generateStartEditPath(detail.entityType)}${detail.identifier}`
                          }
                          className='inline-flex group bg-white py-1 px-3 text-xs font-medium  items-center rounded-full text-gray-600 hover:bg-blue-lm transition'
                          target={detail.entityType === 'Media' ? '' : '_blank'}
                          rel='noreferrer'
                        >
                          <PencilSvg className='w-5 h-5 mr-2 group-hover:text-white transition' outline={true} />
                          <span className='group-hover:text-white transition'>Review or edit</span>
                        </Link>
                      </div>
                    </td>
                    <td className='whitespace-nowrap w-10'>
                      <div className='transition'>
                        {showPreview(detail.entityType) ? (
                          <a
                            href={previewUrlGenerator(
                              businessAuth?.public,
                              businessAuth?.byob,
                              businessAuth?.slug,
                              slug,
                              detail.entityType === 'BlogArticle' ? `blog/${detail.identifier}` : '',
                              businessAuth?.previewUrl
                            )}
                            className='inline-flex group bg-white py-1 px-3 text-xs font-medium  items-center rounded-full text-gray-600 hover:bg-blue-lm transition'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <EyeSvg isHidden={false} className='w-5 h-5 mr-2 group-hover:text-white transition' />
                            <span className='group-hover:text-white transition'>Preview</span>
                          </a>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default ApprovalLogItem;
