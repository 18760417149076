import { Command } from './Commands';
import { v4 as newGuid } from 'uuid';
import { FieldType } from 'models/Field';

export class AddProperty extends Command {
  type: string = 'Page.AddProperty';
  constructor(
    public page: string,
    public fieldType: FieldType,
    public name: string,
    public displayName: string
  ) {
    super('', newGuid());
  }
  priority: number = 75;
}
