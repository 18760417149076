import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { ChangeAwardImage } from 'commands/award/ChangeAwardImage';
import { ChangeAwardAlt } from 'commands/award/ChangeAwardAlt';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import { Award } from 'models/award';
import { useSelectedAwards } from 'api/queries/useAwards';

import Layout from 'components/Layout/Layout';
import MediaInput from 'components/Inputs/MediaInput';
import TextInput from 'components/Inputs/TextInput';
import ApprovalButtons from 'components/Buttons/ApprovalButtons';
import { Approve, Reject, RequestApproval } from 'commands/award/AwardApproval';
import { useTimedGoBack } from 'hooks/useTimedGoBack';
import ErrorModal from 'components/Modals/ErrorModal';
import { useCommands } from 'hooks/useCommands';
import { blankMedia, IMedia } from 'models/media';

const AwardEditScreen = () => {
  const { id } = useParams<{ id: string }>();

  const { commands, showError, showSuccess, errorMessage, setShowError, setShowSuccess } = useCommands();

  useTimedGoBack(showSuccess);
  const { businessName } = useParams<{ businessName: string }>();
  const { data, error, isError } = useSelectedAwards(businessName);
  const [awardContent, setAwardContent] = useState<Award>();

  const changeAwardState = (propertyName: 'imageUrl' | 'altText', value: string) => {
    if (awardContent) {
      const modifiedAward = {
        ...awardContent,
        [propertyName]: value
      };
      setAwardContent(modifiedAward);
    }
  };
  useEffect(() => {
    if (data) {
      const awardDetails = data.filter(award => award.id === id)[0];
      setAwardContent(awardDetails!);
    }
  }, [data, id]);

  const clearImage = () => handleChangeImage(blankMedia);

  const handleChangeImage = (media: IMedia) => {
    changeAwardState('imageUrl', media.url);
    commands.add(new ChangeAwardImage(media.url, id));
  };

  const handleChangeAlt = (value: string) => {
    changeAwardState('altText', value);
    commands.add(new ChangeAwardAlt(value, id));
  };

  return (
    <Layout
      pageTitle={'Editing Award'}
      buttons={
        <ApprovalButtons
          approval={awardContent?.approval}
          addApproveCommand={message => commands.add(new Approve(message, id)).send()}
          addRejectCommand={message => commands.add(new Reject(message, id)).send()}
          addRequestApprovalCommand={() => commands.add(new RequestApproval('', id)).send()}
          addPublishCommand={message => commands.add(new Approve(message, id)).send()}
          save={() => commands.send()}
        />
      }
    >
      <ErrorModal open={isError} error={error} />
      <NotificationMessage type={NotificationStyles.Danger} show={showError} setShow={setShowError} message={errorMessage} />
      <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='Successfully sent!' />
      {awardContent && (
        <>
          <MediaInput
            commands={commands}
            mediaType='Award Image'
            id='ChangeImage'
            label='Award Image'
            onSelectUmbracoMedia={handleChangeImage}
            onSelectLocalMedia={handleChangeImage}
            onClearMedia={clearImage}
            value={awardContent!.imageUrl}
          />
          <TextInput value={awardContent!.altText} id='ChangeAlt' label='Image Alt Text' handleFieldValue={handleChangeAlt} />
        </>
      )}
    </Layout>
  );
};

export default AwardEditScreen;
