import SearchInput from 'components/SearchInput';
import { useSearchState } from 'hooks/useSearchState';
import { BusinessApprovalSummary } from 'models/approval';
import React from 'react';

import ApprovalLogItem from './ApprovalLogItem';

type ApprovalLogProps = {
  summaries: BusinessApprovalSummary[];
};
const ApprovalLogTable = ({ summaries }: ApprovalLogProps) => {
  const { handleSearch, handleSearchSubmit, searchResults, searchValue } = useSearchState(summary => summary.name, summaries);

  return (
    <div className='flex flex-col'>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <SearchInput
            searchValue={searchValue}
            placeholder='Search by business name'
            handleSearch={handleSearch}
            handleSearchSubmit={handleSearchSubmit}
          />
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
            <table className='min-w-full  divide-y divide-gray-200'>
              <thead className='bg-blue-tint'>
                <tr>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Business
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Slug
                  </th>

                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Awaiting Approvals
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'></th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {searchResults.map(summary => (
                  <ApprovalLogItem
                    slug={summary.slug}
                    name={summary.name}
                    approvalsRequired={summary.approvalsRequired}
                    key={summary.approvalsRequired + summary.slug}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalLogTable;
