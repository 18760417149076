import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ActivityStatus from './ActivityStatus';
import { formatDate } from 'components/util/formatDate';
import EyeSvg from 'components/Svg/EyeSvg';
import PencilSvg from 'components/Svg/PencilSvg';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import { previewUrlGenerator } from 'components/util/previewUrlGenerator';
import { generateStartEditPath } from 'components/util/generateStartEditPath';
import { Activity } from 'models/approval';
import { showPreview } from 'components/util/showPreview';

type Props = {
  activity: Activity;
};

const ActivityItem = ({ activity }: Props) => {
  const { operation, entityType, date, title, identifier } = activity;
  const { businessName } = useParams<{ businessName: string }>();
  const { data: businessAuthData } = useBusinessAuth(businessName);

  return (
    <li>
      <div className='relative pb-8'>
        <span className='absolute top-4 left-4 -ml-px w-0.5 bg-gray-200' aria-hidden='true'></span>
        <div className='relative  space-x-3  z-10'>
          <div className='min-w-0 flex-1 pt-1.5  bg-white  shadow w-full p-2 rounded'>
            <div>
              <div className='flex justify-between w-full text-gray-600'>
                <ActivityStatus status={operation} />
                <span className='text-sm'>{formatDate(date)}</span>
              </div>
              <div className='flex justify-between items-center'>
                <div className='text-sm text-gray-600'>
                  <span className='ml-1 block'>Type: {entityType}</span>
                  <span className='ml-1 block font-medium'>{title}</span>
                </div>
                <div className='text-sm text-gray-500'>
                  {showPreview(entityType) && businessAuthData ? (
                    <a
                      href={previewUrlGenerator(
                        businessAuthData.public,
                        businessAuthData.byob,
                        businessAuthData.slug,
                        businessName,
                        identifier,
                        businessAuthData.previewUrl
                      )}
                      className='flex my-2 hover:text-gray-600 hover:bg-gray-200 py-0.5 px-1 rounded transition-all'
                    >
                      <EyeSvg isHidden={false} className='w-5 h-5 mr-1' />
                      <span>Preview</span>
                    </a>
                  ) : null}
                  <Link
                    to={
                      entityType === 'Media'
                        ? { pathname: `${businessName}/media`, state: { open: true, id: identifier } }
                        : `/${businessName}/${generateStartEditPath(entityType)}${identifier}`
                    }
                    className='flex my-2 hover:text-gray-600 hover:bg-gray-200 py-0.5 px-1 rounded transition-all'
                  >
                    <PencilSvg className='w-5 h-5 mr-1' />
                    <span>Edit</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ActivityItem;
