import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';

type Props = {
  modalType: 'approve' | 'reject';
  title: string;
  description: string;
  message: string;
  setMessage: (state: string) => void;
  sendHandler: () => void;
  onClose: () => void;
  open: boolean;
};

const MessageModal = (props: Props) => {
  const cancelButtonRef = useRef<HTMLDivElement>(null);

  return (
    <Transition show={props.open} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' initialFocus={cancelButtonRef} static open={props.open} onClose={props.onClose}>
        <div className='min-h-screen px-4 text-center'>
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md '>
              <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                {props.title}
              </Dialog.Title>
              <Dialog.Description className='text-grey-300'>{props.description}</Dialog.Description>
              <div className='mt-2'>
                <textarea
                  className='flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 min-w-0 rounded-md sm:text-sm p-3 h-52'
                  value={props.message}
                  onChange={e => props.setMessage(e.target.value)}
                />
              </div>

              <div className='mt-4'>
                <button
                  type='button'
                  className={`inline-flex justify-center px-4 py-2 text-sm font-medium ${
                    props.modalType === 'approve' ? 'text-blue-900 bg-blue-100' : ' text-red-900 bg-red-100'
                  } border border-transparent rounded-md hover:${
                    props.modalType === 'approve' ? 'bg-blue-200' : 'bg-red-200'
                  } focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500`}
                  onClick={props.onClose}
                >
                  Cancel
                </button>
                <button
                  disabled={props.message === '' ? true : false}
                  type='button'
                  className={`inline-flex justify-center px-4 py-2  ml-3 text-sm font-medium text-white ${
                    props.modalType === 'approve' ? 'bg-blue-lm' : 'bg-red-600'
                  }  border border-transparent rounded-md hover:${
                    props.modalType === 'approve' ? 'bg-blue-dark' : 'bg-red-700'
                  } focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50 ${
                    props.message === '' ? 'cursor-not-allowed' : ''
                  }`}
                  onClick={props.sendHandler}
                >
                  {props.modalType === 'approve' ? 'Approve' : 'Submit Rejection'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MessageModal;
