import React from 'react';
import CheckSvg from 'components/Svg/CheckSvg';
import CloseSvg from 'components/Svg/CloseSvg';

import { Business } from 'models/business';
import { Link } from 'react-router-dom';

type BusinessTableItemProps = {
  index: number;
  business: Business;
};

const BusinessTableItem = (props: BusinessTableItemProps) => {
  const { displayName, name, slug, byob, hasBlog, hasCustomPages, visible } = props.business;
  const index = props.index;

  return (
    <tr className={`${index! % 2 === 0 ? 'bg-white' : ' bg-gray-50'}`}>
      <td className='px-6 py-4'>
        <div className='flex items-center'>
          <div className=''>
            <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{displayName}</div>
            <div className='text-sm text-gray-500'>{name}</div>
          </div>
        </div>
      </td>
      <td className='px-6 py-4 whitespace-nowrap'>
        {hasCustomPages ? (
          <span className='text-blue-lm'>
            <CheckSvg />
          </span>
        ) : (
          <span className='text-gray-500'>
            <CloseSvg />
          </span>
        )}
      </td>
      <td className='px-6 py-4 whitespace-nowrap'>
        {hasBlog ? (
          <span className='text-blue-lm'>
            <CheckSvg />
          </span>
        ) : (
          <span className='text-gray-500'>
            <CloseSvg />
          </span>
        )}
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-sm'>
        {byob ? (
          <span className='text-blue-lm'>
            <CheckSvg />
          </span>
        ) : (
          <span className='text-gray-500'>
            <CloseSvg />
          </span>
        )}
      </td>
      <td className='px-6 py-4 text-sm text-gray-500'>{slug}</td>
      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
        {visible ? (
          <span className='px-3 py-1 bg-green-100 text-green-700 rounded-full'>live</span>
        ) : (
          <span className='px-3 py-1 bg-gray-400 text-white rounded-full'>onboarding</span>
        )}
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
        <Link to={`/${slug}`} className='text-blue-lm hover:text-blue-dark' target='_blank'>
          Go into business
        </Link>
      </td>
    </tr>
  );
};

export default BusinessTableItem;
