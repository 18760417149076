import { DropResult } from 'react-beautiful-dnd';

export function rePositionItem<TObject>(
  dropResult: DropResult,
  source: TObject[]
) {
  // if drag is stopped do nothing
  if (!dropResult.destination) return source;

  const cloneState = Array.from(source);
  // remove dragged element from array
  const [draggedAward] = cloneState.splice(dropResult.source.index, 1);
  // add dragged element to the array destination index
  cloneState.splice(dropResult.destination.index, 0, draggedAward);
  return cloneState;
}
