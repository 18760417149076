import React, { useState, useContext } from 'react';
import { EditContext } from 'context/EditContext';

import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';
import { Command, CommandBatch, ICommand } from 'commands/Commands';
import { LeadBroker } from 'models/business';
import { useTeamMembers } from 'api/queries/useTeamMembers';
import { useParams } from 'react-router-dom';

type Props<TCommand extends ICommand> = {
  commands: CommandBatch;

  initialLeadBroker: LeadBroker;
  commandFactory: (payload: string) => TCommand;
};

const BusinessLeadBrokerSelect = ({ initialLeadBroker, commands, commandFactory }: Props<Command>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialLeadBroker?.name);

  const { setDirty } = useContext(EditContext);
  const { businessName } = useParams<{ businessName: string }>();
  const { data: membersData } = useTeamMembers(businessName);

  const handleChangeLeadBroker = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const select = e.target;
    setValue(select.value);
    commands.add(commandFactory(select.selectedOptions[0].id));
    setDirty(true);
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
        <h3 className='block text-lg text-gray-700 p-1'>Lead Broker:</h3>
        <div className='flex'>
          {isEditing ? (
            <select className='mx-3 bg-gray-100 rounded-md p-1' value={value ?? 'unselected'} onChange={handleChangeLeadBroker}>
              {!value ? <option value='unselected'>select a broker</option> : null}
              {membersData?.map(member => (
                <option key={member.employeeId} value={member.name} id={member.employeeId}>
                  {member.preferredFirstName && member.lastName ? `${member.preferredFirstName} ${member.lastName}` : member.name}
                </option>
              ))}
            </select>
          ) : (
            <p className='px-3 pt-2'>{value ? value : 'No lead broker selected'}</p>
          )}
          <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} />
        </div>
      </div>
    </>
  );
};

export default BusinessLeadBrokerSelect;
