import { Command } from 'commands/Commands';


export class AddPageRedirect extends Command {
  type: string = 'AddPageRedirect';
  constructor(public newValue: string[]) {
    super(newValue)
  }
  priority: number = 100;

}
