import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import EyeSvg from './Svg/EyeSvg';
import { previewUrlGenerator } from './util/previewUrlGenerator';

type Props = {
  businessName: string;
  slug: string;
};
const PreviewLink = ({ businessName, slug }: Props) => {
  const { data, isLoading } = useBusinessAuth(businessName);

  if (isLoading) return <p className='text-xs font-medium items-center mr-5 py-2 px-2 text-gray-500'>loading</p>;
  return (
    <a
      href={previewUrlGenerator(data!.public, data!.byob, data!.slug, businessName, slug, data!.previewUrl)}
      target='_blank'
      className='text-xs font-medium items-center mr-5 py-2 px-2 text-gray-500 hover:text-blue-lm sm:flex hidden'
      rel='noreferrer'
    >
      <EyeSvg isHidden={false} className='mr-2 w-5 h-5' />
      <span>Preview</span>
    </a>
  );
};

export default PreviewLink;
