import React, { FunctionComponent } from 'react';
import InfoSvg from 'components/Svg/InfoSvg';
import CloseSvg from 'components/Svg/CloseSvg';

type DrawerProps = {
  toggleHandler: () => void;
  description: string;
  title: string;
};
const Drawer: FunctionComponent<DrawerProps> = ({ toggleHandler, children, description, title }) => {
  return (
    <div className='fixed inset-0 overflow-hidden z-50'>
      <div className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' aria-hidden={true} onClick={toggleHandler} />
      <section className='absolute inset-y-0 right-0 pl-10 max-w-full flex ' aria-labelledby='slide-over-heading'>
        <div className='w-screen max-w-lg'>
          <div className='h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll'>
            <div className='px-4 sm:px-6'>
              <div className='flex justify-between pb-2 align-center'>
                <h2 id='slide-over-heading' className='text-lg  font-medium text-navy first-letter:uppercase'>
                  {title}
                </h2>
                <button
                  className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={toggleHandler}
                >
                  <CloseSvg />
                  <span className='sr-only'>Close panel</span>
                </button>
              </div>
              <div className='flex'>
                <p className='flex items-center text-sm text-gray-500'>
                  <InfoSvg className='block mr-3 text-gray-500 flex-shrink-0' />
                  {description}
                </p>
              </div>
              {children}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Drawer;
