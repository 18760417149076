import React from 'react';
type Props = {
  searchValue: string;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: (e: React.FormEvent) => void;
  placeholder?: string;
};
const SearchInput = ({
  searchValue,
  handleSearch,
  handleSearchSubmit,
  placeholder,
}: Props) => {
  return (
    <form
      onSubmit={handleSearchSubmit}
      className='flex content-center justify-center'
    >
      <input
        type='text'
        placeholder={placeholder ? placeholder : 'Search for...'}
        className='shadow p-3 rounded-md w-full mb-5 max-w-7xl'
        onChange={handleSearch}
        value={searchValue}
      />
    </form>
  );
};

export default SearchInput;
