import CheckSvg from 'components/Svg/CheckSvg';

type Props = {
  addRequestApprovalCommand: () => void;
  enable?: boolean;
  isDropDown?: boolean;
};
const RequestApprovalButton = ({ addRequestApprovalCommand, enable=true, isDropDown = false }: Props) => {
  const handleSubmitRequest = () => {
    addRequestApprovalCommand();
  };

  return (
    <button
      disabled={!enable}
      onClick={handleSubmitRequest}
      className={
        isDropDown
          ? 'group flex rounded-md items-center w-full px-2 py-2 text-sm text-blue-lm hover:bg-blue-light disabled:opacity-50'
          : `inline-flex items-center px-4 py-2 my-2 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50`
      }
    >
      <CheckSvg />
      Submit for Approval
    </button>
  );
};

export default RequestApprovalButton;
