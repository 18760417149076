import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Drawer from 'components/Layout/Drawer';
import MediaFolder from 'components/Media/MediaFolder';
import { useApprovedBusinessMedia, useMediaLibrary } from 'api/queries/useMediaLibrary';
import MediaListItem from './MediaListItem';
import MediaNav from 'components/Media/MediaNav';
import { IMedia } from 'models/media';

type Props = {
  toggleHandler: () => void;
  onLocalMediaSelected: (media: IMedia) => void;
  onUmbracoMediaSelected: (media: IMedia) => void;
  avatar?: boolean;
};

const MediaPickerDrawer = ({ toggleHandler, onLocalMediaSelected, onUmbracoMediaSelected }: Props) => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data } = useMediaLibrary(businessName);
  const { data: businessMediaData } = useApprovedBusinessMedia(businessName);

  const [isLoanMarket, setIsLoanMarket] = useState(true);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.target.value === 'loan-market' ? setIsLoanMarket(true) : setIsLoanMarket(false);
  };

  return (
    <Drawer toggleHandler={toggleHandler} title='choose media' description='Pick a media item'>
      <div className='flex-grow'>
        <div className='sm:hidden'>
          <label htmlFor='tabs' className='sr-only'>
            Select a tab
          </label>
          <select
            id='tabs'
            name='tabs'
            className='block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
            onChange={handleSelectChange}
          >
            <option value='loan-market'>Loan Market Media</option>
            <option value='your-media'>Your Approved Media</option>
          </select>
        </div>
        <div className='hidden sm:block flex-grow w-full'>
          <MediaNav setIsLoanMarket={setIsLoanMarket} isLoanMarket={isLoanMarket} />
        </div>
      </div>
      <ul className='divide-y divide-gray-200'>
        {isLoanMarket ? (
          <>
            {data?.folders.map(folder => (
              <MediaFolder
                handleSelectedMedia={onUmbracoMediaSelected}
                folders={folder.folders}
                key={folder.id}
                id={folder.id}
                name={folder.name}
                items={folder.items}
              />
            ))}
          </>
        ) : !businessMediaData || businessMediaData!.length <= 0 ? (
          <p>No media</p>
        ) : (
          businessMediaData?.map(item => <MediaListItem isChild={false} item={item} key={item.id} handleSelectedMedia={onLocalMediaSelected} />)
        )}
      </ul>
    </Drawer>
  );
};

export default MediaPickerDrawer;
