import { Command } from 'commands/Commands';

export class RequestApproval extends Command {
  type: string = 'Award.RequestApproval';
  priority: number = 150;
}

export class Approve extends Command {
  type: string = 'Award.Approve';
  priority: number = 150;
}

export class Reject extends Command {
  type: string = 'Award.Reject';
  priority: number = 150;
}
