const REDIRECT_URI = `${window.location.origin}/login/callback`;

const config = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: process.env.REACT_APP_ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: false,
};
//   resourceServer: {
//     messagesUrl: 'http://localhost:8000/api/messages',
//   },

export default config;
