import Layout from 'components/Layout/Layout';

import TeamMemberList from 'components/TeamMember/TeamMemberList';
import BarLoader from 'react-spinners/BarLoader';
import { useDirtyPageCleanUp } from 'components/util/useDirtyPageCleanUp';
import { useParams } from 'react-router-dom';
import { useTeamMembers } from 'api/queries/useTeamMembers';
import ErrorModal from 'components/Modals/ErrorModal';

const TeamScreen = () => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data, isLoading, isError, error } = useTeamMembers(businessName);

  useDirtyPageCleanUp();
  return (
    <>
      <Layout pageTitle={'Your Team'}>
        <ErrorModal open={isError} error={error} />
        <div>
          <div className='space-y-16'>
            <div className='max-w-7xl mx-auto p-8'>
              <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
              {!isLoading && <TeamMemberList teamData={data!} />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TeamScreen;
