import axios from 'axios';
import { User } from 'models/user';
import { generateGetHeaders } from './generateGetHeaders';

// get user details upon login
export const getUserDetails = async () => {
  const token = JSON.parse(
    window.localStorage.getItem('okta-token-storage') || ''
  );
  const email = token.idToken.claims.email;
  const { data } = await axios.get<User>(
    `${process.env.REACT_APP_API}/Portal/User/Login/${email}`,
    generateGetHeaders()
  );
  return data;
};
