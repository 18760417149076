import { useEffect, useState } from 'react';

export function useSearchState<T>(searchKey: (item: T) => string, items: T[], initialValue: string = '') {
  const [searchValue, setSearchValue] = useState(initialValue);
  const [searchResults, setSearchResults] = useState<T[]>([]);

  useEffect(() => {
    setSearchResults(items);
  }, [items]);

  // filter using property key
  const searchFilter = (items: T[], searchValue: string) => {
    const filteredItems = items.filter(item => {
      if (!searchValue) return true;
      return searchKey(item)?.toLowerCase()?.includes(searchValue);
    });
    return filteredItems;
  };

  const search = (value: string) => {
    const filteredItems = searchFilter(items, value.toLowerCase());
    setSearchResults(filteredItems);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(e.target.value);
    // perform search when input value length is greater than 2
    if (value.length === 0 || value.length > 2) {
      search(value);
    }
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    search(searchValue);
  };

  return {
    handleSearch,
    handleSearchSubmit,
    searchResults,
    searchValue
  };
}
