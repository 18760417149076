import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';

import axios from 'axios';
import { Page } from 'models/page';
import { useQuery } from 'react-query';

// Get individual page and it's blocks
export const usePage = (businessName: string, slug: string, getTemplate: boolean = false) => {
  return useQuery<Page, Error>(
    `page ${slug}`,
    async () => {
      const userDetails = await getUserDetails();
      const templateSegment = getTemplate ? 'Template/' : '';
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Pages/${templateSegment}${slug}`,
        generateGetHeaders(userDetails, businessName)
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessName
    }
  );
};
