import { Approval } from './approval';

export interface IMediaFolder {
  id: string;
  name: string;
  folders: IMediaFolder[];
  items: IMedia[];
}
export interface IMedia {
  id: string;
  url: string;
  name: string;
  extension: string;
  bytes: string;
  altText: string | null;
  approval: Approval;
}

export const blankMedia: IMedia = {
  id: '',
  url: '',
  name: '',
  extension: '',
  bytes: '',
  altText: '',
  approval: {
    approved: false,        
    rejected: false,
    requested: false,
    required: false,
  }
}
