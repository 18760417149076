import { retrieveToken } from 'api/retrieveToken';
import axios from 'axios';

import { ChangeAlt } from 'commands/media/ChangeAlt';
import { ChangeMediaFile } from 'commands/media/setMedia';
import { MediaApprove, MediaReject, MediaRequestApproval } from 'commands/media/MediaApproval';
import MessageModal from 'components/Buttons/ButtonModals/MessageModal';
import Image from 'components/Image';
import Drawer from 'components/Layout/Drawer';
import ErrorModal from 'components/Modals/ErrorModal';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import { UserContext } from 'context/UserContext';
import { IMedia } from 'models/media';
import React, { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router';

import DisplaySelectedMedia from './DisplaySelectedMedia';
import AltTextHelp from 'components/AltTextHelp';
import { useDerivedState } from 'hooks/useDerivedState';
import { isImageExtension } from 'components/util/isImageExtension';
import PageSvg from 'components/Svg/PageSvg';
import { useCommands } from 'hooks/useCommands';
import DeleteButton from 'components/Buttons/DeleteButton';
import { DeleteMedia } from 'commands/media/DeleteMedia';

type Props = {
  toggleHandler: () => void;
  selectedMedia: IMedia | null;
};

const EditMediaDrawer = ({ toggleHandler, selectedMedia }: Props) => {
  const { commands, showError, showSuccess, errorMessage, setShowError, setShowSuccess, setErrorMessage } = useCommands();
  const { user } = useContext(UserContext);
  const { businessName } = useParams<{ businessName: string }>();

  const [openApprove, setOpenApprove] = useState(false);
  const [altText, setAltText] = useDerivedState(selectedMedia?.altText ?? '');
  const [openReject, setOpenReject] = useState(false);
  const [approveMessage, setApproveMessage] = useState('You are approved');
  const [rejectionMessage, setRejectionMessage] = useState('You are rejected');
  const [isUpload, setIsUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();

  useEffect(() => {
    if (showSuccess) {
      window.location.reload();
    }
  }, [showSuccess]);

  if (!selectedMedia) return null;

  const handleAltTextChange = (value: string) => {
    commands.add(new ChangeAlt(selectedMedia.id, value));
    setAltText(value);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target.files![0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append('File', selectedFile!);
      formData.append('Slug', businessName);
      axios
        .post<IMedia>(`${process.env.REACT_APP_API}/Portal/Media/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: retrieveToken()
          }
        })
        .then(res => {
          commands.add(
            new ChangeMediaFile(selectedMedia.id, {
              name: res.data.name,
              url: res.data.url,
              bytes: res.data.bytes.toString(),
              extension: res.data.extension
            })
          );

          commands.send();
        })
        .catch(err => {
          setShowError(true);
          setErrorMessage(`${err}`);
          console.debug(err);
        });
    }
  };

  let buttons: JSX.Element;

  if (user.corporateUser) {
    buttons = (
      <>
        <button
          className='mt-5  items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
          onClick={() => setOpenApprove(true)}
        >
          Approve
        </button>
        <button
          className='mt-5  items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
          onClick={() => setOpenReject(true)}
        >
          Reject
        </button>
      </>
    );
  } else {
    buttons = (
      <button
        onClick={() => commands.add(new MediaRequestApproval(approveMessage, selectedMedia.id)).send()}
        className='mt-5  items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
      >
        Save
      </button>
    );
  }

  return (
    <>
      <Drawer
        title='Edit media'
        toggleHandler={toggleHandler}
        description='Media will need to be approved by loan market before
    being displayed'
      >
        <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='success' drawer={true} />
        <ErrorModal open={showError} error={null} errorMessage={errorMessage} />
        <div className='h-56'>
          <button
            onClick={() => setIsUpload(!isUpload)}
            className='inline-block bg-gray-700 px-2 py-1 rounded text-white hover:bg-gray-500 ml-auto my-2 transition-all'
          >
            {isUpload ? 'Cancel change media' : 'Change media'}
          </button>
          {isUpload ? (
            <>
              <DisplaySelectedMedia selectedFile={selectedFile} handleOnChange={handleOnChange} />
              <button
                className='inline-flex items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3 disabled:opacity-50'
                onClick={handleSubmit}
              >
                Click me to upload
              </button>
            </>
          ) : (
            <>
              {isImageExtension(selectedMedia.extension) ? (
                <Image src={selectedMedia.url} alt='Selected Media' className='w-full h-80 mx-auto my-2 object-cover' />
              ) : (
                <PageSvg className='w-32 h-32 text-blue-lm' />
              )}
              {user.corporateUser && (
                <div className='flex justify-between items-center mt-3'>
                  <span className='font-bold text-blue-lm'>Url:</span>
                  <a target='_blank' href={`${selectedMedia!.url}`} className='text-xs text-right underline' rel='noreferrer'>
                    {selectedMedia!.url}
                  </a>
                </div>
              )}
              <label className='block text-md font-medium text-gray-700  my-3' htmlFor='altText'>
                Alt text: <AltTextHelp />
              </label>
              <input
                id='altText'
                type='text'
                className='block border-2 px-2 py-3 w-full rounded-md '
                value={altText}
                onChange={e => handleAltTextChange(e.target.value)}
              />
              {buttons}
              <div>
                {user.corporateUser ? (
                  <DeleteButton
                    addDeleteCommand={() => {
                      commands.add(new DeleteMedia(selectedMedia!.id)).send();
                    }}
                    autoClear={false}
                    fullWidth={true}
                    isMedia={true}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </Drawer>
      <MessageModal
        modalType='approve'
        description='Please write a detailed approval message'
        title='Approval Message'
        message={approveMessage}
        setMessage={setApproveMessage}
        sendHandler={() => commands.add(new MediaApprove(approveMessage, selectedMedia.id)).send()}
        onClose={() => setOpenApprove(false)}
        open={openApprove}
      />
      <MessageModal
        modalType='reject'
        description='Please write a detailed rejection message'
        title='Reject Message'
        message={rejectionMessage}
        setMessage={setRejectionMessage}
        sendHandler={() => commands.add(new MediaReject(rejectionMessage, selectedMedia.id)).send()}
        onClose={() => setOpenReject(false)}
        open={openReject}
      />
    </>
  );
};

export default EditMediaDrawer;
