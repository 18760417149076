import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { BusinessAuthDetails } from 'models/business';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

// Get auth details
export const useBusinessAuth = (businessName: string) => {
  const history = useHistory();

  return useQuery<BusinessAuthDetails, Error>(
    'businessAuth',
    async () => {
      try {
        const userDetails = await getUserDetails();
        const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Businesses/AuthDetails`, generateGetHeaders(userDetails, businessName));
        return data;
      } catch (err: any) {
        if (err.response.status === 500 || err.response.status === 401 || err.response.status === 403) history.push('/business-not-found');
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessName
    }
  );
};
