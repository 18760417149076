import React from 'react';

const AltTextHelp = () => {
  return (
    <span className='text-sm font-normal text-gray-600'>
      ( this text is the written copy that appears in place of an image on a webpage if the image fails to load on a user's screen. It also helps
      screen-reading tools describe images to visually impaired readers and allows search engines to better crawl and rank your website. )
    </span>
  );
};

export default AltTextHelp;
