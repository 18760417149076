import React from 'react';
import CheckSvg from 'components/Svg/CheckSvg';
import PencilSvg from 'components/Svg/PencilSvg';

type Props = {
  isEditing: boolean;
  setIsEditing: (bool: boolean) => void;
};

const BusinessEditControls = ({ isEditing, setIsEditing }: Props) => {
  const editButton = (
    <button
      className='block transition transform hover:opacity-50 no-outline  text-gray-700'
      style={{ outline: 'none' }}
      onClick={() => setIsEditing(true)}
    >
      <PencilSvg />
    </button>
  );

  const checkSVG = (
    <div className='block transition transform hover:opacity-50 pt-2 text-gray-700 cursor-pointer' onClick={() => setIsEditing(false)}>
      <CheckSvg />
    </div>
  );

  return isEditing ? checkSVG : editButton;
};

export default BusinessEditControls;
