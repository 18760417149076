import React from 'react';

type Props = {
  isLoanMarket: boolean;
  setIsLoanMarket: (value: React.SetStateAction<boolean>) => void;
};
const MediaNav = ({ isLoanMarket, setIsLoanMarket }: Props) => {
  return (
    <div className='border-b border-gray-200'>
      <nav className='-mb-px flex' aria-label='Tabs'>
        <button
          onClick={() => setIsLoanMarket(true)}
          className={`${
            isLoanMarket
              ? 'border-blue-lm text-blue-lm'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
          }   w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm flex-grow focus:outline-none`}
        >
          Loan Market Media
        </button>
        <button
          onClick={() => setIsLoanMarket(false)}
          className={`${
            isLoanMarket
              ? 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              : 'border-blue-lm text-blue-lm'
          }   w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm flex-grow focus:outline-none`}
          aria-current='page'
        >
          Your Approved Media
        </button>
      </nav>
    </div>
  );
};

export default MediaNav;
