import React, { useState } from 'react';

export const EditContext = React.createContext({
  isDirty: false,
  setDirty: (value: boolean) => {}
});

const EditProvider: React.FC = ({ children }) => {
  const [isDirty, setIsChanged] = useState(false);

  const setDirty = (value: boolean) => {
    setIsChanged(value);
  };

  return <EditContext.Provider value={{ isDirty, setDirty }}>{children}</EditContext.Provider>;
};

export default EditProvider;
