import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { Recaptcha } from 'models/recaptcha';
import { useQuery } from 'react-query';

// get recaptcha group
export const useRecaptchaGroup = (businessName: string) => {
  return useQuery<Recaptcha[], Error>(
    'recaptchaGroups',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(`${process.env.REACT_APP_API}/Businesses/RecaptchaGroups/All`, generateGetHeaders(userDetails, businessName));
      return data;
    },
    { enabled: !!businessName, refetchOnWindowFocus: false }
  );
};
