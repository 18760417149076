import React from 'react';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import EyeSvg from 'components/Svg/EyeSvg';
import { previewUrlGenerator } from 'components/util/previewUrlGenerator';

type Props = {
  businessName: string;
  slug: string;
  enable?: boolean;
};

const PreviewButton = ({ businessName, slug, enable }: Props) => {
  const { data } = useBusinessAuth(businessName);
  if (!data) return null;
  return (
    <a
      href={previewUrlGenerator(data!.public, data!.byob, data!.slug, businessName, slug, data!.previewUrl)}
      className={`inline-flex mx-2 my-2  items-center px-4 py-2 border disabled:opacity-50 border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
        !enable ? 'opacity-50' : ''
      }`}
      style={{ pointerEvents: !enable ? 'none' : 'all' }}
      rel='noreferrer'
      target='_blank'
    >
      <EyeSvg isHidden={false} className='w-5 h-5 mr-2' />
      Preview
    </a>
  );
};

export default PreviewButton;
