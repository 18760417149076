import React, { useContext, useState } from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Member } from 'models/member';
// context
import { EditContext } from 'context/EditContext';
import { UserContext } from 'context/UserContext';
// components
import TeamMemberEditDrawer from 'components/TeamMember/TeamMemberEditDrawer';
import TeamMemberCard from 'components/TeamMember/TeamMemberCard';
import CheckSvg from 'components/Svg/CheckSvg';
import { rePositionItem } from 'components/util/rePositionItem';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
// commands

import { PromoteToEditor } from 'commands/teamMembers/PromoteToEditor';
import { DemoteEditor } from 'commands/teamMembers/DemoteEditor';

import { useDerivedState } from 'hooks/useDerivedState';
import InformationCard from 'components/InformationCard';
import { ChangeMemberVisibility } from 'commands/teamMembers/ChangeMemberVisibility';
import ErrorModal from 'components/Modals/ErrorModal';
import { SetOrder } from 'commands/teamMembers/SetOrder';
import { useCommands } from 'hooks/useCommands';

type Props = {
  teamData: Member[];
};

const TeamMemberList = ({ teamData }: Props) => {
  const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();
  const [teamMembers, setTeamMembers] = useDerivedState(teamData);
  const [toggle, setToggle] = useState(false);
  const [selectedMemeber, setSelectedMember] = useState<Member>();
  const { user } = useContext(UserContext);
  const { setDirty, isDirty } = useContext(EditContext);

  const toggleDetailsHandler = (id: string) => {
    // Select member by id. and add to selected member
    let member = teamData.find(member => member.employeeId === id) ?? null;
    if (member) {
      setSelectedMember(member);
      setToggle(!toggle);
    }
  };

  const handleOnDragEnd = (dropResult: DropResult) => {
    const reorderedMembers = rePositionItem(dropResult, teamMembers);
    setTeamMembers(reorderedMembers);
    commands.add(
      new SetOrder(
        reorderedMembers.map((teamMember, index) => {
          return { teamMemberId: teamMember.employeeId, order: index };
        })
      )
    );
    setDirty(true);
  };

  const handleSend = () => {
    commands.send();
  };

  const handleShowHide = (id: string) => {
    const updatedMembers = teamMembers.map(member => {
      if (member.employeeId === id) {
        commands.add(new ChangeMemberVisibility(`${!member.visible}`, id));
        return { ...member, visible: !member.visible };
      }
      return member;
    });
    setDirty(true);
    setTeamMembers(updatedMembers);
  };

  const handleEditorPromotion = (id: string) => {
    if (user.corporateUser) {
      let isEditor = teamMembers.find(member => member.employeeId === id)?.editor;
      const updatedMembers = teamMembers.map(member => {
        if (member.employeeId === id) {
          return { ...member, editor: !member.editor };
        }
        return member;
      });
      setTeamMembers(updatedMembers);

      setDirty(true);
      if (!isEditor) {
        commands.add(new PromoteToEditor(id, id), null, command => command.id === id);
      } else {
        commands.add(new DemoteEditor(id, id), null, command => command.id === id);
      }
    }
  };

  return (
    <div>
      <ErrorModal open={showError} error={null} errorMessage={errorMessage} />
      <NotificationMessage message='Success' type={NotificationStyles.Happy} show={!showError && showSuccess} setShow={setShowSuccess} />
      <InformationCard>
        Use this page to manage how team members are displayed on your website. To hide a team member click on the eye icon. To change the order your
        team members are displayed on your website drag and drop the tiles below into your preferred order. Click on the pen icon to control what
        information is displayed for each team member.
      </InformationCard>
      <div className='flex mb-6 justify-end'>
        <button
          onClick={handleSend}
          disabled={!isDirty}
          className='inline-flex items-center px-4 py-2 my-2 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
        >
          <CheckSvg />
          Save order
        </button>
      </div>
      {teamData && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='teamMembers'>
            {provided => (
              <div className='grid grid-cols-1 gap-4' {...provided.droppableProps} ref={provided.innerRef}>
                {teamMembers!.map((member, index) => {
                  return (
                    <Draggable key={member?.employeeId} draggableId={member?.employeeId} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <TeamMemberCard
                            member={member}
                            key={member.employeeId}
                            promoteHandler={handleEditorPromotion}
                            handleShowHide={handleShowHide}
                            toggleHandler={() => toggleDetailsHandler(member.employeeId)}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {toggle && <TeamMemberEditDrawer toggleHandler={() => setToggle(false)} selectedMember={selectedMemeber} />}
    </div>
  );
};

export default TeamMemberList;
