import { Command } from 'commands/Commands';

class DeleteTeamMember extends Command {
  type: string = 'Member.DeleteTeamMember';
  constructor(public inputs: string[]) {
    super(inputs);
  }
  priority = 100;
}
export default DeleteTeamMember;
