import Layout from 'components/Layout/Layout';
import { UserContext } from 'context/UserContext';
import React, { useContext, useEffect } from 'react';

export const NotFoundPage = () => {
  const { user } = useContext(UserContext);
  useEffect(() => {
    if ((window.location.pathname === '/' && user.corporateUser) || (window.location.pathname === '/' && user.businesses.length >= 2)) {
      window.location.href = '/admin/businesses';
    } else if (window.location.pathname === '/') {
      window.location.href = `/${user.businesses[0]}`;
    }
  }, [user]);

  return (
    <Layout pageTitle='Not Found'>
      <h1 className='text-md'>404 Not Found</h1>
    </Layout>
  );
};
