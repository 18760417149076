import { FieldType } from 'models/Field';
import { AddProperty } from './AddProperty';
export class AddField extends AddProperty {
  type: string = 'Page.AddField';

  buId: string | null;
  block: string | null;

  constructor(
    public page: string,
    public fieldType: FieldType,
    public name: string,
    public displayName: string,    
    buId: string | null,
    block: string | null
  ) {
    super(page,fieldType,name, displayName);
    this.block = block;
    this.buId = buId;
  }
  priority: number = 75;
}
