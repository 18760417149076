import { User } from 'models/user';
import React from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';

export const UserContext = React.createContext({
  user: { corporateUser: false, businesses: [''] },
  setUser: (value: User) => {},
});

const UserProvider: React.FC = ({ children }) => {
  const [user, setIsChanged] = useLocalStorage<User>('loggedInUser', {
    corporateUser: false,
    businesses: [''],
  });

  const setUser = (value: User) => {
    setIsChanged(value);
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
