import { Command } from '../Commands';

export class SetLocalMedia extends Command {
  type: string = 'SetLocalMedia';
  constructor(public id: string, newValue: string) {
    super(newValue, id);
  }
  priority: number = 100;
}

export class SetUmbracoMedia extends Command {
  type: string = 'SetUmbracoMedia';
  constructor(
    public id: string,
    newValue: {
      umbracoId: number | null;
      url: string | null;
    }
  ) {
    super(newValue, id);
  }
  priority: number = 100;
}
