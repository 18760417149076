import React from 'react';
import InfoSvg from './Svg/InfoSvg';
type Props = {
  children: React.ReactNode;
  trainingLink?: string;
};
const InformationCard = ({ children, trainingLink = '' }: Props) => {
  return (
    <div className='mx-auto flex mb-5 bg-blue-tint p-3 rounded-md shadow'>
      <InfoSvg className=' block mr-3 text-gray-500 flex-shrink-0' />
      <h3 className='block text-sm text-gray-500 max-w-7xl flex-grow-0'>
        {children}
        {trainingLink !== '' && (
          <a className='font-md text-blue-lm' href={trainingLink} target='_blank' rel='noreferrer'>
            {' '}
            If you need help, view the training material here.
          </a>
        )}
      </h3>
    </div>
  );
};

export default InformationCard;
