import React, { useState } from 'react';

import PencilSvg from 'components/Svg/PencilSvg';
import InputModal from 'components/Modals/InputModal';
import { useCommands } from 'hooks/useCommands';
import { BusinessDetails } from 'models/business';
import { RenamePage } from 'commands/RenamePage';
import { Page } from 'models/page';
import { useHistory, useParams } from 'react-router';
import { AddPageRedirect } from 'commands/redirect/AddPageRedirect';

type Props = {
  text: string;
  enable?: boolean;
  isDropDown?: boolean;
  pageTitles?: string[];
  pageSlugs?: string[];
  businessData?: BusinessDetails;
  pageData: Page | undefined;
};

const RenameButton = ({ text, enable = true, isDropDown = false, pageTitles, pageSlugs, businessData, pageData }: Props) => {
  const [open, setOpen] = useState(false);
  const [saveRenameMessage, setSaveRenameMessage] = useState<string>('Must be a valid page name');
  const [value, setValue] = useState<string>('');
  const { commands, showError, showSuccess, errorMessage, setShowSuccess, setErrorMessage, setShowError } = useCommands();
  const pageHistory = useHistory();
  const { slug, businessName } = useParams<{ slug: string; businessName: string }>();

  const submitHandler = async () => {
    const pageSlug = pageData!.slug.split('/');
    const oldSlug = pageSlug[pageSlug.length - 1];
    const newSlug = value.replaceAll(/\s+/g, '-').toLowerCase();
    let outputSlug = pageData!.slug.replace(oldSlug, newSlug);
    const inputValue = [],
      pageRedirect = [];
    inputValue.push(pageData!.id, value, outputSlug, pageData!.slug);
    if (!outputSlug.startsWith('/')) {
      outputSlug = '/' + outputSlug;
    }
    if (outputSlug.endsWith('/')) {
      outputSlug = outputSlug.substring(0, outputSlug.length - 1);
    }
    let pSlug = pageData!.slug;
    if (!pSlug.startsWith('/')) {
      pSlug = '/' + pSlug;
    }
    if (pSlug.endsWith('/')) {
      pSlug = pSlug.substring(0, pSlug.length - 1);
    }
    pageRedirect.push(pSlug, outputSlug, businessData!.details.slug);
    const isApprovalSuccess = await commands.add(new RenamePage(inputValue, businessData!.id)).add(new AddPageRedirect(pageRedirect)).send();
    if (isApprovalSuccess) {
      setShowSuccess(true);
      setOpen(false);
      setTimeout(() => {
        pageHistory.push(`/${businessName}/pages`);
        window.location.reload();
      }, 1000);
    } else {
      setErrorMessage('Rename failed');
      setShowError(true);
    }
  };

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  const nameIsValid = async (value: string) => {
    if (pageData === undefined) return;
    let match = false;
    const slugMatch = value.replace(/\s+/g, '-').toLowerCase();
    const pageSlug = pageData!.slug.split('/');
    const oldSlug = pageSlug[pageSlug.length - 1];
    const searchSlug = pageData!.slug.replace(oldSlug, slugMatch);

    pageTitles?.forEach(pt => {
      if (pt.toLowerCase() == value.toLowerCase()) {
        match = true;
      }
    });

    pageSlugs?.forEach(ps => {
      if (ps.toLowerCase() == searchSlug.toLowerCase()) {
        match = true;
      }
    });

    if (value === '' || value.replace(/\s/g, '').length === 0) {
      match = true;
    }
    setSaveRenameMessage(!match ? 'Rename' : 'Enter in a new name');
    return match;
  };

  return (
    <>
      <button
        disabled={!enable}
        onClick={openModal}
        type='button'
        className={
          isDropDown
            ? 'group flex rounded-md items-center w-full px-2 py-2 text-sm text-blue-lm hover:bg-blue-light disabled:opacity-50'
            : `inline-flex
      mx-2 my-2  items-center px-4 py-2 border disabled:opacity-50 border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`
        }
      >
        <div className='flex'>
          <PencilSvg className='w-5 h-5 mr-2' />
          {text}
        </div>
      </button>

      <InputModal
        saveText={saveRenameMessage}
        title={'Rename Custom Page'}
        description={`Enter in a new unique name for the custom page. WARNING: Renaming a page will remove any unsaved work - please save all work before renaming the page.`}
        open={open}
        inputValue={value}
        textArea={false}
        setInputValue={setValue}
        sendHandler={submitHandler}
        customValidation={value => nameIsValid(value)}
        red={false}
        onClose={() => closeModal()}
      />
    </>
  );
};

export default RenameButton;
