import React from 'react';
import Layout from 'components/Layout/Layout';
import ChildPageItem from 'components/PageList/ChildPageItem';
import ParentPageItem from 'components/PageList/ParentPageItem';
import { isContainer } from 'models/page';
import BarLoader from 'react-spinners/BarLoader';
import { useParams } from 'react-router-dom';
import { usePages } from 'api/queries/usePages';
import InformationCard from 'components/InformationCard';
import ErrorModal from 'components/Modals/ErrorModal';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';

const PagesScreen = () => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data: pages, error, isLoading, isError } = usePages(businessName);
  const { data: authDetails } = useBusinessAuth(businessName);

  return (
    <Layout pageTitle={'Your Pages'}>
      <InformationCard trainingLink={authDetails?.trainingLink}>
        These are all the pages you have available on your website. To edit content on a page click on the pen icon. To add a new page click the plus
        icon. New pages will require approval from the corporate team before they are published to your website.
      </InformationCard>
      <ErrorModal open={isError} error={error} />
      {isLoading ? (
        <div className='flex w-full'>
          <BarLoader height='4px' width='100%' color='#00AAE5' />
        </div>
      ) : (
        <div className='bg-white shadow-xl overflow-hidden sm:rounded-md'>
          <ul className='divide-y divide-gray-200'>
            {pages!.map(node => (
              <React.Fragment key={node.title}>
                <ParentPageItem node={node} />
                {isContainer(node) && node.pages && node.pages.map(page => <ChildPageItem key={page.slug} page={page} />)}
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </Layout>
  );
};

export default PagesScreen;
