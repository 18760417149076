import React from 'react';

import Drawer from 'components/Layout/Drawer';
import MediaUpload from './MediaUpload';

type AddMediaDrawerProps = {
  toggleHandler: () => void;
};

const AddMediaDrawer = ({ toggleHandler }: AddMediaDrawerProps) => {
  return (
    <Drawer
      title='Add media'
      toggleHandler={toggleHandler}
      description='Media will be sent to the Loan Market corporate team for approval before you can use it on your website. You’ll be notified as soon as it’s approved.'
    >
      <MediaUpload />
    </Drawer>
  );
};

export default AddMediaDrawer;
