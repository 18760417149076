import React, { useState, useContext } from 'react';
import { ChangeSocial } from 'commands/business/social/ChangeSocial';
import { CommandBatch, ICommand } from 'commands/Commands';

import { EditContext } from 'context/EditContext';

import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';

type Props<TCommand extends ICommand> = {
  initialVal?: string;
  label?: string;
  commands: CommandBatch;
  showControls?: boolean;
  commandFactory: (payload: string) => TCommand;
};
const BusinessEditableText = ({ initialVal, label, commands, showControls = true, commandFactory }: Props<ChangeSocial>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialVal ? initialVal : '');
  const { setDirty } = useContext(EditContext);
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value.trim();
    setValue(newValue);
    commands.add(commandFactory(newValue));
    setDirty(true);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      setIsEditing(false);
    }
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
        <h3 className='block text-lg text-gray-700 p-1'>{label}:</h3>
        <div className='flex'>
          {isEditing ? (
            <input
              className='flex focus:ring-blue-lm focus:border-blue-lm w-full border-gray-200 border-2 rounded-md sm:text-sm p-1 mr-5 transition-all '
              type='text'
              value={value}
              onChange={handleChange}
              onKeyPress={e => handleEnter(e)}
              onBlur={() => setIsEditing(false)}
              autoFocus={isEditing}
            />
          ) : (
            <p className='px-3 pt-2'>{value}</p>
          )}
          {showControls ? <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} /> : null}
        </div>
      </div>
    </>
  );
};

export default BusinessEditableText;
