import { CommandBatch } from 'commands/Commands';
import { BusinessDetails } from 'models/business';
import BusinessBoard from 'components/MyBusiness/BusinessBoard';
import InputModal from 'components/Modals/InputModal';
import { useContext, useState } from 'react';
import { UserContext } from 'context/UserContext';
import { DuplicateBusiness } from 'commands/business/DuplicateBusiness';
import AddHeader from 'components/Layout/AddHeader';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import { v4 } from 'uuid';
import DialogModal from 'components/Modals/DialogModal';
import DeleteButton from 'components/Buttons/DeleteButton';
import { DeleteBusiness } from 'commands/business/DeleteBusiness';

type Props = {
  business: BusinessDetails;
  commands: CommandBatch;
};

const BusinessSettings = ({ business, commands }: Props) => {
  const [openSlug, setOpenSlug] = useState(false);
  const [openMyCRM, setOpenMyCRM] = useState(false);
  const [openByob, setOpenByob] = useState(false);
  const [notice, setNotice] = useState(false);
  const [submitNotice, setSubmitNotice] = useState(false);
  const [submitSuccessNotice, setSubmitSuccessNotice] = useState(false);
  const [submitFailureNotice, setSubmitFailureNotice] = useState(false);
  const [isByobValue, setIsByobValue] = useState<string>('');
  const [myCRMValue, setMyCRMValue] = useState<string>('');
  const [slugValue, setSlugValue] = useState<string>('');
  const [deleteNotice, setDeleteNotice] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const { user } = useContext(UserContext);

  const [myCRMMessage, setMyCRMMessage] = useState<string>('Must not be an existing MyCRM ID');
  const [slugMessage, setSlugMessage] = useState<string>('Must not be an existing Business slug');
  const [deleteMessage, setDeleteMessage] = useState<string>('Enter to Delete to confirm');
  const [deleteValue, setDeleteValue] = useState<string>('');
  const [deleteSubmitNotice, setDeleteSubmitNotice] = useState(false);
  const [deleteFailureNotice, setDeleteFailureNotice] = useState(false);

  const oktaAuth = useOktaAuth();

  const myCRMIsValid = async (value: string) => {
    const isTaken = await axios.get(`${process.env.REACT_APP_API}/Portal/Businesses/CheckMyCrm/${value}`, {
      headers: {
        Authorization: `Bearer ${oktaAuth.authState.idToken?.idToken}`,
        business: business.details.displayName
      }
    });
    let valid = isTaken.data;
    var regExp = /[a-zA-Z]/;
    value === '' || regExp.test(value) === true ? (valid = true) : (valid = isTaken.data);
    setMyCRMMessage(valid ? 'Must be valid and not existing MyCRM ID' : 'Continue');
    return valid;
  };

  const handleNotice = () => {
    setNotice(false);
    setOpenByob(true);
  };

  const handleByob = () => {
    setOpenByob(false);
    setIsByobValue('true');
    setOpenMyCRM(true);
  };

  const handleSlugInput = () => {
    setOpenMyCRM(false);
    setOpenSlug(true);
  };

  const handleDeleteNotice = () => {
    setDeleteNotice(false);
    setOpenDeleteConfirmation(true);
  };

  const handleDeleteinput = async () => {
    setOpenDeleteConfirmation(false);
    setDeleteSubmitNotice(true);

    commands.add(new DeleteBusiness(true));
    const isSendSuccess = await commands.send();
    if (isSendSuccess) {
      setDeleteSubmitNotice(false);
      window.location.href = window.origin;
    } else {
      setDeleteSubmitNotice(false);
      setDeleteFailureNotice(true);
    }
  };

  const deleteIsvalid = async (value: string) => {
    let invalid = false;
    if (value.toLowerCase() !== 'delete') {
      invalid = true;
    }
    setDeleteMessage(invalid ? 'Enter in delete to confirm' : 'I understand - Delete this business');
    return invalid;
  };

  const slugIsValid = async (value: string) => {
    const isTaken = await axios.get(`${process.env.REACT_APP_API}/Portal/Businesses/CheckSlug/${value}`, {
      headers: {
        Authorization: `Bearer ${oktaAuth.authState.idToken?.idToken}`,
        business: business.details.displayName
      }
    });
    let byobCheck = false;
    if (isByobValue === 'true') {
      if (value.includes('www.')) {
        byobCheck = true;
      }
    }
    let valid = isTaken.data;
    value === '' ? (valid = true) : (valid = isTaken.data);

    if (isByobValue === 'true') {
      if (byobCheck === true) {
        valid = false;
      } else {
        valid = true;
      }
    }

    setSlugMessage(valid ? 'Invalid Business slug' : 'Submit');
    return valid;
  };

  function clearCache() {
    window.location.reload();
  }

  const submitDuplicateBusiness = async () => {
    setOpenSlug(false);
    setSubmitNotice(true);
    var input = [];
    input.push(business.id, myCRMValue, slugValue, isByobValue);
    commands.add(new DuplicateBusiness(input), v4());
    const isSendSuccess = await commands.send();
    if (isSendSuccess) {
      setSubmitNotice(false);
      setSubmitSuccessNotice(true);
      setTimeout(clearCache, 1000);
    } else {
      setSubmitNotice(false);
      setSubmitFailureNotice(true);
    }
  };

  return (
    <>
      {user ? (
        <BusinessBoard heading='Business'>
          <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
            <h3 className='block text-lg text-gray-700 p-1'>Duplicate Business:</h3>
            {submitNotice || submitSuccessNotice ? (
              <div>
                {submitNotice ? <span className='pl-5'>Duplicating Business details, please wait.......</span> : null}
                {submitSuccessNotice ? <span className='pl-5 text-green-500'>Success!</span> : null}
              </div>
            ) : (
              <div onClick={() => setNotice(true)}>
                <div className='content-center'>
                  <AddHeader linkText='Create duplicate' linkUrl={'#'} />
                  {submitFailureNotice ? <span className='pl-5 text-red-500'>Error: Unable to duplicate Business!</span> : null}
                </div>
              </div>
            )}
          </div>
          <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
            <h3 className='block text-lg text-gray-700 p-1'>Delete Business:</h3>
            {deleteNotice || deleteSubmitNotice ? (
              <div>{deleteSubmitNotice ? <span className='pl-5'>Deleting this business, please wait.......</span> : null}</div>
            ) : (
              <div onClick={() => setDeleteNotice(true)}>
                <div className='content-center'>
                  {deleteFailureNotice ? (
                    <span className='pl-5 text-red-500'>Error: Unable to delete this Business!</span>
                  ) : (
                    <DeleteButton addDeleteCommand={() => {}} autoClear={false} fullWidth={false} isMedia={false} useModal={false} />
                  )}
                </div>
              </div>
            )}
          </div>
          <DialogModal
            open={notice}
            title='Warning'
            actionText={'I understand'}
            closeModal={() => setNotice(false)}
            description={
              'You are attempting to duplicate an existing Business, please make sure that the new Business MyCRM Id is correct and has a unique slug that is valid.'
            }
            submitHandler={handleNotice}
            isRed={true}
          />
          <DialogModal
            open={openByob}
            title='BYOB'
            actionText={'Yes'}
            exitText={'No'}
            closeModal={() => {
              setOpenByob(false);
              setIsByobValue('false');
              setOpenMyCRM(true);
            }}
            description={`Is the new copy of the current business a BYOB business?`}
            submitHandler={handleByob}
            isRed={false}
          />
          <DialogModal
            open={deleteNotice}
            title='Warning'
            actionText={'I understand'}
            closeModal={() => setDeleteNotice(false)}
            description={'You are attempting to delete a business. By continuing, you understand the risks associated with this operation.'}
            submitHandler={handleDeleteNotice}
            isRed={true}
          />
          <InputModal
            saveText={myCRMMessage}
            title={'Enter MyCRM ID'}
            description={`Enter in the new business MyCRM ID.`}
            open={openMyCRM}
            inputValue={myCRMValue}
            textArea={false}
            setInputValue={setMyCRMValue}
            sendHandler={handleSlugInput}
            customValidation={myCRMValue => myCRMIsValid(myCRMValue)}
            red={false}
            onClose={() => setOpenMyCRM(false)}
          />
          <InputModal
            saveText={slugMessage}
            title={'Enter business slug'}
            description={`New slug must be valid and not an existing business slug.`}
            open={openSlug}
            inputValue={slugValue}
            textArea={false}
            setInputValue={setSlugValue}
            sendHandler={submitDuplicateBusiness}
            customValidation={slugValue => slugIsValid(slugValue)}
            red={false}
            onClose={() => setOpenSlug(false)}
          />
          <InputModal
            saveText={deleteMessage}
            title={'Confirm deletion'}
            description={`Enter in "Delete" to confirm deletion of business.`}
            open={openDeleteConfirmation}
            inputValue={deleteValue}
            textArea={false}
            setInputValue={setDeleteValue}
            sendHandler={handleDeleteinput}
            customValidation={deleteValue => deleteIsvalid(deleteValue)}
            red={true}
            onClose={() => setOpenDeleteConfirmation(false)}
          />
        </BusinessBoard>
      ) : null}
    </>
  );
};

export default BusinessSettings;
