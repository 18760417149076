import React from 'react';
import BusinessTableItem from './BusinessTableItem';
import BarLoader from 'react-spinners/BarLoader';
import SearchInput from 'components/SearchInput';

import { useSearchState } from 'hooks/useSearchState';
import { useBusinesses } from 'api/queries/useBusinesses';
import ErrorModal from 'components/Modals/ErrorModal';

const BusinessTable = () => {
  const { data: businessesData, isLoading, isError, error } = useBusinesses();

  const { handleSearch, handleSearchSubmit, searchResults, searchValue } = useSearchState(business => business.displayName, businessesData!);

  return (
    <div className='flex flex-col'>
      <ErrorModal open={isError} error={error} />
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <h3 className='mb-5 font-bold text-gray-500'>
            <SearchInput
              handleSearchSubmit={handleSearchSubmit}
              searchValue={searchValue}
              placeholder='Search for a business'
              handleSearch={handleSearch}
            />
          </h3>
          <BarLoader loading={isLoading} />
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
            <table className='min-w-full  divide-y divide-gray-200'>
              <thead className='bg-blue-tint'>
                <tr>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Business Name
                  </th>

                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Custom Pages
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Custom Blogs
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Byob
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Slug
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Status
                  </th>
                  <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Businesses: {searchResults?.length}
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {searchResults ? searchResults?.map((business, index) => <BusinessTableItem key={index} index={index} business={business} />) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='mt-5 flex'>
        <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
      </div>
    </div>
  );
};

export default BusinessTable;
