import React from 'react';
import NavLinks from 'components/Navigation/NavLinks';

const DesktopNav = () => {
  return (
    <div className='hidden lg:flex lg:flex-shrink-0'>
      <div className='flex flex-col w-64'>
        <NavLinks />
      </div>
    </div>
  );
};

export default DesktopNav;
