import { retrieveToken } from 'api/retrieveToken';
import axios from 'axios';

export interface ICommand {
  businessId: string;
  type: string;
  payload: string | object | boolean | null;
  id: string;
  priority: number;
}

export abstract class Command implements ICommand {
  businessId: string;
  abstract type: string;
  id: string;
  abstract priority: number;
  constructor(public payload: string | string[] | object | boolean | null, id: string | null = null) {
    if (id !== null) this.id = id;
  }
}

export class CommandBatch {
  businessId: () => string;
  constructor(
    businessIdString: string,
    private setErrorMessage: (message: string) => void = _ => {},
    private setShowError: (show: boolean) => void = _ => {},
    private setShowSuccess: (show: boolean) => void = _ => {}
  ) {
    this.businessId = () => businessIdString;
  }

  async send() {
    this.timeSent = new Date();
    const data = JSON.stringify(this);
    console.debug('Sending commands:', data);

    return axios
      .post(`${process.env.REACT_APP_API}/Portal/Command`, data, {
        headers: {
          business: (window.location.pathname.split('/') ?? ['', ''])[1], // gets businessname from url path
          'Content-Type': 'application/json',
          Authorization: retrieveToken()
        }
      })
      .then(res => {
        this.setShowSuccess(true);
        console.debug('Command send success:', res);
        return true;
      })
      .catch(err => {
        this.setShowError(true);
        this.setErrorMessage(err.message);
        console.debug('Command send fail:', err);
      });
  }

  any() {
    return this.commands.length > 0;
  }

  add(
    command: ICommand,
    businessId: string | null = null,
    addPredicate: (command: ICommand) => boolean = c => c.type === command.type && c.id === command.id
  ) {
    command.businessId = businessId ?? this.businessId();
    var foundIndex = this.commands.findIndex(addPredicate);
    if (foundIndex !== -1) {
      this.commands[foundIndex] = command;
    } else {
      this.commands.push(command);
    }
    console.debug('adding command', this);

    return this;
  }

  private timeSent: Date;

  private commands: ICommand[] = [];
}
