import React from 'react';
import { useHistory } from 'react-router-dom';

export const useTimedGoBack = (success: boolean, time?: number) => {
  const timeOut = time || 2000;
  const history = useHistory();

  React.useEffect(() => {
    if (success) {
      var timer = setTimeout(() => {
        history.goBack();
      }, timeOut);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [success, history, timeOut]);
};
