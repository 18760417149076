import React from 'react';
type Props = {
  size?: number;
  colour?: string;
};
const MediaSvg = ({ size = 6, colour = 'text-white' }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-${size} w-${size} pr-2 ${colour}`}
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2'
      />
    </svg>
  );
};

export default MediaSvg;
