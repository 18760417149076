import { Command } from './Commands';


export class PublishUmbracoPage extends Command {
  priority: number = 200;
  type: string = 'Page.Umbraco.Publish';

  constructor(slug: string, message: string) {
    super(message, slug);
  }
}
