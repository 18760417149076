import React from 'react';
import { UserContext } from 'context/UserContext';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CloseSvg from 'components/Svg/CloseSvg';

const ClearSavedBusinessButton = () => {
  const history = useHistory();
  const { businessName } = useParams<{ businessName: string }>();
  const { user } = useContext(UserContext);

  const handleClearSavedBusiness = () => {
    history.push('/admin/businesses');
  };

  if (!user.corporateUser) return null;

  return (
    <div
      className='bg-blue-lm px-4 py-2 fixed right-10
						bottom-2 text-white rounded-md flex items-center cursor-pointer hover:bg-blue-dark shadow z-40'
      onClick={handleClearSavedBusiness}
    >
      <p className='text-md mr-2'>{businessName ? businessName : 'Select a Business'}</p>

      <CloseSvg className='h-4 w-4' />
    </div>
  );
};

export default ClearSavedBusinessButton;
