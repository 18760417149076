import { useContext, useState } from 'react';
import TrashSvg from 'components/Svg/TrashSvg';

import PencilSvg from 'components/Svg/PencilSvg';
import { useCommands } from 'hooks/useCommands';
import InputModal from 'components/Modals/InputModal';
import { EditContext } from 'context/EditContext';
import { useOktaAuth } from '@okta/okta-react';
import { v4 } from 'uuid';
import { setTimeout } from 'timers';
import { pageredirects } from 'models/pageredirect';
import { RemovePageRedirect } from 'commands/redirect/RemovePageRedirect';
import { UpdatePageRedirect } from 'commands/redirect/UpdatePageRedirect';

type PageRedirectTableItemProps = {
  initialVal?: string;
  index: number;
  pageredirects: pageredirects;
};

const RedirectTableItem = (props: PageRedirectTableItemProps) => {
  const { oldUrl, newUrl, slug } = props.pageredirects;
  const index = props.index;

  const { commands } = useCommands();
  const [okay, setOkay] = useState(false);
  const [update, setUpdate] = useState(false);

  const [value, setValue] = useState<string>(props.initialVal ? props.initialVal : '');
  const [updatevalue, setUpdateValue] = useState<string>(props.initialVal ? props.initialVal : '');

  const { setDirty } = useContext(EditContext);

  useOktaAuth();

  const [saveMessage, setSaveMessage] = useState<string>('Must be a valid Url');
  const [updateMessage, setUpdateMessage] = useState<string>('Must be a valid Url redirect');

  function clearCache() {
    window.location.reload();
  }

  const handleSend = () => {
    var deleteredirect = [];
    deleteredirect.push(oldUrl, newUrl, slug);
    commands.add(new RemovePageRedirect(deleteredirect), v4());
    commands.send();
    setOkay(false);
    setDirty(true);

    setTimeout(clearCache, 250);
  };

  const handleUpdateSend = () => {
    var updateinput = [];
    updateinput.push(oldUrl, newUrl, updatevalue, slug);
    commands.add(new UpdatePageRedirect(updateinput), v4());
    commands.send();
    setUpdate(false);
    setDirty(true);

    setTimeout(clearCache, 250);
  };

  const slugIsValid = async (value: string) => {
    var match = false;
    value === 'DELETE' || value === 'Delete' || value === 'delete' ? (match = false) : (match = true);
    setSaveMessage(match ? 'Type in Delete to confirm redirect removal' : 'Delete');
    return match;
  };

  const updateSlugIsValid = async (value: string) => {
    var pass = false;
    value !== oldUrl ? (value !== newUrl ? (value !== '' ? (pass = true) : (pass = false)) : (pass = false)) : (pass = false);
    pass === true ? (pass = false) : (pass = true);
    setUpdateMessage(pass ? 'Must be a valid Url redirect' : 'Update');
    return pass;
  };

  return (
    <>
      <tr className={`${index! % 2 === 0 ? 'bg-white' : ' bg-gray-50'}`}>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{props.pageredirects.slug}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{props.pageredirects.oldUrl}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{props.pageredirects.newUrl}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className='bg-gray-200 px-2 pt-1 rounded-md'>
              <a className={'cursor-pointer  inline-block text-blue-200'} onClick={() => setUpdate(true)}>
                <PencilSvg outline />
              </a>
            </div>
            <div>
              <a className={'cursor-pointer inline-block ml-5 text-red-500'} onClick={() => setOkay(true)}>
                <TrashSvg />
              </a>
            </div>
          </div>
        </td>
      </tr>
      {okay ? (
        <InputModal
          saveText={saveMessage}
          title={'Delete Url Redirect'}
          description={`Enter in DELETE to confirm the removal of the page redirect.`}
          open={okay}
          inputValue={value}
          textArea={false}
          setInputValue={setValue}
          sendHandler={handleSend}
          customValidation={value => slugIsValid(value)}
          red={true}
          onClose={() => setOkay(false)}
        />
      ) : null}

      {update ? (
        <InputModal
          saveText={updateMessage}
          title={'Update Url Redirect'}
          description={`Enter in a new url to update page redirect`}
          open={update}
          inputValue={updatevalue}
          textArea={false}
          setInputValue={setUpdateValue}
          sendHandler={handleUpdateSend}
          customValidation={updatevalue => updateSlugIsValid(updatevalue)}
          red={true}
          onClose={() => setUpdate(false)}
        />
      ) : null}
    </>
  );
};

export default RedirectTableItem;
