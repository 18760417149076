import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import ChevronDown from 'components/Svg/ChevronDown';

type Props = {
  buttons: JSX.Element[];
};

const DropDownHeaderButtons = ({ buttons }: Props) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex mx-2 my-2  items-center px-4 py-2 border disabled:opacity-50 border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:hidden'>
          Options <ChevronDown />
        </Menu.Button>
      </div>
      <Transition
        as={'div'}
        show={true}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-10 focus:outline-none'>
          <div className='px-1 py-1 '>{buttons.map((button, index) => React.cloneElement(button, { isDropDown: true, key: index }))}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default DropDownHeaderButtons;
