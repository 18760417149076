import PageSvg from 'components/Svg/PageSvg';
import React from 'react';
type Props = {
  selectedFile: File | undefined;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const DisplaySelectedMedia = ({ selectedFile, handleOnChange }: Props) => {
  return (
    <>
      {selectedFile ? (
        selectedFile?.type === 'application/pdf' ? (
          <PageSvg className='w-32 h-32 text-blue-lm' />
        ) : (
          <img src={URL.createObjectURL(selectedFile)} className='w-full h-80 mx-auto my-2 object-cover' alt='alt' />
        )
      ) : (
        <div className='w-full h-80 mx-auto my-2 object-cover bg-gray-200 font-medium flex justify-center items-center'>
          <p className='text-gray-700'>Upload an Image</p>
        </div>
      )}
      <label className='bg-gray-300 block px-6 py-3 rounded-md cursor-pointer hover:bg-gray-200 text-gray-700 '>
        <p className=' text-center font-medium '>Select a file</p>
        <input type='file' name='upload_file' onChange={handleOnChange} className='hidden' />
      </label>
    </>
  );
};

export default DisplaySelectedMedia;
