import React, { useState } from 'react';
import { MemberEditableDetail } from 'models/member';
import { Command, CommandBatch } from 'commands/Commands';

import PencilSvg from 'components/Svg/PencilSvg';

import { truncateStr } from 'components/util/truncateStr';
import CheckSvg from 'components/Svg/CheckSvg';
import { ChangeCustomUrl } from 'commands/teamMembers/ChangeCustomUrl';

type TeamMemberCtaSelectProps = {
  teamMemberId: string;
  detail: MemberEditableDetail;
  detailName: string;
  commands: CommandBatch;
  calendlyUrl: string;
  mlgUrl: string;
  customUrl?: string;
  setDisableButton?: React.Dispatch<React.SetStateAction<boolean>>;
  editCommand: (newValue: string) => Command;
};

const TeamMemberCtaSelect = ({
  teamMemberId,
  detail,
  commands,
  detailName,
  mlgUrl,
  calendlyUrl,
  customUrl,
  editCommand
}: TeamMemberCtaSelectProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(detail.value ?? '');
  const [customValue, setCustomValue] = useState<string>(customUrl ?? '');

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEditValue(e.target.value);
    commands.add(editCommand(e.target.value));
  };

  let editInput = (
    <select className=' bg-gray-100 rounded-md p-1 z-20' value={editValue} onChange={e => handleChangeSelect(e)}>
      {detail.allowedValues.map(value => (
        <option key={value} value={value}>
          {value === 'MLG' ? 'My Lead Generator' : value}
        </option>
      ))}
    </select>
  );

  return (
    <div className={'relative rounded-lg  hover:shadow-md flex flex-row items-center space-x-3   hover:bg-gray-50 transition-all bg-white shadow-lg'}>
      <div className='flex items-center space-x-3 flex-1 px-6 py-5 '>
        <div className='flex-1 min-w-0'>
          <div className='focus:outline-none'>
            <span className=' inset-0' aria-hidden='true'></span>

            <p className='text-md font-medium text-gray-700'>{detailName}</p>
            <div className='text-sm font-normal text-gray-900 font-sm z-20'>
              {isEditing ? editInput : truncateStr(editValue === 'MLG' ? 'My Lead Generator' : editValue, 30)}
            </div>
            {editValue === 'Custom' ? (
              <div className='pt-2'>
                {isEditing ? <input className='border-2' type='text' value={customValue} onChange={e => setCustomValue(e.target.value)} /> : <></>}
              </div>
            ) : null}
            <div className='text-sm font-normal text-gray-600 font-sm z-20 underline mt-2'>
              {editValue === 'MLG' ? mlgUrl : editValue === 'Calendly' ? calendlyUrl : editValue === 'Custom' ? (!isEditing ? customValue : '') : ''}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-none text-gray-400 pr-6'>
        <div
          onClick={
            editValue === 'Custom'
              ? () => {
                  if (isEditing) {
                    commands.add(new ChangeCustomUrl(customValue, teamMemberId));
                  }
                  setIsEditing(!isEditing);
                }
              : () => setIsEditing(!isEditing)
          }
          className='ml-3 focus:outline-none cursor-pointer  z-20'
        >
          {!isEditing ? <PencilSvg /> : <CheckSvg />}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCtaSelect;
