import { generateGetHeaders } from 'api/generateGetHeaders';
import axios from 'axios';
import { pageredirects } from 'models/pageredirect';
import { useQuery } from 'react-query';

// Get list of redirects
export const usePageRedirects = () => {
    return useQuery<pageredirects[], Error>('pageredirects', async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Businesses/PageRedirect`,
        generateGetHeaders()
      );
      return data;
    });
  };