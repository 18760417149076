import { useCustomPageTemplate } from 'api/queries/useCustomPageTemplate';
import SaveButton from 'components/Buttons/SaveButton';
import Layout from 'components/Layout/Layout';
import ErrorModal from 'components/Modals/ErrorModal';
import CustomPageTemplateListItem from 'components/PageTemplateList/CustomPageTemplateListItem';
import { useLocation } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

const CustomPageTemplateListScreen = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const templateSlug = searchParams.get('templateSlug');
  const { data, isLoading, error, isError } = useCustomPageTemplate(templateSlug ?? '');

  const handleExportCSV = () => {
    if (data !== undefined && data.length > 0) {
      const fileName = templateSlug + '.csv';
      const csvHeaders = 'Business Name,Page Name,Page Url,Page Status,Visibility\n';
      const csvRows = data
        .map(row => {
          return [
            row.businessName,
            row.title,
            row.businessSlug + '/' + row.slug,
            row.approval.approved ? 'Published' : 'UnPublished',
            row.visibility ? 'Visible' : 'Hidden'
          ].join(',');
        })
        .join('\n');

      const csvContent = csvHeaders + csvRows;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      {isLoading ? (
        <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
      ) : (
        <Layout
          pageTitle={`Template Viewer: ${templateSlug}`}
          showGoBackLink={true}
          buttons={
            <SaveButton
              text='Export as CSV'
              save={handleExportCSV}
              modalTitle='Export as CSV'
              modalDescription='Would you like to export template usage information as a CSV file?'
              buttonText='Export'
            />
          }
        >
          <ErrorModal open={isError} error={error} errorMessage='Failed to fetch custom page template data for list view.' />
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
            <table className='min-w-full  divide-y divide-gray-200'>
              <thead className='bg-blue-tint'>
                <tr>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Business Name
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Page Name
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Page Url
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Page Status
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Visibility
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Pages: {data?.length ?? '0'}
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {data
                  ? data?.map((pagetemplate, index) => <CustomPageTemplateListItem key={index} index={index} pagetemplate={pagetemplate} />)
                  : null}
              </tbody>
            </table>
          </div>
        </Layout>
      )}
    </>
  );
};

export default CustomPageTemplateListScreen;
