import RejectButton from './RejectButton';

import ApproveButton from './ApproveButton';
import SaveButton from './SaveButton';
import RequestApprovalButton from './RequestApprovalButton';
import PublishWithoutApprovalButton from './PublishWithoutApprovalButton';

import React, { useContext } from 'react';
import { UserContext } from 'context/UserContext';

import { Approval } from 'models/approval';
import DropDownHeaderButtons from './DropDownHeaderButtons';

type ApprovalButtonProps = {
  approval?: Approval;
  save: () => void;

  addRequestApprovalCommand: () => void;
  addApproveCommand: (message: string) => void;
  addRejectCommand: (message: string) => void;
  addPublishCommand: (message: string) => void;
};

const ApprovalButtons = ({
  save,
  addRequestApprovalCommand,
  addApproveCommand,
  addRejectCommand,
  addPublishCommand,
  approval
}: ApprovalButtonProps) => {
  const { user } = useContext(UserContext);
  const corporateUser = user.corporateUser;
  let buttons: JSX.Element[];
  if (corporateUser) {
    if (approval?.required) {
      if (approval?.requested) {
        buttons = [<RejectButton addRejectCommand={addRejectCommand!} />, <ApproveButton addApproveCommand={addApproveCommand!} />];
      } else {
        buttons = [<SaveButton save={save} />, <PublishWithoutApprovalButton text='Publish' addCommand={addApproveCommand!} />];
      }
    } else {
      buttons = [<SaveButton save={save} />, <PublishWithoutApprovalButton text='Publish' addCommand={addPublishCommand!} />];
    }
  } else {
    if (approval?.required) {
      if (approval?.requested) {
        buttons = [<SaveButton save={save} />];
      } else {
        buttons = [<SaveButton save={save} />, <RequestApprovalButton addRequestApprovalCommand={addRequestApprovalCommand!} />];
      }
    } else {
      buttons = [<SaveButton save={save} />, <PublishWithoutApprovalButton text='Publish' addCommand={addPublishCommand!} />];
    }
  }

  return (
    <>
      <div className='hidden md:flex items-center'>{buttons.map((button, index) => React.cloneElement(button, { key: index }))}</div>
      <div className='sm:flex items-center'>
        <DropDownHeaderButtons buttons={buttons} />
      </div>
    </>
  );
};

export default ApprovalButtons;
