import React from 'react';
import { ChangeFacebook } from 'commands/business/social/ChangeFacebook';
import { ChangeGoogle } from 'commands/business/social/ChangeGoogle';
import { ChangeInstagram } from 'commands/business/social/ChangeInstagram';
import { ChangeLinkedIn } from 'commands/business/social/ChangeLinkedIn';
import { ChangeTwitter } from 'commands/business/social/ChangeTwitter';
import { ChangeYoutube } from 'commands/business/social/ChangeYoutube';
import { CommandBatch } from 'commands/Commands';
import BusinessEditableText from 'components/MyBusiness/Inputs/BusinessEditableText';
import { BusinessDetails } from 'models/business';
import BusinessBoard from './BusinessBoard';
type Props = {
  business: BusinessDetails;
  commands: CommandBatch;
};
const BusinessSocials = ({ business, commands }: Props) => {
  return (
    <BusinessBoard heading='Socials'>
      <BusinessEditableText
        label='Google Business Page'
        initialVal={business.socialLinks?.googleBusinessPage}
        commands={commands}
        commandFactory={value => new ChangeGoogle(value)}
      />
      <hr />
      <BusinessEditableText
        label='Linkedin'
        initialVal={business.socialLinks?.linkedIn}
        commands={commands}
        commandFactory={value => new ChangeLinkedIn(value)}
      />
      <hr />
      <BusinessEditableText
        label='Facebook'
        initialVal={business.socialLinks?.facebook}
        commands={commands}
        commandFactory={value => new ChangeFacebook(value)}
      />
      <hr />
      <BusinessEditableText
        label='Instagram'
        initialVal={business.socialLinks?.instagram}
        commands={commands}
        commandFactory={value => new ChangeInstagram(value)}
      />
      <hr />
      <BusinessEditableText
        label='YouTube'
        initialVal={business.socialLinks?.youtube}
        commands={commands}
        commandFactory={value => new ChangeYoutube(value)}
      />
      <hr />
      <BusinessEditableText
        label='Twitter'
        initialVal={business.socialLinks?.twitter}
        commands={commands}
        commandFactory={value => new ChangeTwitter(value)}
      />
    </BusinessBoard>
  );
};

export default BusinessSocials;
