import { User } from 'models/user';
import { retrieveToken } from './retrieveToken';

export const generateGetHeaders = (
  userDetails?: User,
  businessName?: string
) => {
  const config = {
    headers: {
      Authorization: retrieveToken(),
    },
  };
  if (businessName && userDetails) {
    (config as any).headers.Business = businessName
  }

  return config;
};
