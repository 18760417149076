import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import { IMedia } from 'models/media';
import { retrieveToken } from 'api/retrieveToken';

import { AddMedia } from 'commands/media/AddMedia';
import ErrorModal from 'components/Modals/ErrorModal';

import DisplaySelectedMedia from './DisplaySelectedMedia';
import AltTextHelp from 'components/AltTextHelp';
import { useCommands } from 'hooks/useCommands';
import { UserContext } from 'context/UserContext';
import { CorporateAddMedia } from 'commands/media/CorporateAddMedia';

const MediaUpload = () => {
  const { commands, showError, showSuccess, errorMessage, setShowError, setShowSuccess, setErrorMessage } = useCommands();
  const { businessName } = useParams<{ slug: string; businessName: string }>();
  const [selectedFile, setSelectedFile] = useState<File>();

  const [altText, setAltText] = useState('');

  const { user } = useContext(UserContext);
  const corporateUser = user.corporateUser;

  React.useEffect(() => {
    if (showSuccess) {
      window.location.reload();
    }
  }, [showSuccess]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target.files![0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append('File', selectedFile!);
      formData.append('Slug', businessName);

      axios
        .post<IMedia>(`${process.env.REACT_APP_API}/Portal/Media/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: retrieveToken()
          }
        })
        .then(res => {
          if (corporateUser) {
            commands.add(
              new CorporateAddMedia({
                name: res.data.name,
                altText: altText,
                url: res.data.url,
                bytes: res.data.bytes.toString(),
                extension: res.data.extension
              })
            );
          } else {
            commands.add(
              new AddMedia({
                name: res.data.name,
                altText: altText,
                url: res.data.url,
                bytes: res.data.bytes.toString(),
                extension: res.data.extension
              })
            );
          }

          commands.send();
        })
        .catch(err => {
          setShowError(true);
          setErrorMessage(`${err}`);
          console.debug(err);
        });
    }
  };

  return (
    <div>
      <NotificationMessage
        message='Successfully uploaded!'
        type={NotificationStyles.Happy}
        show={showSuccess}
        setShow={setShowSuccess}
        drawer={true}
      />

      <ErrorModal open={showError} error={null} errorMessage={errorMessage} />
      <DisplaySelectedMedia selectedFile={selectedFile} handleOnChange={handleOnChange} />
      <label className='block text-md font-medium text-gray-700  my-3' htmlFor='altText'>
        Alt text: <AltTextHelp />
      </label>
      <input
        id='altText'
        type='text'
        className='block border-2 px-2 py-3 w-full rounded-md '
        value={altText}
        onChange={e => setAltText(e.target.value)}
      />
      <button
        className='inline-flex items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3 disabled:opacity-50'
        onClick={handleSubmit}
        disabled={altText === ''}
      >
        Upload media
      </button>
    </div>
  );
};

export default MediaUpload;
