import { EditContext } from 'context/EditContext';
import React, { useContext } from 'react';
import { Prompt } from 'react-router';
import { useDirtyPageCleanUp } from './util/useDirtyPageCleanUp';

const SavePrompt = () => {
  const { isDirty } = useContext(EditContext);

  useDirtyPageCleanUp();

  return (
    <Prompt
      when={isDirty}
      message={location =>
        `You have unsaved changes. Are you sure you want to leave and go to ${location.pathname}?`
      }
    />
  );
};

export default SavePrompt;
