import BusinessTable from 'components/Admin/Businesses/BusinessTable';
import Layout from 'components/Layout/Layout';

const AdminBusinessScreen = () => {
  return (
    <Layout pageTitle={'Businesses'}>
      <BusinessTable />
    </Layout>
  );
};

export default AdminBusinessScreen;
