import { useRedirects } from 'api/queries/useRedirects';
import axios from 'axios';
import RedirectTableItem from 'components/Admin/Businesses/RedirectTableItem';
import AddHeader from 'components/Layout/AddHeader';
import Layout from 'components/Layout/Layout';
import InputModal from 'components/Modals/InputModal';
import { useSearchState } from 'hooks/useSearchState';
import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { v4 } from 'uuid';
import { AddRedirect } from 'commands/redirect/AddRedirect';
import { useCommands } from 'hooks/useCommands';
import PageRedirectTableItem from 'components/Admin/Businesses/PageRedirectTableItem';
import { usePageRedirects } from 'api/queries/usePageRedirects';
import EyeSvg from 'components/Svg/EyeSvg';
import { AddPageRedirect } from 'commands/redirect/AddPageRedirect';

const RedirectManagementScreen = () => {
  const { data: slugData } = useRedirects();
  const { data: pageRedirectData } = usePageRedirects();

  const { searchResults } = useSearchState(b => b.newslug, slugData!);
  const { searchResults: pageSearchResults } = useSearchState(p => p.newUrl, pageRedirectData!);

  const [create, setCreate] = useState(false);
  const [createnext, setCreateNext] = useState(false);

  const [pagecreate, setPageCreate] = useState(false);
  const [pagecreatenext, setPageCreateNext] = useState(false);

  const [slugcreate, setSlugCreate] = useState(false);

  const createMessage = 'Must be a business slug that exists';
  const verifyMessage = 'New Slug must be unique and unused';

  const slugMessage = 'New Slug must be valid';

  const [firstMessage, setFirstMessage] = useState<string>(createMessage);
  const [secondMessage, setSecondMessage] = useState<string>(verifyMessage);

  const [firstvalue, setFirstValue] = useState<string>('');
  const [secondvalue, setSecondValue] = useState<string>('');

  const [slugvalue, setSlugValue] = useState<string>('');

  const [slugmessage, setSlugMessage] = useState<string>(slugMessage);
  const [pagefirstMessage, setPageFirstMessage] = useState<string>(createMessage);
  const [pagesecondMessage, setPageSecondMessage] = useState<string>(verifyMessage);

  const [pagefirstvalue, setPageFirstValue] = useState<string>('');
  const [pagesecondvalue, setPageSecondValue] = useState<string>('');

  const { commands } = useCommands();

  const [redirectType, setRedirectType] = useState(false);

  const oktaAuth = useOktaAuth();

  function clearCache() {
    window.location.reload();
  }

  const handleFirstSlug = () => {
    setCreate(false);
    setCreateNext(true);
  };

  const handleSecondSlug = () => {
    var addinput = [];
    addinput.push(firstvalue, secondvalue);
    commands.add(new AddRedirect(addinput), v4());
    commands.send();
    setCreateNext(false);
    setTimeout(clearCache, 750);
  };

  const firstSlugIsValid = async (newValue: string) => {
    const isRedirect = await axios.get(`${process.env.REACT_APP_API}/Portal/Businesses/Redirect`, {
      headers: {
        Authorization: `Bearer ${oktaAuth.authState.idToken?.idToken}`,
        businessId: v4()
      }
    });

    const rvalid = isRedirect.data;
    var pass = false;
    var notoldslug = rvalid.find((o: { oldslug: string }) => o.oldslug === newValue);
    newValue !== '' ? (typeof notoldslug === 'undefined' ? (pass = true) : (pass = false)) : (pass = false);
    pass === true ? (pass = false) : (pass = true);
    setFirstMessage(pass ? 'Must be slug that is not in another redirect' : 'Submit Old Slug');
    return pass;
  };

  const SecondSlugIsValid = async (newValue: string) => {
    var pass = false;
    newValue !== '' ? (newValue !== firstvalue ? (pass = true) : (pass = false)) : (pass = false);
    pass === true ? (pass = false) : (pass = true);
    setSecondMessage(pass ? 'New Slug must not be blank or the old slug' : 'Submit New Slug');
    return pass;
  };

  const handlePageFirstSlug = () => {
    setPageCreate(false);
    setPageCreateNext(true);
  };

  const handleSlugValue = () => {
    setSlugCreate(false);
    setPageCreate(true);
  };

  const BusinessSlugIsValid = async (newValue: string) => {
    var pass = true;
    newValue !== '' ? (pass = false) : (pass = true);
    setSlugMessage(pass ? 'Slug must be valid' : 'Submit Slug');
    return pass;
  };

  const handlePageSecondSlug = () => {
    var addpageinput = [];
    addpageinput.push(pagefirstvalue, pagesecondvalue, slugvalue);
    commands.add(new AddPageRedirect(addpageinput), v4());
    commands.send();
    setPageCreateNext(false);
    setTimeout(clearCache, 750);
  };

  const firstPageSlugIsValid = async (newValue: string) => {
    var pass = false;
    newValue !== '' ? (pass = true) : (pass = false);
    pass === true ? (pass = false) : (pass = true);
    setPageFirstMessage(pass ? 'Must be valid page redirect url' : 'Submit Old Url');
    return pass;
  };

  const SecondPageSlugIsValid = async (newValue: string) => {
    var pass = false;
    newValue !== pagefirstvalue ? (pass = true) : (pass = false);
    pass === true ? (pass = false) : (pass = true);
    setPageSecondMessage(pass ? 'New url must not be blank or old url' : 'Submit New Url');
    return pass;
  };

  return (
    <>
      {!redirectType ? (
        <Layout
          pageTitle='Business Redirect Manager'
          buttons={
            <>
              <div
                onClick={() => setRedirectType(true)}
                className='cursor-pointer mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                <EyeSvg isHidden={false} className='w-4 h-4 mr-2' /> <span>Show Page Redirects</span>
              </div>{' '}
              <div onClick={() => setCreate(true)}>
                <AddHeader linkText='Create New Business Redirect' linkUrl={'#'} />
              </div>
            </>
          }
        >
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
            <table className='min-w-full  divide-y divide-gray-200'>
              <thead className='bg-blue-tint'>
                <tr>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Old Slug
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    New Slug
                  </th>
                  <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Redirects: {searchResults?.length}
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {searchResults ? searchResults?.map((redirect, index) => <RedirectTableItem key={index} index={index} redirect={redirect} />) : null}
              </tbody>
            </table>
          </div>
        </Layout>
      ) : (
        <Layout
          pageTitle='Page Redirect Manager'
          buttons={
            <>
              <div
                onClick={() => setRedirectType(false)}
                className='cursor-pointer mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                <EyeSvg isHidden={false} className='w-4 h-4 mr-2' /> <span>Show Business Redirects</span>
              </div>{' '}
              <div onClick={() => setSlugCreate(true)}>
                <AddHeader linkText='Create New Page Redirect' linkUrl={'#'} />
              </div>
            </>
          }
        >
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
            <table className='min-w-full  divide-y divide-gray-200'>
              <thead className='bg-blue-tint'>
                <tr>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Business (Slug)
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Old Url
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    New Url
                  </th>
                  <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Redirects: {pageSearchResults?.length}
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {pageSearchResults
                  ? pageSearchResults?.map((pageredirects, index) => (
                      <PageRedirectTableItem key={index} index={index} pageredirects={pageredirects} />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </Layout>
      )}

      {create ? (
        <InputModal
          saveText={firstMessage}
          title={'Create Redirect - select business'}
          description={`Enter in the current slug of the business as the old slug.`}
          open={create}
          inputValue={firstvalue}
          textArea={false}
          setInputValue={setFirstValue}
          sendHandler={handleFirstSlug}
          customValidation={value => firstSlugIsValid(value)}
          red={true}
          onClose={() => setCreate(false)}
        />
      ) : createnext ? (
        <InputModal
          saveText={secondMessage}
          title={'Create Redirect - create redirect'}
          description={`Enter in the new slug for the business.`}
          open={createnext}
          inputValue={secondvalue}
          textArea={false}
          setInputValue={setSecondValue}
          sendHandler={handleSecondSlug}
          customValidation={value => SecondSlugIsValid(value)}
          red={true}
          onClose={() => setCreateNext(false)}
        />
      ) : null}

      {slugcreate ? (
        <InputModal
          saveText={slugmessage}
          title={'Create Page Redirect - Enter in business slug'}
          description={'Enter in the slug of business the page redirect is for.'}
          open={slugcreate}
          inputValue={slugvalue}
          textArea={false}
          setInputValue={setSlugValue}
          sendHandler={handleSlugValue}
          customValidation={value => BusinessSlugIsValid(value)}
          red={true}
          onClose={() => setSlugCreate(false)}
        />
      ) : pagecreate ? (
        <InputModal
          saveText={pagefirstMessage}
          title={'Create Page Redirect - Enter in first url'}
          description={`Enter in the first url for the page redirect.`}
          open={pagecreate}
          inputValue={pagefirstvalue}
          textArea={false}
          setInputValue={setPageFirstValue}
          sendHandler={handlePageFirstSlug}
          customValidation={value => firstPageSlugIsValid(value)}
          red={true}
          onClose={() => setPageCreate(false)}
        />
      ) : pagecreatenext ? (
        <InputModal
          saveText={pagesecondMessage}
          title={'Create Page Redirect - Enter new url'}
          description={`Enter in the url you want to old url to redirect to, leave this blank to redirect to homepage.`}
          open={pagecreatenext}
          inputValue={pagesecondvalue}
          textArea={false}
          setInputValue={setPageSecondValue}
          sendHandler={handlePageSecondSlug}
          customValidation={value => SecondPageSlugIsValid(value)}
          red={true}
          onClose={() => setPageCreateNext(false)}
        />
      ) : null}
    </>
  );
};

export default RedirectManagementScreen;
