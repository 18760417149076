import PencilSvg from 'components/Svg/PencilSvg';
import { CustomPage } from 'models/page';
import { Link } from 'react-router-dom';

type CustomPageTemplateListItemProps = {
  initialVal?: string;
  index: number;
  pagetemplate: CustomPage;
};

const CustomPageTemplateListItem = (props: CustomPageTemplateListItemProps) => {
  const index = props.index;

  return (
    <>
      <tr className={`${index! % 2 === 0 ? 'bg-white' : ' bg-gray-50'}`}>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{props.pagetemplate.businessName}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{props.pagetemplate.title}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>
                {props.pagetemplate.businessSlug + '/' + props.pagetemplate.slug}
              </div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>
                {props.pagetemplate.approval.approved ? (
                  <span className='inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium mb-2 flex-grow-0 ml-2 md:ml-0 bg-green-100 text-green-700 '>
                    Published
                  </span>
                ) : (
                  <span className='inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium mb-2 flex-grow-0 ml-2 md:ml-0 bg-red-100 text-red-700 '>
                    UnPublished
                  </span>
                )}
              </div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>
                {props.pagetemplate.visibility ? (
                  <span className='px-3 py-0.5 rounded-full text-xs font-medium mb-2 text-yellow-600 bg-yellow-100 ml-2  md:ml-0'>Visible</span>
                ) : (
                  <span className='px-3 py-0.5 rounded-full text-xs font-medium mb-2 text-purple-600 bg-purple-300 ml-2  md:ml-0'>Hidden</span>
                )}
              </div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <Link to={`/${props.pagetemplate.businessSlug}/page-edit/${props.pagetemplate.slug}`} className='text-blue-lm'>
            <span className='text-blue-lm'>
              <PencilSvg outline={true} />
            </span>
          </Link>
        </td>
      </tr>
    </>
  );
};

export default CustomPageTemplateListItem;
