import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { useQuery } from 'react-query';

// Get Lead Broker of business
export const useLeadBroker = (businessName: string) => {
  return useQuery<number, Error>(
    'leadBroker',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Businesses/LeadBroker`, generateGetHeaders(userDetails, businessName));
      return data;
    },
    { enabled: !!businessName, refetchOnWindowFocus: false }
  );
};
