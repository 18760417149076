import React, { useState } from 'react';

// Components
import DesktopNav from './DesktopNav';
import MobileHeader from './MobileHeader';
import MobileNav from './MobileNav';
import ClearSavedBusinessButton from 'components/Admin/ClearSavedBusinessButton';
import './layout.css';
import BackArrow from 'components/Svg/BackArrow';
import { useHistory } from 'react-router-dom';
import ActivityFeed from 'components/ActivityFeed/ActivityFeed';

type LayoutProps = {
  children: React.ReactNode;
  buttons?: React.ReactNode;
  activityFeed?: boolean;
  pageTitle: string;
  showGoBackLink?: boolean;
};

const Layout = (props: LayoutProps) => {
  const [toggleMobileNav, setToggleMobileNav] = useState(false);
  const history = useHistory();
  return (
    <div className='h-screen flex overflow-hidden bg-white'>
      {toggleMobileNav && <MobileNav toggleHandler={() => setToggleMobileNav(!toggleMobileNav)} />}
      <DesktopNav />
      <div className='flex flex-col min-w-0 flex-1 overflow-hidden '>
        <MobileHeader toggleHandler={() => setToggleMobileNav(!toggleMobileNav)} />

        <div className='border-b-2  border-gray-200 px-8 py-8 relative'>
          <div className='flex justify-between space-x-2 items-center'>
            <div className='flex justify-between'>
              {props.showGoBackLink && (
                <button className='text-gray-500 flex absolute bottom-2 transition hover:opacity-50' onClick={() => history.goBack()}>
                  <BackArrow />
                  Back
                </button>
              )}
              <h1 className='lg:text-4xl text-xl lg:font-normal text-gray-800 font-medium'>{props.pageTitle}</h1>
            </div>
            <div className='flex'>{props.buttons}</div>
          </div>
        </div>

        <div className='flex-1 relative z-0 flex overflow-hidden'>
          <main className=' bg-gray-50 flex-1 p-16 relative z-0 overflow-y-auto focus:outline-none' tabIndex={0}>
            <div className='absolute inset-0  sm:px-6 lg:px-8'>
              <div className='h-full py-8'>
                <div className=' pb-16'>{props.children}</div>
              </div>
            </div>
          </main>
          {props.activityFeed && <ActivityFeed />}
        </div>
      </div>
      <ClearSavedBusinessButton />
    </div>
  );
};

export default Layout;
