import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { isContainer, isPage, ITreeNode } from 'models/page';
import PageSvg from 'components/Svg/PageSvg';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import PlusSvg from 'components/Svg/PlusSvg';
import FolderSvg from 'components/Svg/FolderSvg';
import PreviewLink from 'components/PreviewLink';
import PencilSvg from 'components/Svg/PencilSvg';

export interface ParentPageProps {
  node: ITreeNode;
}

const ParentPageItem = ({ node }: ParentPageProps) => {
  const isBYOB: boolean = true;
  const id = node.id;
  const { businessName } = useParams<{ businessName: string }>();

  const { data: authData } = useBusinessAuth(businessName);
  return (
    <li>
      <div className='block hover:bg-gray-50 transition-all'>
        <div className='flex'>
          <div className='flex flex-col flex-grow px-4 py-4 sm:px-6 justify-between'>
            <div className='flex items-center mb-1'>
              {isPage(node) ? (
                <PageSvg className='text-gray-400 group-hover:text-gray-500 mr-1.5 h-6 w-6' />
              ) : (
                <FolderSvg className='mr-1.5 text-gray-500' />
              )}
              <p className='text-xl font-medium text-navy truncate'>{node.title}</p>
            </div>

            {isPage(node) && (
              <div className='sm:flex'>
                <p className='flex items-center text-sm text-gray-500'>{node.slug}</p>
              </div>
            )}
          </div>
          {isPage(node) && <PreviewLink businessName={businessName} slug={node.slug} />}

          <div className='sm:flex sm:justify-between'>
            {isBYOB &&
              (isPage(node) ? (
                <div className='flex px-9 items-center bg-blue-tint h-full text-sm text-gray-500 sm:mt-0'>
                  <Link to={`/${businessName}/page-edit/${node.slug}`} className='text-blue-lm'>
                    <span className='text-blue-lm'>
                      <PencilSvg outline={true} />
                    </span>
                  </Link>
                </div>
              ) : isContainer(node) ? (
                authData?.hasCustomPages ? (
                  <span className='flex px-9 items-center bg-blue-tint text h-full text-gray-500 sm:mt-0'>
                    <Link
                      to={{
                        pathname: `/${businessName}/page-create/${node.displaySlug}`,
                        state: { id: id }
                      }}
                      className='text-gray-500'
                    >
                      <PlusSvg />
                    </Link>
                  </span>
                ) : null
              ) : (
                <div className='w-5 h-5' />
              ))}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ParentPageItem;
