import Image from 'components/Image';
import Drawer from 'components/Layout/Drawer';
import PageSvg from 'components/Svg/PageSvg';
import { formatBytes } from 'components/util/formatBytes';
import { isImageExtension } from 'components/util/isImageExtension';
import { IMedia } from 'models/media';
import { useContext, useState } from 'react';
import { useDerivedState } from 'hooks/useDerivedState';
import { ChangeAlt } from 'commands/media/ChangeAlt';
import { useCommands } from 'hooks/useCommands';
import { UserContext } from 'context/UserContext';
import { MediaApprove, MediaRequestApproval } from 'commands/media/MediaApproval';
import MessageModal from 'components/Buttons/ButtonModals/MessageModal';
import DeleteButton from 'components/Buttons/DeleteButton';
import { DeleteMedia } from 'commands/media/DeleteMedia';

type Props = {
  toggleHandler: () => void;
  selectedMedia: IMedia | null;
};

const ApprovedMediaDrawer = ({ selectedMedia, toggleHandler }: Props) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [altText, setAltText] = useDerivedState(selectedMedia?.altText ?? '');
  const { commands } = useCommands();
  const { user } = useContext(UserContext);
  const [approveMessage, setApproveMessage] = useState('You are approved');

  const handleAltTextChange = (value: string) => {
    commands.add(new ChangeAlt(selectedMedia!.id, value));
    setAltText(value);
  };

  let buttons: JSX.Element = user.corporateUser ? (
    <button
      disabled={isSubmitted}
      className='mt-5 items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
      onClick={() => setOpenApprove(true)}
    >
      {isSubmitted ? 'Submitted' : 'Approve'}
    </button>
  ) : (
    <button
      disabled={isSubmitted}
      onClick={() => {
        commands.add(new MediaRequestApproval(approveMessage, selectedMedia!.id)).send();
        setIsSubmitted(true);
      }}
      className='mt-5  items-center  justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
    >
      {isSubmitted ? 'Submitted' : 'Save'}
    </button>
  );

  return (
    <>
      <Drawer
        toggleHandler={() => {
          toggleHandler();
          window.location.reload();
        }}
        title={selectedMedia!.name}
        description={'Viewing approved media'}
      >
        {isImageExtension(selectedMedia!.extension) ? (
          <Image src={selectedMedia!.url} alt='Selected Media' className='w-full h-80 mx-auto my-2 object-cover' />
        ) : (
          <PageSvg className='w-32 h-32 text-blue-lm' />
        )}

        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Alt text:</span>
          <span className='text-sm text-right'>{selectedMedia!.altText}</span>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Extension Type:</span>
          <span className='text-sm text-right'>{selectedMedia!.extension}</span>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Bytes:</span>
          <span className='text-sm text-right'>{formatBytes(parseInt(selectedMedia!.bytes), 2)}</span>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Url:</span>
          <a target='_blank' href={`${selectedMedia!.url}`} className='text-xs text-right underline' rel='noreferrer'>
            {selectedMedia!.url}
          </a>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Change Alt text:</span>
          <input
            id='altText'
            type='text'
            className='block border-2 px-2 py-3 w-full rounded-md '
            value={altText}
            onChange={e => handleAltTextChange(e.target.value)}
          />
        </div>
        {isSubmitted ? <p className='font-bold'>Changes to Alt text submitted, please close this drawer to see changes once approved.</p> : null}
        {buttons}
        <div>
          {user.corporateUser ? (
            <DeleteButton
              addDeleteCommand={() => {
                commands.add(new DeleteMedia(selectedMedia!.id)).send();
              }}
              autoClear={false}
              fullWidth={true}
              isMedia={true}
            />
          ) : null}
        </div>
      </Drawer>

      <MessageModal
        modalType='approve'
        description={'Please write a detailed approval message'}
        title='Approval Message'
        message={approveMessage}
        setMessage={setApproveMessage}
        sendHandler={() => {
          commands.add(new MediaApprove(approveMessage, selectedMedia!.id)).send();
          setOpenApprove(false);
          setIsSubmitted(true);
        }}
        onClose={() => setOpenApprove(false)}
        open={openApprove}
      />
    </>
  );
};

export default ApprovedMediaDrawer;
