import React from 'react';
//we're leaving the tinymce react code (@tinymce/tinymce-react: ^3.12.2) in place so we can easily swap back to it if the client changes their mind...
//import { Editor } from '@tinymce/tinymce-react';
import { useQuill } from 'react-quilljs';

import 'quill/dist/quill.snow.css';

type RichTextEditorProps = {
  label: string;
  value: string;
  id: string;
  handleFieldValue: (value: string, id?: string) => void;
};

const RichTextEditorInput = ({ label, value, id, handleFieldValue }: RichTextEditorProps) => {
  //tinymce code
  // const handleEditorChange = (content: string) => {
  //   const newValue = content;
  //   handleFieldValue(newValue, id);
  //   value = content;
  //   console.log('tinymce value', value);
  // };

  const theme = 'snow';

  const modules = {
    toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic'], [{ list: 'bullet' }, { list: 'ordered' }], ['link'], ['clean']]
  };

  const { quill, quillRef } = useQuill({ theme, modules });

  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(value ?? '');
      quill.on('text-change', (delta, oldDelta, source) => {
        const content = quill.getSemanticHTML();
        const newValue = content;
        handleFieldValue(newValue, id);
        value = content;
      });
    }
  }, [quill]);

  return (
    <>
      <label className='block text-md font-medium text-gray-700 pt-5 space-y-3'>{label}</label>
      {/* <Editor
        initialValue={value ?? ''}
        apiKey='hczx677xadq980gygi6p3jevxzifjjy2ee3e6d6yn3tyxuvt'
        init={{
          invalid_elements: 'script',
          height: 200,
          menubar: false,
          block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | bold italic | \
        bullist numlist | removeformat | help | removeh1'
        }}
        onEditorChange={handleEditorChange}
      /> */}
      {/* // */}
      <div className='mt-3 w-full h-80 bg-white border-2 rounded-md border-solid border-gray-200 overflow-auto resize-y'>
        <div ref={quillRef} />
      </div>
    </>
  );
};

export default RichTextEditorInput;
