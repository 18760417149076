import { Command } from '../Commands';

export class DeleteMedia extends Command {
  type: string = 'DeleteMedia';

  constructor(public id: string) {
    super(id);
  }

  priority: number = 100;
}
