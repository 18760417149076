import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';

import axios from 'axios';
import { CustomPage } from 'models/page';
import { useQuery } from 'react-query';

// get list of pages which are using custom templates
export const useCustomPageTemplate = (templateSlug: string) => {
  return useQuery<CustomPage[], Error>('templatesList', async () => {
    const userDetails = await getUserDetails();
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/Portal/Pages/TemplateList/${templateSlug}`,
      generateGetHeaders(userDetails, templateSlug)
    );
    return data;
  });
};
