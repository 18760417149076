import { Command, CommandBatch, ICommand } from 'commands/Commands';
import { EditContext } from 'context/EditContext';
import React, { useContext, useState } from 'react';
import BusinessEditControls from '../BusinessEditControls';

type Props<TCommand extends ICommand> = {
  visible: boolean;
  label: string;
  commands: CommandBatch;
  showControls: boolean;
  setBooleanState?: (bool: boolean) => void;
  commandFactory: (payload: boolean) => TCommand;
};
const BusinessBooleanSelect = ({ visible, commands, label, showControls, commandFactory, setBooleanState }: Props<Command>) => {
  const [value, setValue] = useState<boolean>(visible);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { setDirty } = useContext(EditContext);
  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const select = e.target;
    setDirty(true);
    if (select.value === 'true') {
      commands.add(commandFactory(true));
      setValue(true);
      if (setBooleanState) {
        setBooleanState!(true);
      }
    } else {
      commands.add(commandFactory(false));
      setValue(false);
      if (setBooleanState) {
        setBooleanState!(false);
      }
    }
  };
  return (
    <div>
      <div>
        <div className='flex justify-between mx-5 mt-2 mb-3'>
          <h3 className='text-lg text-gray-700'>{label}</h3>
          <div className='flex'>
            {!isEditing ? (
              value ? (
                <span className='px-3 py-1 bg-green-100 text-green-700 rounded-full'>true</span>
              ) : (
                <span className='px-2 py-1 bg-gray-400 text-white rounded-full'>false</span>
              )
            ) : (
              <select className='mx-3 bg-gray-100 rounded-md p-1' value={value ? 'true' : 'false'} onChange={handleOnChange}>
                <option value='true'>true</option>
                <option value='false'>false</option>
              </select>
            )}
            {showControls ? <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessBooleanSelect;
