import { useTemplates } from 'api/queries/useTemplates';
import Layout from 'components/Layout/Layout';
import PageTemplateItem from 'components/PageTemplateList/PageTemplateItem';
import { useParams } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

const PageTemplatesListViewerScreen = () => {
  const { slug, businessName } = useParams<{ slug: string; businessName: string }>();
  const { data, isLoading } = useTemplates(businessName);

  return (
    <>
      {isLoading ? (
        <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
      ) : (
        <Layout pageTitle='Custom Page Template List Viewer'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
            <table className='min-w-full  divide-y divide-gray-200'>
              <thead className='bg-blue-tint'>
                <tr>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Template Name
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Template Slug
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    View List
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {data ? data?.map((pagetemplate, index) => <PageTemplateItem key={index} index={index} pagetemplate={pagetemplate} />) : null}
              </tbody>
            </table>
          </div>
        </Layout>
      )}
    </>
  );
};

export default PageTemplatesListViewerScreen;
