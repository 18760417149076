import { Approval } from './approval';
import { Block } from './block';
import { Field } from './Field';

export class ITreeNode {
  id: string;
  title: string;
}

export function isContainer(node: ITreeNode): node is Container {
  return (node as Container).pages !== undefined;
}

export function isPage(node: ITreeNode): node is Page {
  return (node as Container).pages === undefined;
}

export class Container implements ITreeNode {
  id: string;
  title: string;
  displaySlug: string;
  slug: string;
  pages?: Page[];
}

export class Page implements ITreeNode {
  previewImage?: string;
  title: string;
  approval: Approval;
  displaySlug: string;
  id: string;
  custom: boolean;
  slug: string;
  requiresSpecialisation: boolean;
  properties: Field[] = [];
  blocks: Block[] = [];
  pages: Page[];
  visibility: boolean;
}

export class CustomPage extends Page {
  businessName: string;
  businessSlug: string;
}

export enum PageType {
  Home,
  Container,
  Page
}
