import React, { useState, useContext } from 'react';
import { ChangeSocial } from 'commands/business/social/ChangeSocial';
import { CommandBatch, ICommand } from 'commands/Commands';

import { EditContext } from 'context/EditContext';

import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';

import InputModal from 'components/Modals/InputModal';
import DialogModal from 'components/Modals/DialogModal';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import { useParams } from 'react-router-dom';

type Props<TCommand extends ICommand> = {
  initialVal?: string;
  label: string;
  commands: CommandBatch;
  showControls?: boolean;
  commandFactory: (payload: string) => TCommand;
  setNewSlug: (slug: string) => void;
};
const ByobChangeModal = ({ setNewSlug, initialVal, label, commands, showControls = true, commandFactory }: Props<ChangeSocial>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialVal ? initialVal : '');
  const [savedValue, setSavedValue] = useState<string>(initialVal ? initialVal : '');
  const { setDirty } = useContext(EditContext);
  const { businessName } = useParams<{ businessName: string }>();
  const oktaAuth = useOktaAuth();

  const confirmMessage = 'I understand the consequences';
  const validationMessage = 'Slug must be unique';
  const [saveMessage, setSaveMessage] = useState<string>(validationMessage);

  const [okay, setOkay] = useState(false);
  const handleSend = () => {
    setSavedValue(value);
    setNewSlug(value);
    commands.add(commandFactory(value));
    setIsEditing(false);
    setOkay(false);
    setDirty(true);
  };

  const okayToEdit = () => {
    setIsEditing(false);
    setOkay(true);
  };

  const slugIsValid = async (newValue: string) => {
    const isTaken = await axios.get(
      `${process.env.REACT_APP_API}/Portal/Businesses/CheckSlug/${newValue}`,
      {
        headers: {
          Authorization: `Bearer ${oktaAuth.authState.idToken?.idToken}`,
          business: businessName
        }
      }
    );
    const valid = isTaken.data;
    setSaveMessage(valid ? validationMessage  : confirmMessage);
    return valid;
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
        <h3 className='block text-lg text-gray-700 '>{label}:</h3>
        <div className='flex'>
          <DialogModal
            open={isEditing}
            title='Warning'
            actionText={'I understand'}
            closeModal={() => setIsEditing(false)}
            description={'Modifying the business slug can cause your website to not function correctly, or not be viewable. '}
            submitHandler={okayToEdit}
          />
          {okay ? (
            <InputModal
              saveText={saveMessage}
              title={label}
              description={`Enter a new ${label.toLowerCase()}. Saving from this modal will commit other changes made on this page.`}
              open={okay}
              inputValue={value}
              textArea={false}
              setInputValue={setValue}
              sendHandler={handleSend}
              customValidation={slugIsValid}
              red={true}
              onClose={() => setOkay(false)}
            />
          ) : (
            <p className='px-3 pt-2'>{savedValue}</p>
          )}
          {showControls ? <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} /> : null}
        </div>
      </div>
    </>
  );
};

export default ByobChangeModal;
