import { useState } from 'react';

import Drawer from 'components/Layout/Drawer';

export type DrawerItem<TData> = (item: TData) => JSX.Element;

export type DrawerData = {
  id: string;
  imageUrl: string;
  altText: string;
};

type AddDrawerProps<TData extends DrawerData> = {
  toggleHandler: () => void;
  data: TData[];
  title: string;
  description: string;
  addHandler: (data: TData[]) => void;
  drawerItem: DrawerItem<TData>;
};

const AddDrawer = <TData extends DrawerData>({ toggleHandler, data, description, title, addHandler, drawerItem }: AddDrawerProps<TData>) => {
  const [dataToAdd, setDataToAdd] = useState<TData[]>([]);

  const handleSelect = (data: TData) => {
    if (dataToAdd.includes(data)) {
      const copiedData = dataToAdd.filter(addedDataItem => addedDataItem !== data);
      setDataToAdd(copiedData);
    } else {
      setDataToAdd([...dataToAdd, data]);
    }
  };

  return (
    <Drawer toggleHandler={toggleHandler} description={description} title={title}>
      <div className=''>
        <div className='space-y-6 px-6'>
          <div className=''>
            {data === undefined && <p>nothing here</p>}
            {data?.map(dataItem => (
              <div
                key={dataItem.id}
                onClick={() => handleSelect(dataItem)}
                className={`bg-white flex py-2 px-3 shadow mt-3 rounded-md cursor-pointer ${
                  dataToAdd.includes(dataItem) && 'border-blue-lm border-solid border-2'
                }`}
              >
                {drawerItem(dataItem)}
              </div>
            ))}
          </div>

          <div>
            <button
              onClick={() => addHandler(dataToAdd)}
              type='button'
              className='inline-flex items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddDrawer;
