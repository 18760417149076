import React from 'react';
import { IMedia } from 'models/media';
import { formatBytes } from 'components/util/formatBytes';
import Image from 'components/Image';
import StatusTag from 'components/StatusTag';
import PageSvg from 'components/Svg/PageSvg';
import { isImageExtension } from 'components/util/isImageExtension';

interface MediaListItemProps {
  isChild?: boolean;
  item: IMedia;
  handleSelectedMedia: (item: IMedia) => void;
}
const MediaListItem = ({ item, isChild, handleSelectedMedia }: MediaListItemProps) => {
  return (
    <li onClick={() => handleSelectedMedia(item)} className='cursor-pointer'>
      <div className={`block ${isChild ? 'hover:bg-gray-100 bg-gray-150' : 'hover:bg-gray-50 bg-white'}`}>
        <div className='flex items-center px-4 py-4 sm:px-6'>
          <div className='min-w-0 flex-1 flex items-center'>
            <div className='flex-shrink-0 flex-row'>
              {!isImageExtension(item.extension) && item.extension !== null ? (
                <PageSvg className='w-14 h-14 text-blue-lm' />
              ) : (
                <Image
                  className={`${isChild === true ? 'h-8 w-12' : 'h-12 w-16'}  shadow-sm rounded-md object-scale-down `}
                  src={item.url}
                  alt={item.altText ?? ''}
                />
              )}
            </div>
            <div className=' flex-1 px-4 md:grid md:grid-cols-4 md:gap-1'>
              <div className='flex flex-col items-start col-span-2 '>
                {item.approval ? <StatusTag approval={item.approval} /> : null}
                <p className={`${isChild ? 'text-sm' : 'text-md'} text-gray text-xs`}>{item.name}</p>
              </div>
              <div className='hidden md:flex'>
                <div>
                  <p className=' flex items-center text-sm text-gray-500'>{formatBytes(parseInt(item.bytes), 2)}</p>
                </div>
              </div>
              <div className='hidden md:block'>
                <div>
                  <p className='flex items-center text-sm text-gray-500'></p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <!-- Heroicon name: chevron-right --> */}
            {isChild ? (
              <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z'
                  clipRule='evenodd'
                />
              </svg>
            ) : (
              <svg className='h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default MediaListItem;
