import { AddAward } from 'commands/award/AddAward';
import { v4 as newGuid } from 'uuid';
import Layout from 'components/Layout/Layout';
import MediaPickerDrawer from 'components/Media/MediaPickerDrawer';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import { EditContext } from 'context/EditContext';

import React, { useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';

import SaveButton from 'components/Buttons/SaveButton';
import { useTimedGoBack } from 'hooks/useTimedGoBack';
import Image from 'components/Image';
import ErrorModal from 'components/Modals/ErrorModal';
import { IMedia } from 'models/media';
import { UserContext } from 'context/UserContext';
import ApproveButton from 'components/Buttons/ApproveButton';
import { Approve } from 'commands/award/AwardApproval';
import { useCommands } from 'hooks/useCommands';

const CreateAwardScreen = () => {
  const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();

  useTimedGoBack(showSuccess);

  const newAwardId = useMemo(() => newGuid(), []);
  const [newAward, setNewAward] = useState({
    id: newAwardId,
    imageUrl: '',
    altText: ''
  });

  const [toggle, setToggle] = useState<boolean>(false);

  const { setDirty } = useContext(EditContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    commands.add(new AddAward(newAward, newAward.id));
  }, [newAward, commands]);

  const handleSelectedMedia = (media: IMedia) => {
    setNewAward({ ...newAward, imageUrl: media.url });
    setToggle(false);
    setDirty(true);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAward({
      ...newAward,
      [e.currentTarget.name]: e.currentTarget.value
    });
    setDirty(true);
  };

  const checkValidity = () => {
    return Object.values(newAward).every(value => !!value); // Check if all values are not empty
  };

  return (
    <>
      <Layout
        showGoBackLink={true}
        pageTitle={'Create Award'}
        buttons={
          user?.corporateUser ? (
            <ApproveButton enable={checkValidity()} addApproveCommand={message => commands.add(new Approve(message, newAwardId)).send()} />
          ) : (
            <SaveButton save={() => commands.send()} text='Request approval' enable={checkValidity()} />
          )
        }
      >
        <div className='pb-5'>
          <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='Successful!' />
          <ErrorModal open={showError} error={null} errorMessage={errorMessage} />
          <label>
            <p className='block text-md font-medium text-gray-700 my-3'>Award Image</p>
            {newAward.imageUrl && <Image src={newAward.imageUrl} style={{ width: '200px', height: 'auto' }} alt='award' />}
            <button
              onClick={() => setToggle(true)}
              className='inline-flex
     my-2  items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white  bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Add image
            </button>
          </label>
          <label>
            <p className='block text-md font-medium text-gray-700 my-3'>Alt Text</p>
            <input
              required={true}
              name='altText'
              type='text'
              value={newAward.altText}
              className='flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 rounded-md sm:text-sm p-3'
              onChange={handleOnChange}
            />
          </label>
        </div>
      </Layout>
      {toggle && <MediaPickerDrawer toggleHandler={() => setToggle(false)} onLocalMediaSelected={handleSelectedMedia} onUmbracoMediaSelected={handleSelectedMedia} />}
    </>
  );
};

export default CreateAwardScreen;
