import React from 'react';
import { useActivityFeed } from 'api/queries/useActivityFeed';
import ActivityTable from 'components/ActivityTable/ActivityTable';
import Layout from 'components/Layout/Layout';
import { useParams } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import ErrorModal from 'components/Modals/ErrorModal';

const ActivityScreen = () => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data, isLoading, error, isError } = useActivityFeed(businessName);

  return (
    <Layout pageTitle='Activity Feed'>
      <ErrorModal open={isError} error={error} />
      {isLoading ? (
        <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
      ) : (
        <>
          <ActivityTable activityType={'Rejected'} data={data?.filter(activityItem => activityItem.operation === 'Rejected')} />
          <ActivityTable activityType={'Awaiting Approval'} data={data?.filter(activityItem => activityItem.operation === 'Awaiting Approval')} />
          <ActivityTable activityType={'Approved'} data={data?.filter(activityItem => activityItem.operation === 'Approved')} />
        </>
      )}
    </Layout>
  );
};

export default ActivityScreen;
