import React, { useState } from 'react';
import { MemberDetail } from 'models/member';
import EyeSvg from 'components/Svg/EyeSvg';
import { Command, CommandBatch } from 'commands/Commands';

type TeamMemberDetailsVisibilityCardProps = {
  detail: MemberDetail;
  detailName: string;

  commands: CommandBatch;
  visibilityCommand: (value: string) => Command;
};

const TeamMemberDetailsVisibilityCard = ({ detail, commands, detailName, visibilityCommand }: TeamMemberDetailsVisibilityCardProps) => {
  const [visible, setVisible] = useState(detail?.visible ?? false);

  const visabilityHandler = () => {
    const newValue = !visible;
    setVisible(newValue);
    commands.add(visibilityCommand(newValue.toString()));
  };

  return (
    <div
      onClick={visabilityHandler}
      className={`relative rounded-lg  hover:shadow-md flex flex-row items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 hover:bg-gray-50 transition-all ${
        !visible ? 'bg-gray-100' : 'bg-white shadow-lg'
      } `}
    >
      <div className='flex items-center space-x-3 flex-1 px-6 py-5 '>
        <div className='flex-1 min-w-0'>
          <div className='focus:outline-none'>
            <span className='absolute inset-0' aria-hidden='true'></span>

            <p className='text-md font-medium text-gray-700'>{detailName}</p>
            <p className='text-sm font-medium text-gray-600 font-sm break-all'>{detail?.value}</p>
          </div>
        </div>
      </div>
      <div className='flex-none text-gray-400 px-6'>
        <div className='z-20 focus:outline-none '>
          <EyeSvg isHidden={!visible} />
        </div>
      </div>
    </div>
  );
};

export default TeamMemberDetailsVisibilityCard;
