import { Command } from '../Commands';

export class ChangeAlt extends Command {
  type: string = 'Media.ChangeAltText';

  constructor(public id: string, newValue: string) {
    super(newValue, id);
  }
  priority: number = 150;
}
