import { ChangeWebsiteVisibility } from 'commands/business/ChangeWebsiteVisibility';

import { CommandBatch } from 'commands/Commands';
import { EditContext } from 'context/EditContext';
import React, { useState } from 'react';
import { useContext } from 'react';
import BusinessEditControls from '../BusinessEditControls';
type Props = {
  visible: boolean;
  commands: CommandBatch;
  showControls?: boolean;
};
const BusinessWebsiteStatus = ({ visible, commands, showControls = true }: Props) => {
  const [value, setValue] = useState<boolean>(visible);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { setDirty } = useContext(EditContext);
  const handleChangeWebsiteStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const select = e.target;
    setDirty(true);
    if (select.value === 'Live') {
      commands.add(new ChangeWebsiteVisibility(true));
      setValue(true);
    } else {
      commands.add(new ChangeWebsiteVisibility(false));
      setValue(false);
    }
  };
  return (
    <div>
      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>Website Status:</h3>
        <div className='flex'>
          {!isEditing ? (
            value ? (
              <span className='px-3 py-1 bg-green-100 text-green-700 rounded-full'>live</span>
            ) : (
              <span className='px-2 py-1 bg-gray-400 text-white rounded-full'>onboarding</span>
            )
          ) : (
            <select className='mx-3 bg-gray-100 rounded-md p-1' value={value ? 'Live' : 'Onboarding'} onChange={handleChangeWebsiteStatus}>
              <option value='Live'>Live</option>
              <option value='Onboarding'>Onboarding</option>
            </select>
          )}
          {showControls ? <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} /> : null}
        </div>
      </div>
    </div>
  );
};

export default BusinessWebsiteStatus;
