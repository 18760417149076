import React, { useContext } from 'react';
import { ChangeFacebookPixel } from 'commands/business/ChangeFacebookPixel';
import { ChangeGoogleAnalytics } from 'commands/business/ChangeGoogleAnalytics';
import { ChangeJagoTenantId } from 'commands/business/ChangeJagoTenantId';
import { ChangeJagoUserId } from 'commands/business/ChangeJagoUserId';
import { ChangeTagManager } from 'commands/business/ChangeTagmanager';

import { CommandBatch } from 'commands/Commands';
import { UserContext } from 'context/UserContext';
import { BusinessDetails } from 'models/business';
import BusinessBoard from './BusinessBoard';
import BusinessEditableText from './Inputs/BusinessEditableText';
import DropDownList from 'components/Inputs/DropDownList';
import { ChangeReCaptchaGroup } from 'commands/business/ChangeReCaptchaGroup';
import { useRecaptchaGroup } from 'api/queries/useRecaptchaGroups';

type Props = {
  business: BusinessDetails;
  commands: CommandBatch;
  success: boolean;
};
const TrackingBoard = ({ business, commands, success }: Props) => {
  const { user } = useContext(UserContext);

  const { data: groupList } = useRecaptchaGroup(business.details.slug);
  const corporateUser = user.corporateUser;
  if (!corporateUser) return null;

  return (
    <BusinessBoard heading='Tracking'>
      <DropDownList
        label='reCAPTCHA Group'
        initialVal={business.details?.reCaptchaGroup}
        commands={commands}
        commandFactory={value => new ChangeReCaptchaGroup(value)}
        showControls={corporateUser}
        groupList={groupList}
      />
      <hr />

      <BusinessEditableText
        label='Google Analytics'
        initialVal={business.details?.googleAnalytics}
        commands={commands}
        commandFactory={value => new ChangeGoogleAnalytics(value)}
        showControls={corporateUser}
      />
      <hr />
      <BusinessEditableText
        label='Google Tag Manager'
        initialVal={business.details?.tagManager}
        commands={commands}
        commandFactory={value => new ChangeTagManager(value)}
        showControls={corporateUser}
      />
      <hr />
      <BusinessEditableText
        label='Facebook Pixel'
        initialVal={business.details?.facebookPixel}
        commands={commands}
        commandFactory={value => new ChangeFacebookPixel(value)}
        showControls={corporateUser}
      />
      <hr />
      <BusinessEditableText
        label='Jago Tenant Id'
        initialVal={business.details.jagoTenantId}
        commandFactory={value => new ChangeJagoTenantId(value)}
        commands={commands}
        showControls={corporateUser}
      />
      <hr />
      <BusinessEditableText
        label='Jago User Id'
        initialVal={business.details.jagoUserId}
        commandFactory={value => new ChangeJagoUserId(value)}
        commands={commands}
        showControls={corporateUser}
      />
    </BusinessBoard>
  );
};

export default TrackingBoard;
