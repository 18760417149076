import EyeSvg from 'components/Svg/EyeSvg';
import PencilSvg from 'components/Svg/PencilSvg';
import { generateStartEditPath } from 'components/util/generateStartEditPath';
import { previewUrlGenerator } from 'components/util/previewUrlGenerator';
import { showPreview } from 'components/util/showPreview';
import { EntityType } from 'models/approval';
import { BusinessAuthDetails } from 'models/business';
import { Link } from 'react-router-dom';

type Props = {
  entityType: EntityType;
  businessAuth: BusinessAuthDetails | undefined;
  businessName: string;
  identifier: string;
};
const ActivityTableItemButtons = ({ entityType, businessAuth, businessName, identifier }: Props) => {
  return (
    <td className='px-6 py-4 whitespace-nowrap '>
      <div className='flex items-end justify-end text-sm text-gray-600'>
        {showPreview(entityType) && businessAuth ? (
          <a
            href={previewUrlGenerator(businessAuth.public, businessAuth.byob, businessAuth.slug, businessName, identifier, businessAuth.previewUrl)}
            className={`font-light  flex cursor-pointer hover:bg-gray-200  rounded py-1 px-2 transition-all`}
          >
            <span>Preview</span> <EyeSvg isHidden={false} className={' w-5 h-5 ml-1'} />
          </a>
        ) : null}
        {
          <Link
            to={
              entityType === 'Media'
                ? { pathname: `media`, state: { open: true, id: identifier } }
                : `/${businessName}/${generateStartEditPath(entityType)}${identifier}`
            }
            className={`font-light flex cursor-pointer hover:bg-gray-200   rounded py-1 px-2 transition-all`}
          >
            <span>Edit</span> <PencilSvg className={'w-5 h-5 ml-1'} />
          </Link>
        }
      </div>
    </td>
  );
};

export default ActivityTableItemButtons;
