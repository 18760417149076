import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PageSvg from './Svg/PageSvg';
import BriefcaseSvg from './Svg/BriefcaseSvg';
import FolderAddSvg from './Svg/FolderAddSvg';
import PencilSvg from './Svg/PencilSvg';
import RightArrowSvg from './Svg/RightArrowSvg';

const ActionCards = () => {
  const { businessName } = useParams<{ businessName: string }>();
  return (
    <div>
      <div className='mb-16 rounded-lg bg-gray-200 overflow-hidden shadow-xl divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px'>
        <div className=' rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'>
          <div>
            <span className='rounded-lg inline-flex p-3 bg-blue-tint text-blue-lm ring-4 ring-white'>
              {/* <!-- Heroicon name: clock --> */}
              <PencilSvg />
            </span>
          </div>
          <div className='mt-8'>
            <h3 className='text-lg font-medium'>
              <Link to={`${businessName}/blog-listing`} className='focus:outline-none text-gray-600'>
                {/* <!-- Extend touch target to entire panel --> */}
                <span className='absolute inset-0' aria-hidden='true'></span>
                Write a blog post
              </Link>
            </h3>
          </div>
          <span className='absolute top-6 right-6 text-gray-300 group-hover:text-gray-400' aria-hidden='true'>
            <RightArrowSvg />
          </span>
        </div>

        <div className='sm:rounded-tr-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'>
          <div>
            <span className='rounded-lg inline-flex p-3 bg-blue-tint text-blue-lm ring-4 ring-white'>
              {/* <!-- Heroicon name: badge-check --> */}
              <PageSvg />
            </span>
          </div>
          <div className='mt-8'>
            <h3 className='text-lg font-medium'>
              <Link to={`${businessName}/pages`} className='focus:outline-none text-gray-600'>
                {/* <!-- Extend touch target to entire panel --> */}
                <span className='absolute inset-0' aria-hidden='true'></span>
                Edit content
              </Link>
            </h3>
          </div>
          <span className='absolute top-6 right-6 text-gray-300 group-hover:text-gray-400' aria-hidden='true'>
            <RightArrowSvg />
          </span>
        </div>

        <div className='relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'>
          <div>
            <span className='rounded-lg inline-flex p-3 bg-blue-tint text-blue-lm ring-4 ring-white'>
              <FolderAddSvg />
            </span>
          </div>
          <div className='mt-8'>
            <h3 className='text-lg font-medium'>
              <Link to={`${businessName}/media`} className='focus:outline-none text-gray-600'>
                {/* <!-- Extend touch target to entire panel --> */}
                <span className='absolute inset-0' aria-hidden='true'></span>
                Upload media
              </Link>
            </h3>
          </div>
          <span className='absolute top-6 right-6 text-gray-300 group-hover:text-gray-400' aria-hidden='true'>
            <RightArrowSvg />
          </span>
        </div>

        <div className='relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'>
          <div>
            <span className='rounded-lg inline-flex p-3 bg-blue-tint text-blue-lm ring-4 ring-white'>
              {/* <!-- Heroicon name: briefcase --> */}
              <BriefcaseSvg />
            </span>
          </div>
          <div className='mt-8'>
            <h3 className='text-lg font-medium'>
              <Link to={`${businessName}/partners`} className='focus:outline-none text-gray-600'>
                {/* <!-- Extend touch target to entire panel --> */}
                <span className='absolute inset-0' aria-hidden='true'></span>
                Add a partner
              </Link>
            </h3>
          </div>
          <span className='absolute top-6 right-6 text-gray-300 group-hover:text-gray-400' aria-hidden='true'>
            <RightArrowSvg />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActionCards;
