import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { BusinessApprovalSummary } from 'models/approval';
import { useQuery } from 'react-query';

export const useApprovalSummaries = () => {
  return useQuery<BusinessApprovalSummary[], Error>('approvalSummaries', async () => {
    const userDetails = await getUserDetails();
    const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Approvals`, generateGetHeaders(userDetails));
    return data;
  });
};
