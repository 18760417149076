import { Activity, OperationType } from 'models/approval';
import React from 'react';
import ActivityTableItem from './ActivityTableItem';

type Props = {
  activityType: OperationType;
  data?: Activity[];
};

const ActivityTable = ({ activityType, data }: Props) => {
  if (!data) return null;

  const tableHeaderTextColor = (activityType: OperationType) => {
    return activityType === 'Awaiting Approval' ? 'text-yellow-600' : activityType === 'Approved' ? 'text-green-600' : 'text-red-600';
  };

  return (
    <div className='flex flex-col mb-5'>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className={` max-w-7xl mx-auto`}>
            <h2 className='mb-5 text-lg font-medium text-gray-600'>{activityType}</h2>
            {data.length <= 0 ? (
              <p className='text-gray-600 text-sm'>No Entries Currently {activityType}</p>
            ) : (
              <div
                className={` rounded  shadow overflow-hidden  ${
                  activityType === 'Awaiting Approval' ? 'border-yellow-200' : activityType === 'Approved' ? 'border-green-200' : 'border-red-200'
                } max-w-7xl mx-auto`}
              >
                <table className='min-w-full divide-y divide-gray-200 rounded shadow'>
                  <thead
                    className={` ${
                      activityType === 'Awaiting Approval' ? 'bg-yellow-200' : activityType === 'Approved' ? 'bg-green-200' : 'bg-red-200'
                    }`}
                  >
                    <tr>
                      <th
                        scope='col'
                        className={`px-6 py-3 text-left text-xs font-medium ${tableHeaderTextColor(activityType)} uppercase tracking-wider`}
                      >
                        Type
                      </th>
                      <th
                        scope='col'
                        className={`px-6 py-3 text-left text-xs font-medium ${tableHeaderTextColor(activityType)} uppercase tracking-wider`}
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className={`px-6 py-3 text-left text-xs font-medium ${tableHeaderTextColor(activityType)} uppercase tracking-wider`}
                      >
                        Message
                      </th>
                      <th
                        scope='col'
                        className={`px-6 py-3 text-left text-xs font-medium ${tableHeaderTextColor(activityType)} uppercase tracking-wider`}
                      >
                        Date
                      </th>
                      <th
                        scope='col'
                        className={`px-6 py-3 text-left text-xs font-medium ${tableHeaderTextColor(
                          activityType
                        )} uppercase tracking-wider text-right`}
                      >
                        Preview or Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {data?.map(activity => (
                      <ActivityTableItem activity={activity} key={activity.title + activity.date} />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityTable;
