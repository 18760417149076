import { useState } from 'react';
import validator from 'validator';

export const useUrlValidator = (urlType: 'relative' | 'absolute', validity = false) => {
  const [invalidUrl, setInvalidUrl] = useState(validity);

  const config = urlType === 'absolute' ? { require_protocol: true } : { allow_protocol_relative_urls: true };

  const setUrlValidity = (value: string) => {
    if (validator.isURL(value, config)) {
      setInvalidUrl(false);
    } else {
      setInvalidUrl(true);
    }
  };

  return { invalidUrl, setUrlValidity };
};
