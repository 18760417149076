import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { Partner } from 'models/partner';
import { useQuery } from 'react-query';

// Get all unselected partners
export const useAvailablePartners = (businessName: string) => {
  return useQuery<Partner[], Error>(
    'AvailablePartners',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Partners/All`,
        generateGetHeaders(userDetails, businessName)
      );
      return data;
    },
    { enabled: !!businessName, refetchOnWindowFocus: false }
  );
};

// Get all selected partners
export const useSelectedPartners = (businessName: string) => {
  return useQuery<Partner[], Error>(
    'selectedPartners',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Partners/`,
        generateGetHeaders(userDetails, businessName)
      );
      return data;
    },
    { enabled: !!businessName, refetchOnWindowFocus: false }
  );
};
