import { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import { useBusinessDetails } from 'api/queries/useBusinessDetails';
import { EditContext } from 'context/EditContext';
// Components
import BarLoader from 'react-spinners/BarLoader';
import Layout from 'components/Layout/Layout';
import SavePrompt from 'components/SavePrompt';
import NotificationMessage, { NotificationStyles } from 'components/Notification';

import ThemeFields from 'components/MyBusiness/ThemeFields';
import BusinessSocials from 'components/MyBusiness/BusinessSocials';
import InformationCard from 'components/InformationCard';
import WebsiteConfigFields from 'components/MyBusiness/WebsiteConfigFields';
import MyCrmInfo from 'components/MyBusiness/MyCrmInfo';
import ErrorModal from 'components/Modals/ErrorModal';
import TrackingBoard from 'components/MyBusiness/TrackingBoard';
import BusinessSettings from 'components/MyBusiness/BusinessSettings';
import SaveSvg from 'components/Svg/SaveSvg';
// Commands
import { useCommands } from 'hooks/useCommands';

const MyBusinessScreen = () => {
	const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();
	const { businessName } = useParams<{ businessName: string }>();
	useBusinessAuth(businessName);
	const { data: businessData, isError, isLoading, error } = useBusinessDetails(businessName);
	const { isDirty } = useContext(EditContext);
	const [loading, setLoading] = useState(false);
	const [newSlug, setNewSlug] = useState('');
	useEffect(() => {
		if (showSuccess && newSlug === '') {
			window.location.reload();
		}
		if (showSuccess && newSlug !== '') {
			window.location.href = `/admin/businesses`;
		}
	}, [showSuccess, newSlug]);

	const handleSave = () => {
		commands.send();
		setLoading(true);
	};
	return (
		// will display colours for BYOB businesses, Corporate will be able to change colours for BYOB businesses
		<Layout
			pageTitle={'My Business'}
			buttons={
				<div className='flex items-center'>
					{newSlug && <div className='text-red-500'>Warning: Saving will include slug change</div>}
					<button
						className='inline-flex
      mx-2 my-2  items-center px-4 py-2 border disabled:opacity-50 border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						disabled={!isDirty}
						onClick={handleSave}
					>
						<SaveSvg />
						Save Details
					</button>
				</div>
			}
		>
			<ErrorModal open={isError || showError} error={error} errorMessage={errorMessage} />
			<NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message={'Update Successful'} />

			<div className='max-w-7xl mx-auto'>
				<InformationCard>
					If there is a pen icon next to the field you can edit the information here. Otherwise you’ll need to edit the information in MyCRM and it’ll
					sync across to your website accordingly.
				</InformationCard>
			</div>
			<div className='w-full flex'>
				<BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
				<BarLoader height='4px' width='100%' loading={loading} color='#00AAE5' />
			</div>

			{businessData ? (
				<>
					<MyCrmInfo business={businessData} commands={commands} setNewSlug={setNewSlug} />
					<WebsiteConfigFields commands={commands} business={businessData} />
					<BusinessSocials commands={commands} business={businessData} />
					<TrackingBoard commands={commands} business={businessData} success={showSuccess} />
					<ThemeFields commands={commands} business={businessData} />
					<BusinessSettings business={businessData} commands={commands} />
				</>
			) : (
				<p>Nothing to show</p>
			)}
			<SavePrompt />
		</Layout>
	);
};

export default MyBusinessScreen;
