import { Command } from 'commands/Commands';

export class MediaRequestApproval extends Command {
  type: string = 'Media.RequestApproval';
  priority: number = 150;
}

export class MediaApprove extends Command {
  type: string = 'Media.Approve';
  priority: number = 150;
}

export class MediaReject extends Command {
  type: string = 'Media.Reject';
  priority: number = 150;
}
