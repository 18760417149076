import { useState } from 'react';

import MessageModal from './ButtonModals/MessageModal';

type Props = {
  text?: string;
  addApproveCommand: (message: string) => void;
  enable?: boolean;
  isDropDown?: boolean;
};

const ApproveButton = ({ text: textFromParent, addApproveCommand, enable = true, isDropDown }: Props) => {
  const text = textFromParent ?? 'Approve & Publish';
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('You are approved');

  const publishHandler = () => {
    addApproveCommand(message);

    setOpen(false);
    setMessage('');
  };

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  return (
    <>
      <button
        disabled={!enable}
        onClick={openModal}
        className={
          isDropDown
            ? 'group flex rounded-md items-center w-full px-2 py-2 text-sm text-blue-lm hover:bg-blue-light disabled:opacity-50'
            : 'inline-flex items-center px-4 py-2  my-2  mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
        }
      >
        <svg className='-ml-1 mr-2 h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
          <path
            fillRule='evenodd'
            d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
            clipRule='evenodd'
          />
        </svg>
        {text}
      </button>
      <MessageModal
        modalType='approve'
        description='Please write a detailed approval message'
        title='Approval Message'
        message={message}
        setMessage={setMessage}
        sendHandler={publishHandler}
        onClose={closeModal}
        open={open}
      />
    </>
  );
};

export default ApproveButton;
