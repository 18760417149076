import { Command } from 'commands/Commands';


export class UpdatePageRedirect extends Command {
  type: string = 'UpdatePageRedirect';
  constructor(public newValue: string[]) {
    super(newValue)
  }
  priority: number = 100;

}
