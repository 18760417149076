import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

// Basic component with logout button
const SignOut = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    window.localStorage.clear();
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    await oktaAuth.signOut();
  };

  return (
    <button
      className='text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full mb-3'
      onClick={logout}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
        />
      </svg>
      Logout
    </button>
  );
};

export default SignOut;
