import { useState } from 'react';

import MessageModal from './ButtonModals/MessageModal';
import WarningSvg from 'components/Svg/WarningSvg';
type Props = {
  addRejectCommand: (message: string) => void;
  isDropDown?: boolean;
};

const RejectButton = ({ addRejectCommand, isDropDown = false }: Props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  // Button will only be accessible to corporate users
  // Button should open a modal to input a message as to why this item was rejected
  const rejectHandler = () => {
    addRejectCommand(message);
    setOpen(false);
    setMessage('');
  };
  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  return (
    <>
      <button
        onClick={openModal}
        className={
          isDropDown
            ? 'group flex rounded-md items-center w-full px-2 py-2 text-sm text-red-700 hover:bg-red-100 disabled:opacity-50'
            : 'inline-flex items-center px-4 py-2 mx-2 border  my-2 border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        }
      >
        <WarningSvg />
        Reject
      </button>
      <MessageModal
        modalType='reject'
        onClose={closeModal}
        open={open}
        message={message}
        setMessage={setMessage}
        sendHandler={rejectHandler}
        title='Rejection message'
        description='Please write a detailed rejection message'
      />
    </>
  );
};

export default RejectButton;
