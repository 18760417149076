import { Command } from 'commands/Commands';


export class AddRedirect extends Command {
  type: string = 'AddRedirect';
  constructor(public newValue: string[]) {
    super(newValue)
  }
  priority: number = 100;

}
