import React from 'react';
import noImage from '../images/noImage.svg';

type Props = {
  src: string | undefined;
  className?: string;
  alt: string;
  style?: React.CSSProperties;
};

const Image = ({ src, className, alt, style }: Props) => {
  return (
    <img
      className={className}
      style={style}
      alt={alt}
      src={src}
      onError={(e: React.ChangeEvent<HTMLImageElement>) => {
        e.target.onerror = null;
        e.target.src = noImage;
      }}
    />
  );
};

export default Image;
