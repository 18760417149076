import React, { useEffect, useState } from 'react';

import Layout from 'components/Layout/Layout';
import { Partner } from 'models/partner';
import { useParams } from 'react-router-dom';
import MediaInput from 'components/Inputs/MediaInput';
import TextInput from 'components/Inputs/TextInput';
import { ChangePartnerTitle } from 'commands/partners/ChangePartnerTitle';
import { ChangePartnerImage } from 'commands/partners/ChangePartnerImage';
import { ChangePartnerAlt } from 'commands/partners/ChangePartnerAlt';
import RichTextEditorInput from 'components/Inputs/RichTextEditorInput';
import { ChangePartnerText } from 'commands/partners/ChangePartnerText';
import { ChangePartnerLinkText } from 'commands/partners/ChangePartnerLinkText';
import { ChangePartnerLinkUrl } from 'commands/partners/ChangePartnerLinkUrl';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import ApprovalButtons from 'components/Buttons/ApprovalButtons';
import UrlInput from 'components/Inputs/UrlInput';
import { useSelectedPartners } from 'api/queries/usePartners';
import { Approve, RequestApproval, Reject } from 'commands/partners/PartnerApproval';
import { useTimedGoBack } from 'hooks/useTimedGoBack';
import ErrorModal from 'components/Modals/ErrorModal';
import { useUrlValidator } from 'hooks/useUrlValidator';
import { useCommands } from 'hooks/useCommands';
import { blankMedia, IMedia } from 'models/media';

type partnerProperties = 'imageUrl' | 'imageAlt' | 'linkText' | 'linkUrl' | 'text' | 'title';

const PartnerEditScreen = () => {
  const { id, businessName } = useParams<{ id: string; businessName: string }>();
  const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();

  useTimedGoBack(showSuccess);

  const { data, isError, error } = useSelectedPartners(businessName);
  const [partnerContent, setPartnerContent] = useState<Partial<Partner>>({});
  const { setUrlValidity } = useUrlValidator('absolute');

  useEffect(() => {
    if (data) {
      const partnerDetails = data?.filter(partner => partner.id === id)[0];
      setPartnerContent(partnerDetails!);
    }
  }, [data, id]);

  const changePartnerState = (propertyName: partnerProperties, value: string) => {
    if (partnerContent) {

      setPartnerContent((prevState) => ({
        ...prevState,
        [propertyName]: value
      }));
    }
  };
  const clearImage = () => handleChangeImage(blankMedia);

  const handleChangeImage = (media: IMedia) => {
    changePartnerState('imageUrl', media.url);
    commands.add(new ChangePartnerImage(media.url, id));
  };

  const handleChangeAltText = (value: string) => {
    changePartnerState('imageAlt', value);
    commands.add(new ChangePartnerAlt(value, id));
  };

  const handleChangeTitle = (value: string) => {
    changePartnerState('title', value);
    commands.add(new ChangePartnerTitle(value, id));
  };

  const handleChangeBody = (value: string) => {
    changePartnerState('text', value);
    commands.add(new ChangePartnerText(value, id));
  };

  const handleChangeLinkUrl = (value: string) => {
    setUrlValidity(value);
    changePartnerState('linkUrl', value);
    commands.add(new ChangePartnerLinkUrl(value, id));
  };

  const handleChangeLinkText = (value: string) => {
    changePartnerState('linkText', value);
    commands.add(new ChangePartnerLinkText(value, id));
  };

  return (
    <Layout
      pageTitle={'Editing Partner'}
      buttons={
        <ApprovalButtons
          approval={partnerContent?.approval}
          addApproveCommand={message => commands.add(new Approve(message, id)).send()}
          addRejectCommand={message => commands.add(new Reject(message, id)).send()}
          addRequestApprovalCommand={() => commands.add(new RequestApproval('', id)).send()}
          addPublishCommand={message => commands.add(new Approve(message, id)).send()}
          save={() => commands.send()}
        />
      }
    >
      <ErrorModal open={isError || showError} error={error} errorMessage={errorMessage} />
      <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='Successfully sent!' />
      {partnerContent && (
        <>
          <MediaInput
            commands={commands}
            mediaType='Partner Image'
            id='ChangeImage'
            label='Partner Image'
            onSelectUmbracoMedia={handleChangeImage}
            onSelectLocalMedia={handleChangeImage}
            onClearMedia={clearImage}
            value={partnerContent.imageUrl ?? ""}
          />
          <TextInput value={partnerContent.altText ?? ""} id='ChangeAltText' label='Image Alt Text' handleFieldValue={handleChangeAltText} />
          <TextInput value={partnerContent.title ?? ""} id='ChangeTitle' label='Partner Title' handleFieldValue={handleChangeTitle} />
          <RichTextEditorInput
            handleFieldValue={handleChangeBody}
            id='ChangeBody'
            // Need a readonly value here, because the initialValue prop on tinyMCE can only be set once
            value={data!.filter(partner => partner.id === id)[0].text}
            label='Partner Description'
          />
          <TextInput handleFieldValue={handleChangeLinkText} value={partnerContent?.linkText ?? ""} label='Partner Link Text' id='ChangeLink' />
          <UrlInput value={partnerContent?.linkUrl ?? ""} label='Partner Url' id='ChangeUrl' handleFieldValue={handleChangeLinkUrl} urlType='absolute' />
        </>
      )}
    </Layout>
  );
};

export default PartnerEditScreen;
