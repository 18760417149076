import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';

import axios from 'axios';
import { Page } from 'models/page';
import { useQuery } from 'react-query';

// get list of templates
export const useTemplates = (businessName: string) => {
  return useQuery<Page[], Error>('templates', async () => {
    const userDetails = await getUserDetails();
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/Portal/Pages/Templates`,
      generateGetHeaders(userDetails, businessName)
    );
    return data;
  });
};
