import React, { useState } from 'react';

import { CommandBatch } from 'commands/Commands';

type BooleanInputProps = {
  label: string;
  id: string;
  value: boolean | string | undefined;
  commands: CommandBatch;
  handleToggleBlock?: (id: string, bool: boolean) => void;
  handleFieldValue?: (value: string, id: string) => void;
  isBlockToggle?: boolean;
};

const BooleanInput = ({ id, label, value, isBlockToggle, handleToggleBlock, handleFieldValue }: BooleanInputProps) => {
  const [inputValue, setInputValue] = useState(value === '' || value === 'false' ? false : !!value); // empty string is treated as false, string 'true'/'false' are coerced to boolean
  const switchValue = isBlockToggle ? value : inputValue;

  const changeHandler = () => {
    const newVal = !inputValue;
    setInputValue(newVal);
    if (handleFieldValue) handleFieldValue(newVal.toString(), id);
  };
  return (
    <>
      <div className={`flex items-center ${!isBlockToggle ? 'pt-3 pb-3' : ''}`}>
        <button
          onClick={() => (isBlockToggle && handleToggleBlock ? handleToggleBlock(id, !value) : changeHandler())}
          type='button'
          aria-pressed='false'
          aria-labelledby='toggleExample'
          className={`${
            switchValue ? 'bg-blue-lm' : 'bg-gray-200'
          } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          <span className='sr-only'>Use setting</span>

          <span
            aria-hidden='true'
            className={`${
              switchValue ? 'translate-x-5' : 'translate-x-0'
            } inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          ></span>
        </button>
        <span className='block text-sm font-medium text-gray-700 ml-3' id='toggleExample'>
          {label}
        </span>
      </div>
    </>
  );
};

export default BooleanInput;
