import { SetLocalMedia, SetUmbracoMedia } from 'commands/media/ChangeMediaField';

export class SetArticleHeroToLocalImage extends SetLocalMedia {
  type: string = 'BlogArticle.SetLocalImage';
  priority: number = 100;
}

export class SetArticleHeroToUmbracoImage extends SetUmbracoMedia {
  type: string = 'BlogArticle.SetUmbracoImage';
  priority: number = 100;
}
