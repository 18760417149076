export const previewUrlGenerator = (
  isPublic: boolean,
  byob: boolean,
  byobSlug: string,
  businessSlug: string,
  pageSlug: string,
  previewUrl: string
) => {
  const previewLinkTarget = (byob && isPublic ? `https://${byobSlug}` : `${previewUrl}/${businessSlug}`) + `/${pageSlug}?preview=true`;
  return previewLinkTarget;
};
