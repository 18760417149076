import React from 'react';
import { Transition } from '@headlessui/react';
import { Page } from 'models/page';
import StarSvg from 'components/Svg/StarSvg';
import Image from 'components/Image';

type Props = {
  template: Page;
  selectHandler: (template: Page) => void;
  isSelected: boolean;
};
const TemplateCard = ({ template, selectHandler, isSelected }: Props) => {
  return (
    <button className='relative overflow-hidden' onClick={() => selectHandler(template)}>
      <Image src={template.previewImage!} className='w-80' alt='template preview' />
      <div className='absolute top-0 right-0 w-full cursor-pointer '>
        <Transition
          show={isSelected}
          enter='transition ease-in-out duration-200 transform'
          enterFrom='translate-x-full opacity-0'
          enterTo='translate-x-0 opacity-100'
          leave='transition ease-in-out duration-200 transform'
          leaveFrom='translate-x-0 opacity-100'
          leaveTo='translate-x-full opacity-0'
          className='absolute top-0 right-0'
        >
          <div className='text-white flex px-6 py-3 bg-blue-lm   rounded-bl-md content-center '>
            <StarSvg />
            <p className='leading-tight'>Selected</p>
          </div>
        </Transition>
        <Transition
          show={!isSelected}
          enter='transition ease-in-out duration-200 transform'
          enterFrom='translate-x-full opacity-0'
          enterTo='translate-x-0 opacity-100'
          leave='transition ease-in-out duration-200 transform'
          leaveFrom='translate-x-0 opacity-100'
          leaveTo='translate-x-full opacity-0'
          className='absolute top-0 right-0'
        >
          <div className='text-white flex px-6 py-3 bg-gray-600 rounded-bl-md content-center opacity-50 hover:bg-blue-lm transition'>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 mr-2' viewBox='0 0 20 20' fill='currentColor'>
              <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
            </svg>
            <p className='leading-tight'>selected</p>
          </div>
        </Transition>
      </div>
      <p className='bg-gray-600 text-white'>{template.title}</p>
    </button>
  );
};

export default TemplateCard;
