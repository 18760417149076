import { useOktaAuth } from '@okta/okta-react';
import Layout from 'components/Layout/Layout';
import { useCallback, useEffect } from 'react';

export const Unauthorized = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = useCallback(async () => {
    window.localStorage.clear();
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    await oktaAuth.signOut();
  }, [oktaAuth]);

  useEffect(() => {
    const timedRedirect = window.setTimeout(() => {
      logout();

      window.location.href = '/login';
    }, 5000);

    return () => {
      clearTimeout(timedRedirect);
    };
  }, [logout]);

  return (
    <Layout pageTitle='Forbidden'>
      <h1 className='text-md'>
        You are not permitted to access this page. You will be redirected to
        login in 5 seconds...
      </h1>
    </Layout>
  );
};
