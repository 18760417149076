type Props = {
  status: 'Approved' | 'Awaiting Approval' | 'Rejected';
};

const ActivityStatus = ({ status }: Props) => {
  return (
    <span
      className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium mb-2 flex-grow-0 ml-2 md:ml-0 ${
        status === 'Approved'
          ? 'bg-green-100 text-green-700 '
          : status === 'Rejected'
          ? 'bg-red-100 text-red-700 '
          : status === 'Awaiting Approval' && 'bg-yellow-100 text-yellow-700 '
      }`}
    >
      {status}
    </span>
  );
};

export default ActivityStatus;
