import SaveSvg from 'components/Svg/SaveSvg';
import { useState } from 'react';

import DialogModal from 'components/Modals/DialogModal';

type SaveProps = {
  save: () => void;
  isDropDown?: boolean;
  text?: string;
  enable?: boolean;
  modalTitle?: string;
  modalDescription?: string;
  buttonText?: string;
};
const SaveButton = ({ save, isDropDown, text: textFromParent, enable = true, modalTitle, modalDescription, buttonText }: SaveProps) => {
  const text = textFromParent ?? 'Save';

  const modalTitleText = modalTitle ?? 'Save as a Draft?';
  const modalDescriptionText = modalDescription ?? 'Would you like to save your changes as a draft?';
  const modalButtonText = buttonText ?? 'Yes please';

  const [open, setOpen] = useState(false);

  const submitHandler = () => {
    save();
    setOpen(false);
  };
  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  window.addEventListener(
    'keydown',
    function (e) {
      if (e.key === 's' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
        openModal();
      }
    },
    false
  );

  return (
    <>
      {isDropDown ? (
        <button
          disabled={!enable}
          onClick={openModal}
          className=' group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-500 hover:bg-gray-100 disabled:opacity-50'
        >
          <SaveSvg />

          <span className='pl-2'>{text ?? 'Save Draft'}</span>
        </button>
      ) : (
        <button
          disabled={!enable}
          onClick={openModal}
          type='button'
          className='inline-flex
      mx-2 my-2  items-center px-4 py-2 border disabled:opacity-50 border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <SaveSvg />
          {text ?? 'Save Draft'}
        </button>
      )}

      {enable && (
        <DialogModal
          title={modalTitleText}
          description={modalDescriptionText}
          actionText={modalButtonText}
          open={open}
          closeModal={closeModal}
          submitHandler={submitHandler}
        />
      )}
    </>
  );
};

export default SaveButton;
