import { EditContext } from 'context/EditContext';
import { useContext, useEffect } from 'react';

export const useDirtyPageCleanUp = () => {
  const { isDirty, setDirty } = useContext(EditContext);
  useEffect(() => {
    return () => {
      if (isDirty) {
        setDirty(false);
      }
    };
  });
};
