import PlusSvg from 'components/Svg/PlusSvg';
import React from 'react';
import { Link } from 'react-router-dom';

type HeaderProps = {
  linkUrl: string;
  linkText: string;
};

const AddHeader = (props: HeaderProps) => {
  return (
    <Link
      className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      to={props.linkUrl}
    >
      <PlusSvg />
      {props.linkText}
    </Link>
  );
};

export default AddHeader;
