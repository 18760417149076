import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import DialogModal from 'components/Modals/DialogModal';
import TableData from 'components/Table/TableData';
import { Activity } from 'models/approval';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from '../util/formatDate';
import ActivityTableItemButtons from './ActivityTableItemButtons';

type Props = {
  activity: Activity;
};

const ActivityTableItem = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { date, operation, message, identifier, entityType, title } = props.activity;
  const { businessName } = useParams<{ businessName: string }>();
  const { data: businessAuth } = useBusinessAuth(businessName);

  return (
    <>
      <tr className='px-6 py-4 whitespace-nowrap bg-white hover:bg-gray-50'>
        <TableData>{entityType}</TableData>
        <TableData>{title}</TableData>
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className={` inline-flex text-xs rounded-full w-40  items-center h-auto `}>
            {operation === 'Awaiting Approval' ? (
              <p className='text-yellow-600 cursor-not-allowed font-medium'>Awaiting approval</p>
            ) : (
              <button
                className={`font-medium hover:bg-gray-200 rounded py-1 px-2 transition-all font-sm ${
                  operation === 'Approved' ? 'text-green-600 hover:bg-green-100' : 'text-red-600 hover:bg-red-100'
                }`}
                onClick={() => setModalOpen(true)}
              >
                View message
              </button>
            )}
          </div>
        </td>
        <TableData className='text-xs font-medium text-gray-500'>{formatDate(date)}</TableData>
        <ActivityTableItemButtons entityType={entityType} businessAuth={businessAuth} businessName={businessName} identifier={identifier} />
      </tr>
      <DialogModal open={modalOpen} description={message} title={operation} actionText={'Close'} closeModal={() => setModalOpen(false)} />
    </>
  );
};

export default ActivityTableItem;
