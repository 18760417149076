import { SvgProps } from 'models/svg';
import React from 'react';

const ClipboardCheckSvg = ({ className }: SvgProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-6 w-6 ${className || ''}`}
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4'
      />
    </svg>
  );
};

export default ClipboardCheckSvg;
