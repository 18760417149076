import { Award } from 'models/award';
import { DrawerData, DrawerItem } from 'components/AddDrawer/AddDrawer';
import Image from 'components/Image';

const AwardDrawerItem: DrawerItem<Award> = ({ imageUrl, altText }: DrawerData) => {
  return (
    <div className='h-20 flex items-center w-full space-x-2'>
      <Image className='w-10 h-10 object-cover' src={imageUrl} alt={altText} />
      <p className='font-medium text-gray-800'>{altText}</p>
    </div>
  );
};

export default AwardDrawerItem;
