import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import config from 'config';

import logo from 'images/site-logo.svg';
import axios from 'axios';

import { User } from 'models/user';

import { useContext } from 'react';
import { UserContext } from 'context/UserContext';

const LoginScreen = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const { setUser } = useContext(UserContext);

  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const { issuer, clientId, redirectUri, scopes } = config;

    const widget: any = new OktaSignIn({
      baseUrl: issuer!.split('/oauth2')[0],
      clientId,
      redirectUri,

      logo: logo,
      i18n: {
        en: {
          'primaryauth.title': 'Login to My Site'
        }
      },
      authParams: {
        issuer,
        scopes
      },
      useInteractionCodeFlow: false
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res: any) => {
        axios
          .get<User>(`${process.env.REACT_APP_API}/Portal/User/Login/${res.tokens.idToken.claims.email}`, {
            headers: {
              Authorization: `Bearer ${res.tokens.idToken.idToken}`
            }
          })
          .then(response => {
            const user = response.data;
            setUser(user);
            user.businesses.length >= 2 || user.corporateUser
              ? (window.location.href = `/admin/businesses`)
              : (window.location.href = `/${response.data.businesses[0]}`);
          })
          .catch(reason => {
            window.location.href = `/unauthorised`;
          });
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (err: any) => {
        throw err;
      }
    );

    return () => widget.remove();
  }, [oktaAuth, setUser]);

  return <div>{authState.isAuthenticated ? <div>logging in...</div> : <div ref={widgetRef} />}</div>;
};

export default LoginScreen;
