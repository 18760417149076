import { Page } from 'models/page';
import EyeSvg from 'components/Svg/EyeSvg';

type PageTemplateTableItemProps = {
  initialVal?: string;
  index: number;
  pagetemplate: Page;
};

const PageTemplateItem = (props: PageTemplateTableItemProps) => {
  const index = props.index;

  return (
    <>
      <tr className={`${index! % 2 === 0 ? 'bg-white' : ' bg-gray-50'}`}>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{props.pagetemplate.title}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{props.pagetemplate.slug}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className=' border-gray-700 px-2 pt-1 rounded-md text-center'>
            <a
              className={
                ' cursor-pointer inline-flex  items-center px-4 py-2 border disabled:opacity-50 border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 '
              }
              href={'/admin/custom-page-template-list-viewer' + `?templateSlug=${props.pagetemplate.slug}`}
            >
              <EyeSvg isHidden={false} />
            </a>
          </div>
        </td>
      </tr>
    </>
  );
};

export default PageTemplateItem;
