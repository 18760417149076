import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';

import axios from 'axios';
import { Container, Page } from 'models/page';
import { useQuery } from 'react-query';

// Get list of pages and containers
export const usePages = (businessName: string) => {
  return useQuery<(Container | Page)[], Error>(
    'pages',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Pages`,
        generateGetHeaders(userDetails, businessName)
      );
      return data;
    },
    {
      enabled: !!businessName,
    }
  );
};
