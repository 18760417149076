import React from 'react';

import CloseSvg from 'components/Svg/CloseSvg';
import NavLinks from 'components/Navigation/NavLinks';

type ToggleProps = {
  toggleHandler: () => any;
};
const MobileNav = ({ toggleHandler }: ToggleProps) => {
  return (
    <div>
      {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
      <div className='lg:hidden'>
        <div className='fixed inset-0 flex z-40'>
          {/* <!--
              Off-canvas menu overlay, show/hide based on off-canvas menu state.

                Entering: "transition-opacity ease-linear duration-300"
                From: "opacity-0"
                To: "opacity-100"
                Leaving: "transition-opacity ease-linear duration-300"
                From: "opacity-100"
                To: "opacity-0"
                --> */}
          <div className='fixed inset-0'>
            <div
              className='absolute inset-0 bg-gray-600 opacity-75'
              onClick={toggleHandler}
            ></div>
          </div>
          {/* <!--
                Off-canvas menu, show/hide based on off-canvas menu state.

                Entering: "transition ease-in-out duration-300 transform"
                From: "-translate-x-full"
                To: "translate-x-0"
                Leaving: "transition ease-in-out duration-300 transform"
                From: "translate-x-0"
                To: "-translate-x-full"
                --> */}
          <div
            tabIndex={0}
            className='relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none'
          >
            <div className='absolute top-0 right-0 -mr-12 pt-2'>
              <button
                onClick={toggleHandler}
                type='button'
                className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white text-white'
              >
                <span className='sr-only'>Close sidebar</span>
                <CloseSvg />
              </button>
            </div>
            <NavLinks />
          </div>
          <div className='flex-shrink-0 w-14' aria-hidden='true'></div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
