import React, { useContext, useState } from 'react';
import { CommandBatch } from 'commands/Commands';
import { EditContext } from 'context/EditContext';
import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';
import { useSpecialisations } from 'api/queries/useSpecialisations';
import { useParams } from 'react-router-dom';
import { Specialisation } from 'models/business';
import { ChangeSpecialisations } from 'commands/business/ChangeSpecialisations';

type Props = {
  selectedSpecialisations?: string[];
  commands: CommandBatch;
};

const BusinessSpecialisations = ({ selectedSpecialisations, commands }: Props) => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data: specialisations } = useSpecialisations(businessName);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { setDirty } = useContext(EditContext);
  const [selected, setSelected] = useState(selectedSpecialisations);

  const handleCheckedItem = (specialisation: Specialisation) => {
    setDirty(true);
    let newArr = selected?.includes(specialisation.value)
      ? selected.filter(spec => spec !== specialisation.value)
      : [...selected!, specialisation.value];
    setSelected(newArr);
    if (newArr.length > 0) {
      commands.add(new ChangeSpecialisations(newArr));
    } else {
      setDirty(false);
    }
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
        <h3 className='block text-lg text-gray-700 p-1'>Specialisations:</h3>
        <div className='flex items-center'>
          {isEditing ? (
            <ul className='flex items-center flex-wrap'>
              {specialisations?.map(specialisation => (
                <li key={specialisation.value} className='mx-5 text-gray-700'>
                  <label className='flex items-center'>
                    <input
                      onChange={() => handleCheckedItem(specialisation)}
                      type='checkbox'
                      value={specialisation.value}
                      checked={selected?.includes(specialisation.value)}
                    />
                    <span className='ml-2'>{specialisation.name}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <ul className='list-disc flex itens-center flex-wrap'>
              {specialisations
                ?.filter(checkbox => selected?.includes(checkbox.value))
                ?.map(checkbox => (
                  <li key={checkbox.value} className='mx-5 text-gray-700'>
                    {checkbox.name}
                  </li>
                ))}
            </ul>
          )}
          {selected!.length <= 0 ? (
            <p className='text-red-500 bg-red-100 text-sm block rounded py-1 px-2'>Must select at least one specialisation</p>
          ) : null}
          <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} />
        </div>
      </div>
    </>
  );
};

export default BusinessSpecialisations;
