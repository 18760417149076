// Components
import Layout from 'components/Layout/Layout';
import BlogList from 'components/Blog/BlogList';

import { useParams } from 'react-router-dom';
import AddHeader from 'components/Layout/AddHeader';
import { useBlog, useFeaturedArticles } from 'api/queries/useBlog';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import ErrorModal from 'components/Modals/ErrorModal';

const BlogScreen = () => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data: authData } = useBusinessAuth(businessName);
  const { data: blogData, isError, error } = useBlog(businessName);

  const { data: featuredIds } = useFeaturedArticles(businessName);

  return (
    <Layout pageTitle={'Your Blog Posts'} buttons={authData?.hasBlog && <AddHeader linkText='Create' linkUrl={`/${businessName}/blog-create`} />}>
      <div className='flex-1 relative z-0 flex overflow-hidden'>
        <main className='bg-gray-50 flex-1 relative z-0 p-8 overflow-y-auto focus:outline-none' tabIndex={0}>
          <div>
            <div className=''>
              <div>
                <div className='pb-5'>
                  <ErrorModal open={isError} error={error} />
                  {blogData && <BlogList data={{ articles: blogData }} featuredIds={featuredIds ? featuredIds?.filter(id => id !== '') : []} />}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default BlogScreen;
