import { useActivityFeed } from 'api/queries/useActivityFeed';
import ClipboardList from 'components/Svg/ClipboardList';

import { Link, useParams } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import ActivityItem from './ActivityItem';

const ActivityFeed = () => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data, isLoading, isError } = useActivityFeed(businessName);

  return (
    <aside className='relative xl:flex xl:flex-col overflow-y-auto flex-shrink-0 w-96 border-l border-gray-200 hidden'>
      <div className='absolute inset-0 py-6 px-4 sm:px-6 lg:px-8 z-10'>
        <div className='h-full '>
          <div className='py-6 flex align-center justify-between text-gray-600'>
            <h2 className='text-xl leading-none text-gray-800 font-medium'>Recent Activity</h2>
            <Link to={`${businessName}/activity`} className='flex items-end text-sm font-medium align-baseline hover:text-gray-500 transition-all'>
              <ClipboardList />
              <p className='underline'>Go to activity</p>
            </Link>
          </div>

          <div className='flow-root'>
            {isError && <p className='text-gray-500'>An error occured</p>}
            <ul className='mb-8'>
              {isLoading ? (
                <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
              ) : (
                data?.map(item => <ActivityItem activity={item} key={item.title + item.date} />)
              )}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default ActivityFeed;
