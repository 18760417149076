import { generateGetHeaders } from 'api/generateGetHeaders';
import axios from 'axios';
import { redirects } from 'models/redirect';
import { useQuery } from 'react-query';

// Get list of redirects
export const useRedirects = () => {
    return useQuery<redirects[], Error>('redirects', async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Businesses/Redirect`,
        generateGetHeaders()
      );
      return data;
    });
  };