export enum FieldType {
  Text = 0,
  RichTextEditor = 1,
  Boolean = 2,
  Url = 3,
  Color = 4,
  CheckBoxList = 5,
  RadioList = 6,
  Select = 7,
  Media = 8,
  TeamMemberPicker = 9,
  CodeEditor = 10
}

export class Field {
  displayName: string;
  name: string;
  id: string;
  value: string;
  type: FieldType;
  mediaName: string | null;
  allowedValues: string[] = [];
}
