import React from 'react';
import ActionCards from 'components/ActionCards';

import Layout from 'components/Layout/Layout';
import { useParams } from 'react-router-dom';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';

const HomeScreen = () => {
  const { businessName } = useParams<{ businessName: string }>();
  useBusinessAuth(businessName);
  return (
    <Layout activityFeed={true} pageTitle={'Welcome back!'}>
      <ActionCards />
      {/* <LatestNews /> */}
    </Layout>
  );
};

export default HomeScreen;
