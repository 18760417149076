import React, { useState, useEffect } from 'react';
import { Member } from 'models/member';
import Drawer from 'components/Layout/Drawer';
import TeamMemberDetailsVisibilityCard from './TeamMemberDetailsVisibilityCard';
import TeamMemberDetailsEditCard from './TeamMemberDetailsEditCard';
import { ChangeMemberEmailVisibility } from 'commands/teamMembers/ChangeMemberEmailVisibility';
import { ChangeMemberFacebookVisibility } from 'commands/teamMembers/ChangeMemberFacebookVisibility';
import { ChangeMemberLinkedInVisibility } from 'commands/teamMembers/ChangeMemberLinkedInVisibility';
import { ChangeMemberTwitterVisibility } from 'commands/teamMembers/ChangeMemberTwitterVisibility';
import { ChangeMemberInstagramVisibility } from 'commands/teamMembers/ChangeMemberInstagramVisibility';
import { ChangeMemberYoutubeVisibility } from 'commands/teamMembers/ChangeMemberYoutubeVisibility';
import { ChangeMemberPhoneVisibility } from 'commands/teamMembers/ChangeMemberPhoneVisibility';
import { ChangeCtrLink } from 'commands/teamMembers/ChangeCtrLink';
import { ChangeMemberUrl } from 'commands/teamMembers/ChangeMemberUrl';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import { useQueryClient } from 'react-query';
import { ChangeBio } from 'commands/teamMembers/ChangeBio';
import { ChangeMemberMobileVisibility } from 'commands/teamMembers/ChangeMemberMobileVisibility';
import Image from 'components/Image';
import ErrorModal from 'components/Modals/ErrorModal';
import { useCommands } from 'hooks/useCommands';
import TeamMemberDisclosurePicker from './TeamMemberDisclosurePicker';
import { ChangeDisclosureStatement } from 'commands/teamMembers/ChangeDisclosureStatement';
import TeamMemberCtaSelect from './TeamMemberCtaSelect';
import { ChangeContactButtonText } from 'commands/teamMembers/ChangeContactButtonText';

type TeamMemberEditProps = {
  toggleHandler: () => void;

  selectedMember: Member | undefined;
};

const TeamMemberEditDrawer = ({ toggleHandler, selectedMember }: TeamMemberEditProps) => {
  const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();
  const queryClient = useQueryClient();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handlePublish = () => {
    commands.send();
    setShowSuccess(true);
  };

  useEffect(() => {
    if (showSuccess) {
      var timer = setTimeout(() => {
        toggleHandler();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showSuccess, toggleHandler]);

  useEffect(() => {
    return () => {
      queryClient.refetchQueries('teamMembers');
    };
  }, [queryClient, showSuccess]);

  return (
    <Drawer
      toggleHandler={toggleHandler}
      title='Edit Team Member'
      description='Click the eye icon to show/hide information about this team member. If there is a pen icon next to the field you can edit the information here. Otherwise you’ll need to edit the information in MyCRM and it’ll sync across to your website accordingly'
    >
      <ErrorModal open={showError} error={null} errorMessage={errorMessage} />
      <NotificationMessage
        message={'Successfully saved'}
        type={NotificationStyles.Happy}
        setShow={setShowSuccess}
        show={!showError && showSuccess}
        drawer={true}
      />
      <div className='mt-6 relative flex-1 space-y-6'>
        <div className='space-y-6 px-6'>
          <div className='flex flex-row'>
            <div className='flex w-full'>
              <Image className='h-24 w-24 rounded-full object-cover flex-none' src={selectedMember!.imageUrl} alt='Team member' />
              <div className='ml-5'>
                <h3 className='font-medium text-gray-600 mt-3 text-lg'>{selectedMember!.name}</h3>
                <p className='text-gray-400 text-sm font-medium'>Preferred first name: {selectedMember!.preferredFirstName}</p>
                <p className='text-gray-400 text-sm font-medium'>Last name: {selectedMember!.lastName}</p>
                <p className='text-gray-500'>{selectedMember!.role.value}</p>
                <p className='text-gray-500'>{selectedMember!.myCrmId}</p>
              </div>
            </div>
          </div>

          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberEmailVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.email}
            detailName='Email'
          />
          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberPhoneVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.phone}
            detailName='Phone'
          />
          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberMobileVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.mobile}
            detailName='Mobile'
          />
          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberYoutubeVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.youtube}
            detailName='YouTube'
          />
          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberFacebookVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.facebook}
            detailName='Facebook'
          />
          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberLinkedInVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.linkedIn}
            detailName='LinkedIn'
          />
          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberTwitterVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.twitter}
            detailName='Twitter'
          />
          <TeamMemberDetailsVisibilityCard
            visibilityCommand={newValue => new ChangeMemberInstagramVisibility(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.instagram}
            detailName='Instagram'
          />
          <TeamMemberDetailsEditCard
            editCommand={newValue => new ChangeBio(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.bio}
            detailName='Bio'
            allowEmpty={false}
          />

          <TeamMemberCtaSelect
            teamMemberId={selectedMember!.employeeId}
            editCommand={newValue => new ChangeCtrLink(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.ctrLink}
            calendlyUrl={selectedMember!.calendlyUrl}
            mlgUrl={selectedMember!.mlgUrl}
            customUrl={selectedMember?.customUrl}
            detailName='Call to action URL'
          />

          <TeamMemberDetailsEditCard
            editCommand={newValue => new ChangeContactButtonText(newValue, selectedMember!.employeeId)}
            commands={commands}
            detail={selectedMember!.contactButtonText}
            detailName='Call to action Button text'
            allowEmpty={true}
          />

          <TeamMemberDetailsEditCard
            editCommand={newValue => new ChangeMemberUrl(newValue, selectedMember!.employeeId)}
            setDisableButton={setDisableButton}
            commands={commands}
            detail={selectedMember!.memberUrl}
            detailName='Member Url'
            allowEmpty={false}
          />

          {selectedMember?.disclosureStatement && (
            <TeamMemberDisclosurePicker
              editCommand={newValue => new ChangeDisclosureStatement(newValue, selectedMember!.employeeId)}
              commands={commands}
              detail={selectedMember!.disclosureStatement}
              detailName='Disclosure Statement (NZ only)'
            />
          )}
          <div>
            <button
              onClick={handlePublish}
              type='button'
              disabled={disableButton}
              className='inline-flex items-center justify-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default TeamMemberEditDrawer;
