import React, { useContext, useState } from 'react';
// Components
import CheckSvg from 'components/Svg/CheckSvg';
import PencilSvg from 'components/Svg/PencilSvg';
import RefreshSvg from 'components/Svg/RefreshSvg';
import { EditContext } from 'context/EditContext';
import { UserContext } from 'context/UserContext';
// Commands
import { ChangeColor } from 'commands/business/Colors/ChangeColor';
import { CommandBatch, ICommand } from 'commands/Commands';

type Props<TCommand extends ICommand> = {
  label: string;
  initialVal: string;
  commands: CommandBatch;
  commandFactory: (payload: string) => TCommand;
};
const BusinessColorsInput = ({ label, initialVal, commands, commandFactory }: Props<ChangeColor>) => {
  const strippedHashValue = initialVal.charAt(0) === '#' ? initialVal.substr(1) : initialVal;
  const [value, setValue] = useState<string>(strippedHashValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [invalidColorText, setInvalidColorText] = useState(false);
  const { user } = useContext(UserContext);
  const { setDirty } = useContext(EditContext);
  const corporateUser = user.corporateUser;

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      handleEditing();
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setDirty(true);
    if (inputValue.length < 7 && (/^[0-9a-f]+$/.test(inputValue) || inputValue === '')) {
      setValue(inputValue);
      commands.add(commandFactory(inputValue));
    }
  };

  const handleReset = () => {
    setValue(strippedHashValue);
    if (strippedHashValue === value) {
      setIsEditing(false);
    }
  };

  const handleEditing = () => {
    if (/^([0-9a-f]{3}|[0-9a-f]{6})$/i.test(value)) {
      setIsEditing(false);
      setInvalidColorText(false);
    } else {
      setInvalidColorText(true);
    }
  };

  const checkSvg = (
    <>
      <button onClick={handleEditing} className='transition transform hover:opacity-50 no-outline text-gray-600'>
        <CheckSvg />
      </button>
      <button onClick={handleReset} className='transition transform hover:opacity-50 no-outline'>
        <RefreshSvg />
      </button>
    </>
  );

  const editableButton = (
    <div>
      <button
        onClick={() => setIsEditing(!isEditing)}
        className='focus:outline-none no-outline transition transform hover:opacity-50 ml-5 text-gray-700'
      >
        <PencilSvg />
      </button>
    </div>
  );

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
        <h3 className='block text-lg text-gray-700 p-1'>{label}</h3>
        {corporateUser ? (
          <div className='flex '>
            {isEditing ? (
              <>
                {invalidColorText && (
                  <p className='p-2 bg-red-100 text-red-500 rounded-md text-sm mr-5'>
                    <span className='  rounded-md font-medium text-md'>invalid colour, must be hexadecimal:</span>
                    {'  '}
                    must contain 3 or 6 characters e.g, fff or ffffff
                  </p>
                )}
                <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 text-gray-500 pt-3' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M7 20l4-16m2 16l4-16M6 9h14M4 15h14' />
                </svg>
                <input
                  type='text'
                  className='flex focus:ring-blue-lm focus:border-blue-lm  w-20 border-gray-200 border-2 rounded-md sm:text-sm p-1 mr-5 transition-all'
                  value={value}
                  onChange={handleChangeValue}
                  // onBlur={handleEditing}
                  autoFocus={isEditing}
                  onKeyPress={e => handleEnter(e)}
                />
              </>
            ) : (
              <div
                className='block rounded-full  mr-3 p-1 shadow-lg'
                style={{
                  backgroundColor: `#${value}`,
                  height: '30px',
                  width: '30px'
                }}
              />
            )}

            {isEditing && user.corporateUser ? checkSvg : editableButton}
          </div>
        ) : (
          <div>
            <div
              className='w-50 h-50 block rounded-full mr-5 p-1 shadow-lg'
              style={{
                backgroundColor: `#${value}`,
                height: '30px',
                width: '30px'
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BusinessColorsInput;
