import React from 'react';
type Props = {
  className?: string;
  justify?: '' | 'justify-end' | 'justify-center' | 'justify-start';
};
const TableData: React.FC<Props> = ({ children, className, justify = '' }) => {
  return (
    <td className='px-6 py-4 whitespace-nowrap'>
      <div className={`flex items-center ${justify}`}>
        <div className={className ? className : 'text-sm font-medium text-gray-500'}>{children}</div>
      </div>
    </td>
  );
};

export default TableData;
