export const isImageExtension = (url: string) => {
  
  if(url == null)
  {
    return false;
  }
  const imageExtensions = ['.png', '.jpg', '.svg', '.jpeg', '.webp', '.bmp', '.ico', '.gif'];
  const isImage = imageExtensions.some((extension) => {
    return url.includes(extension)
  })

  return isImage
};
