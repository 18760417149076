import React, { useEffect, useState } from 'react';

import BlogCard from './BlogCard';
import PublishWithoutApprovalButton from 'components/Buttons/PublishWithoutApprovalButton';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import SearchInput from 'components/SearchInput';
import { Blog } from 'models/blog';

import BarLoader from 'react-spinners/BarLoader';

import { useParams } from 'react-router-dom';
import { useSearchState } from 'hooks/useSearchState';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';

import InformationCard from 'components/InformationCard';
import { useDerivedState } from 'hooks/useDerivedState';

import { AddFeaturedArticles } from 'commands/blog/AddFeaturedArticles';
import { useContext } from 'react';
import { EditContext } from 'context/EditContext';
import { useCommands } from 'hooks/useCommands';

type Props = {
  data: Blog;
  featuredIds: string[];
};

const BlogList = ({ data, featuredIds }: Props) => {
  const { commands, showError, showSuccess, errorMessage, setShowError, setShowSuccess } = useCommands();
  const [featuredArticles, setFeaturedArticles] = useDerivedState<string[]>(featuredIds);
  const [tooManyArticlesMessage, setTooManyArticlesMessage] = useState<boolean>(false);

  const { isDirty, setDirty } = useContext(EditContext);

  const { businessName } = useParams<{ businessName: string }>();
  const { data: businessDetails, isLoading } = useBusinessAuth(businessName);

  const { handleSearch, handleSearchSubmit, searchResults, searchValue } = useSearchState(article => article.title, data.articles);

  // // activate button
  useEffect(() => {
    if (featuredArticles.length === 3) {
      // // add featured articles to commands
      if (businessDetails?.id) commands.add(new AddFeaturedArticles(businessDetails?.id, featuredArticles.map(id => id).join('|')));
    } else if (featuredArticles.length === 0) {
      if (businessDetails?.id) commands.add(new AddFeaturedArticles(businessDetails?.id, null));
    }
  }, [featuredArticles, commands, businessDetails]);

  const handleFeaturedArticle = (clickedId: string): void => {
    const articleIsFeatured = featuredArticles.some(id => id === clickedId);
    // if there are no featured articles matching the selected article
    setDirty(true);
    if (!articleIsFeatured) {
      const selectedArticle = data.articles.find(article => article.id === clickedId)?.id;

      if (featuredArticles.length < 3) {
        setFeaturedArticles([...featuredArticles, selectedArticle!]);
      } else {
        setTooManyArticlesMessage(true);
      }
    } else {
      const withClickedArticleRemoved = [...featuredArticles].filter(id => id !== clickedId);

      setFeaturedArticles(withClickedArticleRemoved);
    }
  };

  return (
    <div>
      <NotificationMessage
        setShow={setTooManyArticlesMessage}
        show={tooManyArticlesMessage}
        type={NotificationStyles.Danger}
        message='You may only display 3 featured articles. If you wish to
                  change one, you must unselect a featured article.'
      />
      <NotificationMessage setShow={setShowError} show={showError} type={NotificationStyles.Danger} message={errorMessage} />
      <NotificationMessage setShow={setShowSuccess} show={showSuccess} type={NotificationStyles.Happy} message='Successful' />
      <div className='space-y-16'>
        <div className=''>
          <div className='max-w-7xl mx-auto'>
            <InformationCard>
              View all the blog posts that are available on your website and create new posts by clicking the “create” button. You can also choose 3
              key posts that you’d like to feature throughout your website - if you do not select featured posts we’ll display the 3 most recent
              posts.
            </InformationCard>
          </div>
          <div className='mb-6 flex justify-end max-w-7xl mx-auto'>
            {(featuredArticles.length === 0 || featuredArticles.length === 3) && (
              <PublishWithoutApprovalButton enable={isDirty} addCommand={() => commands.send()} text={'Publish changes to featured articles'} />
            )}
          </div>

          <SearchInput
            handleSearchSubmit={handleSearchSubmit}
            searchValue={searchValue}
            placeholder='Search for a blog article..'
            handleSearch={handleSearch}
          />
          <div className='pb-5'>
            <div className='relative max-w-7xl mx-auto'>
              <div className='max-w-l-full mx-auto flex'>
                <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
              </div>

              <div className='max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none'>
                {searchResults?.map(article => (
                  <BlogCard
                    article={article}
                    key={article.id}
                    handleFeaturedArticle={handleFeaturedArticle}
                    isFeatured={featuredArticles.some(featured => featured === article.id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
