import { generateGetHeaders } from 'api/generateGetHeaders';
import axios from 'axios';
import { Business } from 'models/business';
import { useQuery } from 'react-query';

// Get list of businesses
export const useBusinesses = () => {
  return useQuery<Business[], Error>('businesses', async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/Portal/Businesses/All`,
      generateGetHeaders()
    );
    return data;
  });
};
