import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as newGuid } from 'uuid';
import { useParams } from 'react-router';
// Components
import Layout from 'components/Layout/Layout';
import RichTextEditorInput from 'components/Inputs/RichTextEditorInput';
import TextInput from 'components/Inputs/TextInput';
import DateInput from 'components/Inputs/DateInput';
import MediaInput from 'components/Inputs/MediaInput';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import InputModal from 'components/Modals/InputModal';
import ApprovalButtons from 'components/Buttons/ApprovalButtons';
import PreviewButton from 'components/Buttons/PreviewButton';
// Commands
import { ChangeArticleTitle } from 'commands/blog/ChangeArticleTitle';
import { ChangeArticleBody } from 'commands/blog/ChangeArticleBody';
import { ChangeArticleDate } from 'commands/blog/ChangeArticleDate';
import { ChangeArticleAltText } from 'commands/blog/ChangeArticleAltText';

import { AddArticle } from 'commands/blog/AddArticle';
import { ChangeArticleMetaTitle } from 'commands/blog/ChangeArticleMetaTitle';
import { ChangeArticleMetaDescription } from 'commands/blog/ChangeArticleMetaDescription';
import { ChangeArticleAuthor } from 'commands/blog/ChangeArticleAuthor';
import { Approve, Reject, RequestApproval } from 'commands/blog/Approval';

import { useHistory } from 'react-router-dom';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import { useArticle } from 'api/queries/useArticle';
import { useDerivedState } from 'hooks/useDerivedState';
import { useTimedGoBack } from 'hooks/useTimedGoBack';
import { Approval } from 'models/approval';
import ErrorModal from 'components/Modals/ErrorModal';
import { useCommands } from 'hooks/useCommands';
import { SetArticleHeroToLocalImage, SetArticleHeroToUmbracoImage } from 'commands/blog/ChangeArticleHero';
import { IMedia } from 'models/media';
import { Delete } from 'commands/blog/Delete';
import DeleteButton from 'components/Buttons/DeleteButton';

type BlogEditScreenProps = {
  adding: boolean;
};

type ArticleProperties = 'posted' | 'imageAlt' | 'imageUrl' | 'title' | 'body' | 'metaTitle' | 'metaDescription' | 'author';

type EmptyArticleType = {
  posted: string;
  imageAlt: { value: string };
  imageUrl: { value: string };
  author: { value: string };
  title: { value: string };
  metaTitle: { value: string };
  metaDescription: { value: string };
  body: { value: string };
  approval: Approval;
};

const BlogEditScreen = ({ adding }: BlogEditScreenProps) => {
  const history = useHistory();
  const [newArticleId] = useState<string>(() => newGuid());
  const { commands, showError, showSuccess, errorMessage, setShowError, setShowSuccess } = useCommands();
  const { slug: articleSlug, businessName } = useParams<{ slug: string; businessName: string }>();
  const { data: businessAuthData } = useBusinessAuth(businessName);
  const { data: article, isSuccess, isError, error } = useArticle(articleSlug, adding, businessName);
  const [newArticleSlug, setNewArticleSlug] = useState<string>('');
  const [openModal, setOpenModal] = useState(true);

  useTimedGoBack(showSuccess);

  const emptyArticle: EmptyArticleType = useMemo(
    () => ({
      metaTitle: { value: '' },
      metaDescription: { value: '' },
      posted: new Date().toString(),
      imageAlt: { value: '' },
      imageUrl: { value: '' },
      author: { value: '' },
      body: { value: '' },
      title: { value: '' },
      approval: { approved: false, rejected: false, required: true, requested: false }
    }),
    []
  );
  const articleId = adding ? newArticleId : article?.id ?? '';

  const [blogContent, setBlogContent] = useDerivedState(adding ? emptyArticle : article || emptyArticle);

  const handleAddSlug = () => {
    commands.add(new AddArticle('New Article', newArticleSlug, newArticleId));
    setOpenModal(false);
  };

  const handleAddArticleClose = () => {
    history.push(`/${businessName}/blog-listing`);
  };

  const changeArticleState = (propertyName: ArticleProperties, value: string) => {
    const modifiedArticle = {
      ...blogContent,
      [propertyName]: { ...(blogContent[propertyName] as object), value: value }
    };
    setBlogContent(modifiedArticle);
  };

  const setHeroToLocalImage = (media: IMedia) => {
    changeArticleState('imageUrl', media.url);
    commands.add(new SetArticleHeroToLocalImage(articleId, media.id));
  };

  const setHeroToUmbracoImage = (media: IMedia) => {
    changeArticleState('imageUrl', media.url);
    commands.add(new SetArticleHeroToUmbracoImage(articleId, { umbracoId: parseInt(media.id), url: media.url }));
  };

  const handleChangeAlt = (value: string) => {
    changeArticleState('imageAlt', value);
    commands.add(new ChangeArticleAltText(articleId, value));
  };

  const handleChangeDate = (value: string) => {
    const modifiedArticle = { ...blogContent, posted: value };
    setBlogContent(modifiedArticle);
    commands.add(new ChangeArticleDate(articleId, value));
  };

  const handleChangeTitle = (value: string) => {
    changeArticleState('title', value);
    commands.add(new ChangeArticleTitle(articleId, value));
  };

  const handleChangeBody = (value: string) => {
    changeArticleState('body', value);

    commands.add(new ChangeArticleBody(articleId, value));
  };

  const handleChangeMetaTitle = (value: string) => {
    changeArticleState('metaTitle', value);
    commands.add(new ChangeArticleMetaTitle(articleId, value));
  };

  const handleChangeMetaDescription = (value: string) => {
    changeArticleState('metaDescription', value);
    commands.add(new ChangeArticleMetaDescription(articleId, value));
  };
  const handleChangeAuthor = (value: string) => {
    changeArticleState('author', value);
    commands.add(new ChangeArticleAuthor(articleId, value));
  };

  const checkValidity = useCallback(() => {
    if (blogContent) {
      const { imageAlt, imageUrl, title, body, posted, metaDescription, metaTitle, author } = blogContent;
      const blogContentValues = [metaTitle.value, metaDescription.value, imageAlt.value, imageUrl.value, title.value, body.value, posted, author];

      return blogContentValues.every(value => !!value); // Check if all values are not empty
    }
    return true;
  }, [blogContent]);

  useEffect(() => {
    if (businessAuthData?.hasBlog === false) {
      history.push(`/${businessName}/blog-listing`);
    }
  }, [businessAuthData, businessName, history]);

  return (
    <Layout
      showGoBackLink={true}
      pageTitle={adding ? 'Create blog post' : 'Edit blog post'}
      buttons={
        <>
          {!adding && <DeleteButton addDeleteCommand={() => commands.add(new Delete(articleId)).send()} autoClear={true} isMedia={false} />}
          <PreviewButton businessName={businessName} slug={'blog/' + articleSlug} enable={checkValidity()} />
          <ApprovalButtons
            approval={blogContent.approval}
            addApproveCommand={message => commands.add(new Approve(message, articleId)).send()}
            addPublishCommand={message => commands.add(new Approve(message, articleId)).send()}
            addRejectCommand={message => commands.add(new Reject(message, articleId)).send()}
            addRequestApprovalCommand={() => commands.add(new RequestApproval('', articleId)).send()}
            save={() => commands.send()}
          />
        </>
      }
    >
      <NotificationMessage type={NotificationStyles.Danger} show={showError} setShow={setShowError} message={errorMessage!} />
      <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='Successfully sent!' />

      <ErrorModal open={showError || isError} error={error} />

      {adding ? (
        <div className='absolute inset-0'>
          <div className='px-6 lg:px-8 mt-5'>
            <fieldset className='relative border-solid border-2 pt-1 px-5 pb-5 rounded  max-w-7xl mb-3'>
              <legend className='p-2 font-bold text-gray-500 text-lg'>Meta tags</legend>
              <TextInput label='Meta Title' value='' id='ChangeMetaTitle' handleFieldValue={handleChangeMetaTitle} />
              <TextInput label='Meta Description' value='' id='ChangeMetaDescription' handleFieldValue={handleChangeMetaDescription} />
            </fieldset>
            <label className='block text-lg font-medium text-gray-700'>Select a hero image</label>
            <InputModal
              saveText='Create Article'
              description='The slug should reflect the content of your article and be 3-5 words max.'
              title='Add a slug'
              inputValue={newArticleSlug}
              setInputValue={(value) =>
                setNewArticleSlug(
                  value
                    .toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^a-z0-9-]+/g, '')
                )
              }
              onClose={handleAddArticleClose}
              sendHandler={() => handleAddSlug()}
              open={openModal}
            />
            <MediaInput
              id='ChangeHero'
              label={'Hero Image'}
              value={''}
              mediaType='Hero Image'
              onSelectLocalMedia={setHeroToLocalImage}
              onSelectUmbracoMedia={setHeroToUmbracoImage}
            />
            <TextInput label='Image Alt Text' id='ChangeAltText' value='' handleFieldValue={handleChangeAlt} />
          </div>

          <div className='h-full pb-8 px-6 lg:px-8 '>
            <div className='space-y-3'>
              <DateInput handleFieldValue={handleChangeDate} />
              <TextInput label='Author' value='' id='ChangeAuthor' handleFieldValue={handleChangeAuthor} />
              <TextInput value='' id='ChangeTitle' label='Blog title' handleFieldValue={handleChangeTitle} />
              <div className='space-y-3'>
                <RichTextEditorInput value={''} id='ChangeBody' label='Blog body content' handleFieldValue={handleChangeBody} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        isSuccess &&
        article && (
          <div className='absolute inset-0'>
            <div className='px-6 lg:px-8 mt-5'>
              <fieldset className='relative border-solid border-2 pt-1 px-5 pb-5 rounded  max-w-7xl mb-3'>
                <legend className='p-2 font-bold text-gray-500 text-lg'>Meta tags</legend>
                <TextInput label='Meta Title' value={article.metaTitle.value} id='ChangeMetaTitle' handleFieldValue={handleChangeMetaTitle} />
                <TextInput
                  label='Meta Description'
                  value={article.metaDescription.value}
                  id='ChangeMetaDescription'
                  handleFieldValue={handleChangeMetaDescription}
                />
              </fieldset>
              <label className='block text-lg font-medium text-gray-700'>Select a Hero image</label>
              <MediaInput
                id={article.imageUrl.id}
                label={article.imageUrl.displayName}
                value={article.imageUrl.value}
                mediaType='Hero Image'
                commands={commands}
                onSelectLocalMedia={setHeroToLocalImage}
                onSelectUmbracoMedia={setHeroToUmbracoImage}
              />
              <TextInput label='Image Alt Text' id={'ChangeAltText'} value={article.imageAlt.value} handleFieldValue={handleChangeAlt} />
            </div>

            <div className='h-full pb-8 px-6 lg:px-8 '>
              <div className='space-y-3'>
                <DateInput handleFieldValue={handleChangeDate} value={article.posted} />
                <TextInput label='Author' value={article.author.value} id='ChangeAuthor' handleFieldValue={handleChangeAuthor} />
                <TextInput label='Blog title' id={'ChangeTitle'} value={article.title.value} handleFieldValue={handleChangeTitle} />
                <div className='space-y-3'>
                  <RichTextEditorInput value={article.body.value} label={'Blog body content'} id={'ChangeBody'} handleFieldValue={handleChangeBody} />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </Layout>
  );
};

export default BlogEditScreen;
