import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { Fonts } from 'models/business';
import { useQuery } from 'react-query';

export const useFontSelection = (businessName: string) => {
	return useQuery<Fonts[], Error>(
		['fontSelection', businessName],
		async () => {
			try {
				const userDetails = await getUserDetails();
				const { data } = await axios.get(
					`${process.env.REACT_APP_API}/Portal/FontSelection/fonts`,
					generateGetHeaders(userDetails, businessName)
				);
				return data;
			} catch (error) {
				throw new Error('Failed to fetch font selection');
			}
		},
		{
			refetchOnWindowFocus: true,
			retry: 2,
		}
	);
};