import Image from 'components/Image';
import Drawer from 'components/Layout/Drawer';
import PageSvg from 'components/Svg/PageSvg';
import { formatBytes } from 'components/util/formatBytes';
import { isImageExtension } from 'components/util/isImageExtension';
import { IMedia } from 'models/media';
import { useContext, useState } from 'react';
import { useDerivedState } from 'hooks/useDerivedState';
import { ChangeAlt } from 'commands/media/ChangeAlt';
import { useCommands } from 'hooks/useCommands';
import { UserContext } from 'context/UserContext';
import { MediaApprove, MediaRequestApproval } from 'commands/media/MediaApproval';
import MessageModal from 'components/Buttons/ButtonModals/MessageModal';
import DeleteButton from 'components/Buttons/DeleteButton';
import { DeleteMedia } from 'commands/media/DeleteMedia';

type Props = {
  toggleHandler: () => void;
  selectedMedia: IMedia | null;
};

const MediaDrawer = ({ selectedMedia, toggleHandler }: Props) => {
  const [altText, setAltText] = useDerivedState(selectedMedia?.altText ?? '');
  const { commands } = useCommands();
  const { user } = useContext(UserContext);

  const handleAltTextChange = (value: string) => {
    commands.add(new ChangeAlt(selectedMedia!.id, value));
    setAltText(value);
  };

  return (
    <>
      <Drawer
        toggleHandler={() => {
          toggleHandler();
          window.location.reload();
        }}
        title={selectedMedia!.name}
        description={'Viewing media'}
      >
        {isImageExtension(selectedMedia!.extension) ? (
          <Image src={selectedMedia!.url} alt='Selected Media' className='w-full h-80 mx-auto my-2 object-cover' />
        ) : (
          <PageSvg className='w-32 h-32 text-blue-lm' />
        )}

        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Alt text:</span>
          <span className='text-sm text-right'>{selectedMedia!.altText}</span>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Extension Type:</span>
          <span className='text-sm text-right'>{selectedMedia!.extension}</span>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Bytes:</span>
          <span className='text-sm text-right'>{formatBytes(parseInt(selectedMedia!.bytes), 2)}</span>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Url:</span>
          <a target='_blank' href={`${selectedMedia!.url}`} className='text-xs text-right underline' rel='noreferrer'>
            {selectedMedia!.url}
          </a>
        </div>
        <div className='flex justify-between items-center mt-3'>
          <span className='font-bold text-blue-lm'>Change Alt text:</span>
          <input
            id='altText'
            type='text'
            className='block border-2 px-2 py-3 w-full rounded-md '
            value={altText}
            onChange={e => handleAltTextChange(e.target.value)}
          />
        </div>
        <div>
          {user.corporateUser ? (
            <DeleteButton
              addDeleteCommand={() => {
                commands.add(new DeleteMedia(selectedMedia!.id)).send();
              }}
              autoClear={false}
              fullWidth={true}
              isMedia={true}
            />
          ) : null}
        </div>
      </Drawer>
    </>
  );
};

export default MediaDrawer;
