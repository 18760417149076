import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { BlogArticle } from 'models/blog';
import { useQuery } from 'react-query';

// Get list of blog articles
export const useBlog = (businessName: string) => {
  return useQuery<BlogArticle[], Error>(
    'blog',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Blog`, generateGetHeaders(userDetails, businessName));
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

// get featured blogs
export const useFeaturedArticles = (businessName: string) => {
  return useQuery<string[], Error>(
    'featuredArticles',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Blog/Featured`, generateGetHeaders(userDetails, businessName));
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};
