import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';
type DialogModalProps = {
	open: boolean;
	description: string;
	title: string;
	actionText: string;
	exitText?: string;
	closeModal: () => void;

	submitHandler?: () => void;

	isRed?: boolean;
};
const DialogModal = (props: DialogModalProps) => {
	const cancelButtonRef = useRef<HTMLDivElement>(null);
	return (
		<Transition show={props.open} as={Fragment}>
			<Dialog
				as='div'
				className='fixed inset-0 z-10 overflow-y-auto'
				initialFocus={cancelButtonRef}
				static
				open={props.open}
				onClose={props.closeModal}
			>
				<div className='min-h-screen px-4 text-center'>
					<Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className='inline-block h-screen align-middle' aria-hidden='true'>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 scale-100'
						leaveTo='opacity-0 scale-95'
					>
						<div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md min-h-300'>
							<Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
								{props.title}
							</Dialog.Title>
							<Dialog.Description className='text-grey-300'>{props.description}</Dialog.Description>

							<div className='mt-4'>
								<button
									type='button'
									className='inline-flex justify-center px-4 py-2 text-sm font-medium text-dark-900 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 '
									onClick={props.closeModal}
								>
									{props.exitText ? props.exitText : 'Cancel'}
								</button>
								{props.submitHandler && (
									<button
										type='button'
										className={`inline-flex justify-center px-4 py-2 text-sm font-medium ${props.isRed ? 'bg-red-700 text-white hover:bg-red-500' : 'bg-gray-300 text-dark-900 hover:bg-gray-200'
											} border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 ml-3`}
										onClick={props.submitHandler}
									>
										{props.actionText}
									</button>
								)}
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default DialogModal;
