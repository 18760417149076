import { SvgProps } from 'models/svg';
import React from 'react';

const ComputerSvg = ({ className }: SvgProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-6 w-6 ${className || ''}`}
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
      />
    </svg>
  );
};

export default ComputerSvg;
