import Image from 'components/Image';
import DialogModal from 'components/Modals/DialogModal';
import StatusTag from 'components/StatusTag';
import CloseSvg from 'components/Svg/CloseSvg';
import PencilSvg from 'components/Svg/PencilSvg';

import { Partner } from 'models/partner';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export interface PartnerProps extends Partner {
  handleRemoveItems?: () => void;
}

const PartnerCard = (props: PartnerProps) => {
  const { businessName } = useParams<{ businessName: string }>();

  const [toggleModal, setToggleModal] = useState(false);

  const handleRemove = () => {
    if (props.handleRemoveItems) {
      setToggleModal(false);
      props.handleRemoveItems();
    }
  };

  const decodeAndRemoveHTML = (string: string) => {
    return string
      .replace(/&apos;/g, "'")
      .replace(/&quot;/g, '"')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<')
      .replace(/&amp;/g, '&')
      .replace(/<[^>]*>?/gm, '');
  };

  return (
    <>
      <div className='flex flex-col space-y-5 p-5 bg-white shadow-lg rounded-lg  mx-auto relative my-3 min-w-full'>
        <div className=' absolute top-0 right-0  flex'>
          {props.editable === true && (
            <Link
              to={`/${businessName}/partner-edit/${props.id}`}
              className='inline-flex items-center p-3 border border-transparent shadow-sm text-blue-lm bg-blue-tint hover:bg-blue-tintd focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 no-outline rounded-bl-md'
            >
              <PencilSvg />
            </Link>
          )}
          <button
            onClick={() => setToggleModal(true)}
            className={`inline-flex items-center p-3 border border-transparent rounded-tr-md ${
              !props.editable && 'rounded-bl-md'
            } shadow-sm text-blue-tint bg-blue-lm
					hover:opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 no-outline `}
          >
            <CloseSvg />
          </button>
        </div>
        <div className='flex flex-row py-3'>
          <div className='flex flex-col h-100 flex-grow items-start md:space-x-5 md:flex-row md:h-32 md:items-center'>
            <Image className='w-20 h-20 rounded-md object-cover' src={props.imageUrl} alt={props.altText} />
            <div>
              <h3 className='mb-2 md:text-lg font-medium text-gray-800'>{props.title}</h3>
              <StatusTag approval={props.approval} />
              <div className='text-gray-800 truncate w-72'>
                {props.editable ? props.text.replace(/<[^>]*>?/gm, '') : decodeAndRemoveHTML(props.text)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogModal
        closeModal={() => setToggleModal(false)}
        open={toggleModal}
        description='Are you sure you want to remove this partner from your website?'
        title='Remove partner?'
        actionText='Remove partner'
        submitHandler={handleRemove}
      />
    </>
  );
};

export default PartnerCard;
