import Layout from 'components/Layout/Layout';

import BarLoader from 'react-spinners/BarLoader';

import NotificationMessage, { NotificationStyles } from 'components/Notification';
import PartnerDrawerItem from 'components/Partner/PartnerDrawerItem';
import PartnerCard from 'components/Partner/PartnerCard';
import ReorderableSelectedList from 'components/ReorderableSelectedList';
import { AddPartnerToBusiness } from 'commands/partners/AddPartnerToBusiness';
import { RemovePartnerFromBusiness } from 'commands/partners/RemovePartnerFromBusiness';
import { useParams } from 'react-router-dom';
import AddHeader from 'components/Layout/AddHeader';
import { useAvailablePartners, useSelectedPartners } from 'api/queries/usePartners';
import ErrorModal from 'components/Modals/ErrorModal';
import { useCommands } from 'hooks/useCommands';
import { SetOrder } from 'commands/partners/SetOrder';
import { useEffect } from 'react';

const PartnersScreen = () => {
  const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();
  const { businessName } = useParams<{ businessName: string }>();

  const {
    data: selectedPartnersData,
    isLoading: isSelectedPartnersLoading,
    isError: isSelectedPartnersisError,
    error: selectedPartnersError
  } = useSelectedPartners(businessName);

  const {
    data: availablePartnersData,
    isLoading: isAvailablePartnersLoading,
    isError: isAvailablePartnersError,
    error: availablePartnersError
  } = useAvailablePartners(businessName);

  useEffect(() => {
    if (showSuccess) {
      window.location.reload();
    }
  }, [showSuccess]);
  return (
    <>
      <Layout pageTitle='Your Partners' buttons={<AddHeader linkText='Create partner' linkUrl={`/${businessName}/partner-create`} />}>
        <ErrorModal
          open={isAvailablePartnersError || isSelectedPartnersisError || showError}
          error={availablePartnersError || selectedPartnersError}
          errorMessage={errorMessage}
        />
        <div className='mx-auto pb-5 w-9/12'>
          <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='Successful!' />

          <div className='relative max-w-7xl mx-auto'>
            <BarLoader height='4px' width='100%' loading={isAvailablePartnersLoading || isSelectedPartnersLoading} color='#00AAE5' />
            <ReorderableSelectedList
              removeCommand={item => new RemovePartnerFromBusiness(item.id)}
              addCommand={item => new AddPartnerToBusiness(item.id)}
              itemName='partner'
              commands={commands}
              selectedData={selectedPartnersData!}
              availableData={availablePartnersData!}
              reorderableCard={PartnerCard}
              reorderCommand={reorderedList => new SetOrder(reorderedList)}
              drawerComponent={PartnerDrawerItem}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PartnersScreen;
