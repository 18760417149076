import React, { useState } from 'react';
import { MemberEditableDetail } from 'models/member';
import { Command, CommandBatch } from 'commands/Commands';
import { FieldType } from 'models/Field';
import PencilSvg from 'components/Svg/PencilSvg';
import validator from 'validator';
import InputModal from 'components/Modals/InputModal';
import { truncateStr } from 'components/util/truncateStr';
import CheckSvg from 'components/Svg/CheckSvg';

type TeamMemberDetailsEditCardProps = {
  detail?: MemberEditableDetail;
  detailName: string;
  commands: CommandBatch;
  setDisableButton?: React.Dispatch<React.SetStateAction<boolean>>;
  editCommand: (newValue: string) => Command;
  allowEmpty: boolean;
};

const TeamMemberDetailsEditCard = ({ detail, commands, detailName, allowEmpty, editCommand, setDisableButton }: TeamMemberDetailsEditCardProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(detail?.value ?? '');
  const [error, setError] = useState(false);

  const handleCloseModal = () => {
    if (!detail?.value) {
      setIsEditing(false);
    } else {
      setIsEditing(false);
      setEditValue(detail.value);
    }
  };

  const handleSetModalText = () => {
    commands.add(editCommand(editValue));
    setIsEditing(false);
  };

  const handleChangeUrlText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setEditValue(value);
    if (validator.isURL(value, { allow_protocol_relative_urls: true }) || e.target.value.charAt(0) === '/' || e.target.value === '') {
      commands.add(editCommand(value));
      setError(false);
      if (setDisableButton) setDisableButton(false);
    } else {
      setError(true);
      if (setDisableButton) setDisableButton(true);
    }
  };

  let editInput;

  if (detail?.type === FieldType.Text) {
    editInput = <input className={'z-20 h-7 border-gray-500 border-2 p-1'} type='text' value={editValue} onChange={handleChangeUrlText} />;
  } else {
    allowEmpty
      ? (editInput = (
          <InputModal
            saveText={`Save ${detailName}`}
            title={`Edit ${detailName}`}
            description={`Edit team member ${detailName}`}
            open={isEditing}
            inputValue={editValue}
            textArea={true}
            setInputValue={setEditValue}
            sendHandler={handleSetModalText}
            onClose={handleCloseModal}
            noValidation={true}
          />
        ))
      : (editInput = (
          <InputModal
            saveText={`Save ${detailName}`}
            title={`Edit ${detailName}`}
            description={`Edit team member ${detailName}`}
            open={isEditing}
            inputValue={editValue}
            textArea={true}
            setInputValue={setEditValue}
            sendHandler={handleSetModalText}
            onClose={handleCloseModal}
          />
        ));
  }

  return (
    <div className={'relative rounded-lg  hover:shadow-md flex flex-row items-center space-x-3   hover:bg-gray-50 transition-all bg-white shadow-lg'}>
      <div className='flex items-center space-x-3 flex-1 px-6 py-5 '>
        <div className='flex-1 min-w-0'>
          <div className='focus:outline-none'>
            <span className=' inset-0' aria-hidden='true'></span>

            <p className={`text-md font-medium ${error ? 'text-red-500' : 'text-gray-700'}`}>{error ? 'url not valid' : detailName}</p>
            <div className='text-sm font-normal text-gray-900 font-sm z-20'>{isEditing ? editInput : truncateStr(editValue, 30)}</div>
          </div>
        </div>
      </div>
      <div className='flex flex-none text-gray-400 pr-6'>
        <div onClick={() => setIsEditing(!isEditing)} className='ml-3 focus:outline-none cursor-pointer  z-20'>
          {!isEditing ? <PencilSvg /> : <CheckSvg />}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberDetailsEditCard;
