import React, { useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { useBusinessMediaLibrary } from 'api/queries/useMediaLibrary';
import { useSearchState } from 'hooks/useSearchState';
import Layout from 'components/Layout/Layout';
import AddMediaDrawer from 'components/Media/AddMediaDrawer';
import PlusSvg from 'components/Svg/PlusSvg';
import SearchInput from 'components/SearchInput';
import MediaListItem from 'components/Media/MediaListItem';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import ErrorModal from 'components/Modals/ErrorModal';
import EditMediaDrawer from 'components/Media/EditMediaDrawer';
import { IMedia } from 'models/media';
import ApprovedMediaDrawer from 'components/Media/ApprovedMediaDrawer';
import MediaDrawer from 'components/Media/MediaDrawer';

const MediaScreen = () => {
  const location = useLocation<{ id?: string | number; open?: boolean }>();
  const { businessName } = useParams<{ businessName: string }>();
  useBusinessAuth(businessName);
  const [addMediaToggle, setAddMediaToggle] = useState<boolean>(false);
  const [selectApprovedMediaToggle, setSelectApprovedMediaToggle] = useState<boolean>(false);
  const [selectMediaToggle, setSelectMediaToggle] = useState<boolean>(location?.state?.open ?? false);
  const [selectAllMediaToggle, setSelectAllMediaToggle] = useState<boolean>(false);
  const [approvalFilter, setApprovalFiler] = useState<'All' | 'Approved' | 'Awaiting' | 'Rejected'>('All');
  const { data: businessMediaData, isError, error } = useBusinessMediaLibrary(businessName);
  const [selectedMedia, setSelectedMedia] = useState<IMedia | null>(
    businessMediaData?.find(mediaItem => mediaItem.id === location?.state?.id && (mediaItem.approval.rejected || mediaItem.approval.requested)) ??
      null
  );
  const {
    handleSearch: handleBusinessMediaSearch,
    handleSearchSubmit: handleBusinessMediaSearchSubmit,
    searchResults: businessMediaResults,
    searchValue: businessMediaValue
  } = useSearchState(businessMediaData => businessMediaData.name, businessMediaData!);

  const handleSelectMedia = (id: string) => {
    let mediaItem = businessMediaData?.find(mediaItem => mediaItem.id === id) ?? null;
    if (mediaItem && (mediaItem.approval.rejected || mediaItem.approval.requested)) {
      setSelectedMedia(mediaItem);
      setSelectMediaToggle(true);
    } else if (mediaItem && mediaItem.approval.approved) {
      setSelectedMedia(mediaItem);
      setSelectApprovedMediaToggle(true);
    } else if (mediaItem && mediaItem.approval.approved === false && mediaItem.approval.rejected === false) {
      setSelectedMedia(mediaItem);
      setSelectAllMediaToggle(true);
    }
  };

  return (
    <>
      <Layout
        pageTitle={'Your Media'}
        buttons={
          <button className='bg-blue-lm p-3 rounded-md text-white flex items-center' onClick={() => setAddMediaToggle(true)}>
            Add Media <PlusSvg className='ml-1 w-5 h-5' />
          </button>
        }
      >
        <ErrorModal open={isError} error={error} />
        <div className=''>
          <SearchInput
            searchValue={businessMediaValue}
            handleSearch={handleBusinessMediaSearch}
            handleSearchSubmit={handleBusinessMediaSearchSubmit}
            placeholder='Search your media...'
          />
          <div className='bg-white shadow overflow-hidden sm:rounded-md max-w-7xl mx-auto'>
            <div className=' border-gray-200 px-4 py-4 border-b text-gray-500 font-medium flex-grow focus:outline-none'>
              <nav>
                <button
                  className={`${approvalFilter === 'All' ? 'bg-blue-lm text-white' : 'hover:bg-gray-100'}  py-1 px-2 mr-3 rounded transition-all`}
                  onClick={() => setApprovalFiler('All')}
                >
                  All Media
                </button>
                <button
                  className={`${
                    approvalFilter === 'Approved' ? 'text-green-700 bg-green-100' : 'hover:bg-gray-100'
                  } py-1 px-2 mr-3 rounded transition-all`}
                  onClick={() => setApprovalFiler('Approved')}
                >
                  Approved
                </button>
                <button
                  className={`${
                    approvalFilter === 'Awaiting' ? 'text-yellow-700 bg-yellow-100' : 'hover:bg-gray-100'
                  } py-1 px-2 mr-3 rounded transition-all`}
                  onClick={() => setApprovalFiler('Awaiting')}
                >
                  Awaiting Approval
                </button>
                <button
                  className={`${
                    approvalFilter === 'Rejected' ? 'text-red-700 bg-red-100' : 'hover:bg-gray-100'
                  } py-1 px-2 mr-3 rounded transition-all`}
                  onClick={() => setApprovalFiler('Rejected')}
                >
                  Rejected
                </button>
              </nav>
            </div>
            <ul className='divide-y divide-gray-200'>
              {businessMediaResults
                ? businessMediaResults
                    ?.filter(item => {
                      if (approvalFilter === 'Approved') {
                        return item.approval.approved === true;
                      } else if (approvalFilter === 'Rejected') {
                        return item.approval.rejected === true;
                      } else if (approvalFilter === 'Awaiting') {
                        return item.approval.requested === true && !item.approval.rejected;
                      } else {
                        return item;
                      }
                    })
                    .map(item => <MediaListItem isChild={false} item={item} key={item.id} handleSelectedMedia={() => handleSelectMedia(item.id)} />)
                : null}
            </ul>
          </div>
        </div>
      </Layout>
      {addMediaToggle && <AddMediaDrawer toggleHandler={() => setAddMediaToggle(false)} />}
      {selectMediaToggle && <EditMediaDrawer toggleHandler={() => setSelectMediaToggle(false)} selectedMedia={selectedMedia} />}
      {selectApprovedMediaToggle && <ApprovedMediaDrawer toggleHandler={() => setSelectApprovedMediaToggle(false)} selectedMedia={selectedMedia} />}
      {selectAllMediaToggle && <MediaDrawer toggleHandler={() => setSelectAllMediaToggle(false)} selectedMedia={selectedMedia} />}
    </>
  );
};

export default MediaScreen;
