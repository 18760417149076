import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import { CommandBatch } from 'commands/Commands';
import React, { useState } from 'react';
import { useParams } from 'react-router';

export const useCommands = () => {
  const { businessName } = useParams<{ businessName: string }>();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data: businessAuthData } = useBusinessAuth(businessName);

  const commands = React.useMemo(
    () => new CommandBatch(businessAuthData?.id ?? '', setErrorMessage, setShowError, setShowSuccess),
    [businessAuthData, setErrorMessage, setShowError, setShowSuccess]
  );

  return { commands, showError, showSuccess, errorMessage, setShowError, setShowSuccess, setErrorMessage };
};
