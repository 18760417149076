import { v4 as newGuid } from 'uuid';
import { Command } from './Commands';

export class AddPage extends Command {
  type: string = 'Page.Add';

  // i generate guid here
  constructor(name: string, slug: string, public template: string, public container: string) {
    super({ name, slug } as AddPagePayload, newGuid());
    this.slug = slug;
  }
  readonly slug: string;

  priority: number = 50;
}

export class AddPagePayload {
  name: string;
  slug: string;
}