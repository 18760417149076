import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { ArticleDetail } from 'models/blog';
import { useQuery } from 'react-query';

// Get a blog article
export const useArticle = (
  slug: string,
  addArticle: boolean,
  businessName: string
) => {
  return useQuery<ArticleDetail, Error>(
    'article',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get<ArticleDetail>(
        `${process.env.REACT_APP_API}/Portal/Blog/${slug}`,
        generateGetHeaders(userDetails, businessName)
      );
      return data;
    },
    { refetchOnWindowFocus: false, enabled: !addArticle }
  );
};
