import React from 'react';
import { SvgProps } from 'models/svg';

const FolderAddSvg = ({ className }: SvgProps) => {
  return (
    <svg
      className={`w-6 h-6 ${className || ''}`}
      fill='none'
      stroke='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
      />
    </svg>
  );
};

export default FolderAddSvg;
