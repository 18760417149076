import React, { useState, useContext } from 'react';

import MediaPickerDrawer from 'components/Media/MediaPickerDrawer';
import { EditContext } from 'context/EditContext';

import MediaSvg from 'components/Svg/MediaSvg';
import { CommandBatch, ICommand } from 'commands/Commands';
import { ChangeMedia } from 'commands/business/ChangeMedia';
import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';
import { IMedia } from 'models/media';
import TrashSvg from 'components/Svg/TrashSvg';
import { RemoveBusinessFile } from 'commands/business/RemoveBusinessFile';
import DialogModal from 'components/Modals/DialogModal';

type Props<TCommand extends ICommand> = {
  initialVal: string;
  commands: CommandBatch;
  label: string;
  showControls?: boolean;
  commandFactory: (payload: string) => TCommand;
};

const BusinessFileUploadPicker = ({ initialVal, commands, label, showControls = true, commandFactory }: Props<ChangeMedia>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialVal ?? '');
  const { setDirty } = useContext(EditContext);
  const handleSelectedValue = (Media: IMedia) => {
    setValue(Media.url);
    commands.add(commandFactory(Media.url));
    setToggle(false);
    setDirty(true);
  };

  const handleShowWarning = () => {
    setShowDeleteWarning(true);
  };

  function clearCache() {
    window.location.reload();
  }

  const handleBusinessFileUpload = () => {
    var value = label;
    commands.add(new RemoveBusinessFile(value)).send();
    setShowDeleteWarning(false);
    setTimeout(clearCache, 500);
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center items-center'>
        <h3 className='block text-lg text-gray-700 p-1 '>{label}</h3>
        <div className='flex items-center justify-center'>
          {value ? (
            <>
              <p className='text-sm mr-2'>{value}</p>
              <MediaSvg size={10} colour={value !== '' ? 'text-blue-lm' : 'text-gray-500'} />
            </>
          ) : (
            <p className='text-gray-600'>No File Selected</p>
          )}
          {isEditing ? (
            <>
              {initialVal ? (
                <a
                  className={'cursor-pointer border border-transparent rounded-md bg-red-500 hover:bg-red-600 text-white'}
                  onClick={handleShowWarning}
                >
                  <TrashSvg className='w-10 h-8' />
                </a>
              ) : null}
              <button
                className='items-center px-4 py-2  my-2  mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
                onClick={() => setToggle(true)}
              >
                Select a business file
              </button>
            </>
          ) : null}
          {showControls && <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} />}
        </div>
      </div>
      {toggle && (
        <MediaPickerDrawer
          toggleHandler={() => setToggle(false)}
          onUmbracoMediaSelected={handleSelectedValue}
          onLocalMediaSelected={handleSelectedValue}
        />
      )}
      {showDeleteWarning ? (
        <DialogModal
          title='Remove file'
          description='Are you sure you want to remove the uploaded file?'
          actionText='Delete'
          open={showDeleteWarning}
          closeModal={() => {}}
          submitHandler={handleBusinessFileUpload}
          isRed={true}
        />
      ) : null}
    </>
  );
};

export default BusinessFileUploadPicker;
