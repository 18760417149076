import { Command } from 'commands/Commands';

export class RequestApproval extends Command {
  type: string = 'BlogArticle.RequestApproval';
  priority: number = 150;
}

export class Approve extends Command {
  type: string = 'BlogArticle.Approve';
  priority: number = 150;
}

export class Reject extends Command {
  type: string = 'BlogArticle.Reject';
  priority: number = 150;
}
