import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { generateGetHeaders } from 'api/generateGetHeaders';
import { useQuery } from 'react-query';
import { Activity } from 'models/approval';

export const useActivityFeed = (businessName: string) => {
  return useQuery<Activity[], Error>(
    'activityFeed',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Businesses/Activity`, generateGetHeaders(userDetails, businessName));
      return data;
    },
    { refetchOnWindowFocus: true }
  );
};
