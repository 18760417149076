import React from 'react';
import { Transition } from '@headlessui/react';
import { Link, useParams } from 'react-router-dom';
import StarSvg from 'components/Svg/StarSvg';
import StatusTag from 'components/StatusTag';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import { BlogArticle } from 'models/blog';
import Image from 'components/Image';
import { previewUrlGenerator } from 'components/util/previewUrlGenerator';

type BlogCardProps = {
  article: BlogArticle;
  isFeatured: boolean;
  handleFeaturedArticle: (id: string) => void;
};

const BlogCard = ({ handleFeaturedArticle, isFeatured, article }: BlogCardProps) => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data: businessAuth } = useBusinessAuth(businessName);

  return (
    <div className='flex flex-col rounded-lg shadow-xl overflow-hidden relative'>
      <div className='flex-shrink-0'>
        <Image className='h-48 w-full object-cover' src={article.imageUrl} alt='' />
      </div>
      <div className='flex-1 bg-white p-6 flex flex-col justify-between'>
        <div className='flex-1'>
          <div className='absolute top-0 right-0 w-full cursor-pointer ' onClick={() => handleFeaturedArticle(article.id)}>
            <Transition
              show={isFeatured}
              enter='transition ease-in-out duration-200 transform'
              enterFrom='translate-x-full opacity-0'
              enterTo='translate-x-0 opacity-100'
              leave='transition ease-in-out duration-200 transform'
              leaveFrom='translate-x-0 opacity-100'
              leaveTo='translate-x-full opacity-0'
              className='absolute top-0 right-0'
            >
              <div className='text-white flex px-6 py-3 bg-blue-lm  rounded-tr-md rounded-bl-md content-center '>
                <StarSvg />
                <p className='leading-tight'>Featured</p>
              </div>
            </Transition>
            <Transition
              show={!isFeatured}
              enter='transition ease-in-out duration-200 transform'
              enterFrom='translate-x-full opacity-0'
              enterTo='translate-x-0 opacity-100'
              leave='transition ease-in-out duration-200 transform'
              leaveFrom='translate-x-0 opacity-100'
              leaveTo='translate-x-full opacity-0'
              className='absolute top-0 right-0'
            >
              <div className='text-white flex px-6 py-3 bg-gray-600  rounded-tr-md rounded-bl-md content-center opacity-50 hover:bg-blue-lm transition'>
                <StarSvg />
                <p className='leading-tight'>Featured</p>
              </div>
            </Transition>
          </div>
          <StatusTag approval={article.approval} />

          <p className='text-xl text-gray-900 mb-3'>{article.title}</p>
        </div>
        {/* {status === 'rejected' && <Message content={message} />} */}

        {article.local ? (
          <span className=' w-full bg-white relative z-0 flex flex-row rounded-md justify-items-strech divide-x-2'>
            <Link
              to={`/${businessName}/blog-edit/${article.slug}`}
              className='relative flex-grow  flex flex-row justify-items-center justify-center px-4 py-2 rounded-tl-md bg-blue-tint text-sm font-medium text-blue-lm hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-center'
            >
              <p className=' flex-grow text-center'>Edit</p>
            </Link>
            <a
              href={previewUrlGenerator(
                businessAuth!.public,
                businessAuth!.byob,
                businessAuth!.slug,
                businessName,
                `blog/${article.slug}`,
                businessAuth!.previewUrl
              )}
              rel='noreferrer'
              target='_blank'
              className='relative flex-grow  flex flex-row justify-items-center justify-center px-4 py-2 rounded-tr-md bg-blue-tint text-sm font-medium text-blue-lm hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-center'
            >
              <p className=' flex-grow text-center'>Preview</p>
            </a>
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default BlogCard;
