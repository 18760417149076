import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CommandBatch } from 'commands/Commands';
import { AddPage, AddPagePayload } from 'commands/AddPage';
import { AddField } from 'commands/AddField';
import { AddProperty } from 'commands/AddProperty';
import { Page } from 'models/page';
import Layout from 'components/Layout/Layout';
import GenerateTemplate from 'components/AddPage/GenerateTemplate';
import { usePage } from 'api/queries/usePage';
import Block from 'components/Inputs/Block';
import { convertCamelCaseToTitle } from 'components/util/convertCamelCaseToTitle';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import ApprovalButtons from 'components/Buttons/ApprovalButtons';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import { Approve, Reject, RequestApproval } from 'commands/Approval';
import { useBusinessDetails } from 'api/queries/useBusinessDetails';
import SaveButton from 'components/Buttons/SaveButton';
import ApproveButton from 'components/Buttons/ApproveButton';
import PublishWithoutApprovalButton from 'components/Buttons/PublishWithoutApprovalButton';
import { userInfo } from 'os';
import { UserContext } from 'context/UserContext';
import { generateGetHeaders } from 'api/generateGetHeaders';
import axios from 'axios';
import { getUserDetails } from 'api/getUserDetails';

const AddPageScreen = () => {
  const [addPageCommand, setAddPageCommand] = useState<AddPage>();
  const [templateData, setTemplateData] = useState<Page>();
  const history = useHistory();
  const location = useLocation<{ id: string }>();
  const [blockState, setBlockState] = useState<{ blockId: string; isValid: boolean }[]>([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { businessName } = useParams<{ slug: string; businessName: string }>();
  const { data: businessAuthData } = useBusinessAuth(businessName);

  const { data: businessData } = useBusinessDetails(businessName);
  const isByob = businessData?.details?.byob;

  const { user } = useContext(UserContext);

  const { data, isLoading, error, isError } = usePage(businessName, templateData?.slug ?? '', !!templateData);

  const handleSavePage = async () => {
    const isSendSuccess = await commands.send();
    if (isSendSuccess) {
      history.push(`/${businessName}/pages`);
      window.location.reload();
    } else {
      setErrorMessage('Approval failed');
      setShowError(true);
    }
  };

  const handlePublishPage = async () => {
    const isSuccess = await commands.send();
    if (isSuccess && addPageCommand?.slug) {
      const userDetails = await getUserDetails();
      const { data: publishedPageData } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Pages/${addPageCommand?.slug}`,
        generateGetHeaders(userDetails, businessName)
      );
      const commands = new CommandBatch(businessAuthData?.id ?? '', setErrorMessage, setShowError, setShowSuccess);
      const isApprovalSuccess = await commands.add(new RequestApproval('page requires approval', publishedPageData?.id)).send();
      if (isApprovalSuccess) {
        history.push(`/${businessName}/pages`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setErrorMessage('Request Approval failed');
        setShowError(true);
      }
    }
  };

  const handlePublishWithoutApprovalPage = async () => {
    const isSuccess = await commands.send();
    if (isSuccess && addPageCommand?.slug) {
      const userDetails = await getUserDetails();
      const { data: publishedPageData } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Pages/${addPageCommand?.slug}`,
        generateGetHeaders(userDetails, businessName)
      );
      const commands = new CommandBatch(businessAuthData?.id ?? '', setErrorMessage, setShowError, setShowSuccess);
      const isApprovalSuccess = await commands.add(new Approve('approved and published', publishedPageData?.id)).send();

      if (isApprovalSuccess) {
        history.push(`/${businessName}/pages`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setErrorMessage('Approval failed');
        setShowError(true);
      }
    }
  };

  const commands = useMemo(() => {
    const commands = new CommandBatch(businessAuthData?.id ?? '', setErrorMessage, setShowError, setShowSuccess);
    if (templateData && addPageCommand) {
      const pageSlug = addPageCommand.slug;

      commands.add(addPageCommand, null, c => c.type === 'AddPage' && ((c as AddPage).payload as AddPagePayload).slug === pageSlug);

      data?.blocks.flatMap(block =>
        block.fields.forEach(field => {
          const command = new AddField(pageSlug, field.type, field.name, field.displayName, block.buId, block.alias);
          field.id = command.id;
          commands.add(command);
        })
      );

      data?.properties.forEach(property => {
        const command = new AddProperty(pageSlug, property.type, property.name, property.displayName);
        property.id = command.id;
        commands.add(command);
      });
    }
    return commands;
  }, [templateData, businessAuthData, addPageCommand, data]);

  return (
    <Layout
      showGoBackLink={true}
      pageTitle={'Create Page'}
      buttons={
        !addPageCommand ? null : (
          <>
            <SaveButton save={handleSavePage} />
            {user.corporateUser ? (
              <PublishWithoutApprovalButton addCommand={handlePublishWithoutApprovalPage} text={'Publish and Approve'} />
            ) : (
              <PublishWithoutApprovalButton addCommand={handlePublishPage} text={'Publish'} />
            )}
          </>
        )
      }
    >
      <NotificationMessage type={NotificationStyles.Danger} show={showError} setShow={setShowError} message={errorMessage!} />

      <div className='absolute inset-0  sm:px-6 lg:px-8'>
        <div className='h-full py-8'>
          <div className=' pb-16'>
            {!addPageCommand ? (
              <GenerateTemplate
                containerId={location.state.id}
                setAddPageCommand={setAddPageCommand}
                addPageCommand={addPageCommand}
                templateData={templateData}
                setTemplateData={setTemplateData}
              />
            ) : isLoading ? (
              <p>Loading...</p>
            ) : isError ? (
              <p>{error!.message}</p>
            ) : (
              <>
                <div>
                  {data?.properties && (
                    <Block
                      buId={data!.id}
                      blockState={blockState}
                      setBlockState={setBlockState}
                      alias='Page Properties'
                      fields={data!.properties}
                      commands={commands}
                    />
                  )}
                </div>
                <div>
                  {data?.blocks?.map(block =>
                    isByob ? (
                      block?.hideForBYOB ? null : (
                        <Block
                          buId={block.buId}
                          blockState={blockState}
                          setBlockState={setBlockState}
                          alias={convertCamelCaseToTitle(block.alias)}
                          fields={block.fields}
                          commands={commands}
                          key={block.buId}
                        />
                      )
                    ) : block?.hideForLM ? null : (
                      <Block
                        buId={block.buId}
                        blockState={blockState}
                        setBlockState={setBlockState}
                        alias={convertCamelCaseToTitle(block.alias)}
                        fields={block.fields}
                        commands={commands}
                        key={block.buId}
                      />
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddPageScreen;
