import { Transition } from '@headlessui/react';
import { useEffect } from 'react';
import WarningSvg from './Svg/WarningSvg';

export enum NotificationStyles {
  Danger = 'danger',
  Alert = 'alert',
  Happy = 'happy',
}

type Props = {
  drawer?: boolean;
  message: string;
  show: boolean;
  type: NotificationStyles;
  setShow: (state: boolean) => void;
};

const NotificationMessage = ({
  message,
  type,
  show,
  drawer,
  setShow,
}: Props) => {
  useEffect(() => {
    let timer = setTimeout(() => {
      show && setShow(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [show, setShow]);

  return (
    <div className='relative flex mx-auto justify-center'>
      <Transition
        show={show}
        as={'div'}
        enter='transition ease-in-out duration-200 transform'
        enterFrom='-translate-y-full opacity-0'
        enterTo=' translate-y-0 opacity-100'
        leave='transition ease-in-out duration-200 transform'
        leaveFrom='translate-y-0 opacity-100'
        leaveTo='-translate-y-full opacity-0'
        className={`mb-5 z-50 bottom-10 h-10  md:top-28 fixed ${
          drawer ? 'w-56' : 'w-full'
        }`}
      >
        <div
          className={`${
            type === NotificationStyles.Danger
              ? 'text-red-500 bg-red-50'
              : type === NotificationStyles.Happy
              ? 'text-green-500 bg-green-50'
              : 'text-gray-500 bg-gray-50'
          }   p-3 rounded-md flex shadow bg-opacity-90 max-w-7xl mx-auto `}
        >
          <WarningSvg />
          <p>{message}</p>
        </div>
      </Transition>
    </div>
  );
};

export default NotificationMessage;
