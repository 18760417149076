import { SvgProps } from 'models/svg';
import React from 'react';

const ChildItemSvg = ({ className }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className={`h-6 w-6 rotate-45 ${className || ''}`} fill='none' viewBox='0 0 24 24' stroke='currentColor'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 9l-7 7-7-7'></path>
    </svg>
  );
};

export default ChildItemSvg;
