import { Command } from 'commands/Commands';

export class ChangeMediaFile extends Command {
  type: string = 'Media.ChangeFile';
  constructor(
    public id: string,
    newValue: {
      name: string;
      url: string;
      bytes: string;
      extension: string;
    }
  ) {
    super(newValue, id);
  }
  priority: number = 100;
}
