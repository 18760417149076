import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { EditContext } from 'context/EditContext';
import { useEffect } from 'react';

type DateInputProps = {
  handleFieldValue: (value: string, id?: string) => void;
  value?: string;
};

const DateInput = ({ value, handleFieldValue }: DateInputProps) => {
  const [publishDate, setPublishDate] = useState(value ? new Date(value) : new Date());
  const { setDirty } = useContext(EditContext);

  useEffect(() => {
    setPublishDate(value ? new Date(value) : new Date());
  }, [value]);

  const changeHandler = (date: Date): void => {
    if (date !== null) {
      const dateString = date.toJSON();
      setPublishDate(date);
      handleFieldValue(dateString);
      setDirty(true);
    }
  };

  return (
    <div className='z-40 relative'>
      <label htmlFor='date-picker' className='block text-md font-medium text-gray-700 my-3'>
        Post Date
      </label>
      <DatePicker
        id='date-picker'
        selected={publishDate}
        onChange={changeHandler}
        className='focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 rounded-md sm:text-sm p-3'
      />
    </div>
  );
};

export default DateInput;
