import { ChangeSlug } from 'commands/business/ChangeSlug';
import { CommandBatch } from 'commands/Commands';
import { UserContext } from 'context/UserContext';
import { BusinessDetails } from 'models/business';
import { useContext } from 'react';

import BusinessBoard from './BusinessBoard';
import SlugChangeModal from './Inputs/SlugChangeModal';
type Props = {
  business: BusinessDetails;
  commands: CommandBatch;
  setNewSlug: (slug: string) => void;
};
const MyCrmInfo = ({ business, commands, setNewSlug }: Props) => {
  const { user } = useContext(UserContext);

  const corporateUser = user.corporateUser;
  return (
    <BusinessBoard heading='My CRM information'>
      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>Business display name: </h3>
        <span className='font-normal'>{business.details?.displayName}</span>
      </div>
      <hr />
      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>Office email: </h3>
        <span className='font-normal'>{business.details?.officeEmail}</span>
      </div>
      <hr />

      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>My CRM ID: </h3>
        <span className='font-normal'>{business.details?.myCrmId}</span>
      </div>
      <hr />
      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>Office phone number: </h3>
        <span className='font-normal'>{business.details?.phoneNumber}</span>
      </div>
      <hr />
      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>Address: </h3>
        <span className='font-normal'>{business.details?.address}</span>
      </div>
      <hr />
      <SlugChangeModal
        label='Slug'
        initialVal={business.details.slug}
        commands={commands}
        setNewSlug={setNewSlug}
        commandFactory={value => new ChangeSlug(value)}
        showControls={corporateUser}
      />
    </BusinessBoard>
  );
};

export default MyCrmInfo;
