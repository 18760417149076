import React, { useState } from 'react';
import validator from 'validator';
type UrlInputProps = {
  id: string;
  value: string;
  label: string;
  urlType?: 'absolute' | 'relative';
  handleFieldValue: (value: string, id?: string) => void;
};

const UrlInput = ({ id, label, value, handleFieldValue, urlType = 'relative' }: UrlInputProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState(false);

  const validatorConfig = urlType === 'absolute' ? { require_protocol: true } : { allow_protocol_relative_urls: true };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.trim();
    setInputValue(value);
    if (urlType === 'absolute' && validator.isURL(value, validatorConfig)) {
      setError(false);
    } else if (urlType === 'relative' && value.charAt(0) === '/') {
      handleFieldValue(value, id);
      setError(false);
    } else {
      handleFieldValue(value, id);
      setError(true);
    }
  };

  return (
    <div className='space-y-3 w-full pt-5'>
      <label htmlFor={id} className={`block text-md font-medium ${error ? 'text-red-500' : 'text-gray-700'}`}>
        {error ? 'Must be a valid link' : label}
      </label>
      <input
        id={id}
        name='textField'
        type='text'
        placeholder={urlType === 'absolute' ? 'https://example.com/' : '/example'}
        value={inputValue}
        className={`flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full ${
          error ? 'bg-red-00' : 'border-gray-200'
        } border-2 rounded-md sm:text-sm p-3`}
        onChange={changeHandler}
      />
    </div>
  );
};

export default UrlInput;
