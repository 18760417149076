import { AddPartner } from 'commands/partners/AddPartner';
//we're leaving the tinymce react code (@tinymce/tinymce-react: ^3.12.2) in place so we can easily swap back to it if the client changes their mind...
//import { Editor } from '@tinymce/tinymce-react';
import RichTextEditorInput from 'components/Inputs/RichTextEditorInput';
import { v4 as newGuid } from 'uuid';
import Layout from 'components/Layout/Layout';
import MediaPickerDrawer from 'components/Media/MediaPickerDrawer';
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import { EditContext } from 'context/EditContext';

import React, { useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';

import { useUrlValidator } from 'hooks/useUrlValidator';
import SaveButton from 'components/Buttons/SaveButton';
import { useTimedGoBack } from 'hooks/useTimedGoBack';
import Image from 'components/Image';
import ErrorModal from 'components/Modals/ErrorModal';
import { IMedia } from 'models/media';
import { UserContext } from 'context/UserContext';
import ApproveButton from 'components/Buttons/ApproveButton';
import { Approve } from 'commands/partners/PartnerApproval';
import AltTextHelp from 'components/AltTextHelp';
import { useCommands } from 'hooks/useCommands';

const CreatePartnerScreen = () => {
  const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();

  const { invalidUrl, setUrlValidity } = useUrlValidator('absolute');

  useTimedGoBack(showSuccess);
  const newPartnerId = useMemo(() => newGuid(), []);
  const [newPartner, setNewPartner] = useState({
    id: newPartnerId,
    title: '',
    imageUrl: '',
    altText: '',
    text: '',
    linkUrl: '',
    linkText: ''
  });

  const [toggle, setToggle] = useState<boolean>(false);

  const { setDirty } = useContext(EditContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    commands.add(new AddPartner(newPartner, newPartner.id));
  }, [newPartner, commands]);

  const handleSelectedMedia = (media: IMedia) => {
    setNewPartner({ ...newPartner, imageUrl: media.url });
    setToggle(false);
    setDirty(true);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === 'linkUrl') {
      setUrlValidity(e.currentTarget.value);
      setNewPartner({
        ...newPartner,
        linkUrl: e.currentTarget.value
      });
    } else {
      setNewPartner({
        ...newPartner,
        [e.currentTarget.name]: e.currentTarget.value
      });
      setDirty(true);
    }
  };

  const handleRichTextEditorChange = (content: string) => {
    setNewPartner((prevState) => ({ ...prevState, text: content }));
    setDirty(true);
  };

  const checkValidity = () => {
    return Object.values(newPartner).every(value => !!value) && !invalidUrl; // Check if all values are not empty
  };

  return (
    <>
      <Layout
        showGoBackLink={true}
        pageTitle={'Create Partner'}
        buttons={
          user?.corporateUser ? (
            <ApproveButton enable={checkValidity()} addApproveCommand={message => commands.add(new Approve(message, newPartnerId)).send()} />
          ) : (
            <SaveButton save={() => commands.send()} text='Request approval' enable={checkValidity()} />
          )
        }
      >
        <div className='pb-5'>
          <ErrorModal open={showError} error={null} errorMessage={errorMessage} />
          <NotificationMessage type={NotificationStyles.Happy} show={!showError && showSuccess} setShow={setShowSuccess} message='Successful!' />

          <label>
            <p className='block text-md font-medium text-gray-700  my-3'>Partner Logo</p>
            {newPartner.imageUrl && <Image src={newPartner.imageUrl} style={{ width: '200px', height: 'auto' }} alt='partner' />}
            <button
              onClick={() => setToggle(true)}
              className='inline-flex
     my-2  items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white  bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Add partner logo
            </button>
          </label>
          <label>
            <p className='block text-md font-medium text-gray-700  my-3'>Partner name</p>
            <input
              type='text'
              name='title'
              value={newPartner.title}
              className='flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 rounded-md sm:text-sm p-3'
              onChange={handleOnChange}
            />
          </label>

          <label>
            <p className='block text-md font-medium text-gray-700 my-3'>Text</p>
            {/* <Editor
              initialValue=''
              apiKey='hczx677xadq980gygi6p3jevxzifjjy2ee3e6d6yn3tyxuvt'
              init={{
                height: 500,
                menubar: false,
                block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic | bullist numlist | removeformat | help | removeh1'
              }}
              onEditorChange={handleRichTextEditorChange}
            /> */}

            <RichTextEditorInput handleFieldValue={handleRichTextEditorChange} id='ChangeBody' value='' label='Partner Description' />
          </label>

          <label>
            <p className='block text-md font-medium text-gray-700  my-3'>
              Image alt text: <AltTextHelp />
            </p>
            <input
              name='altText'
              type='text'
              value={newPartner.altText}
              className='flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 rounded-md sm:text-sm p-3'
              onChange={handleOnChange}
            />
          </label>
          <label>
            <p className='block text-md font-medium text-gray-700  my-3'>
              Call-to-action text: <span className='text-sm font-normal text-gray-600'>( e.g. Learn more )</span>
            </p>
            <input
              name='linkText'
              type='text'
              value={newPartner.linkText}
              className='flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 rounded-md sm:text-sm p-3'
              onChange={handleOnChange}
            />
          </label>
          <label>
            <p className={`block text-md font-medium my-3 ${invalidUrl ? 'text-red-500' : 'text-gray-700'}`}>
              {invalidUrl ? (
                'Must be a valid absolute url'
              ) : (
                <>
                  Link Url:
                  <span className='text-sm font-normal text-gray-600'>( e.g. partner website )</span>
                </>
              )}
            </p>
            <input
              type='text'
              name='linkUrl'
              value={newPartner.linkUrl}
              className={`flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 rounded-md sm:text-sm p-3 `}
              onChange={handleOnChange}
            />
          </label>
        </div>
      </Layout>
      {toggle && (
        <MediaPickerDrawer
          toggleHandler={() => setToggle(false)}
          onLocalMediaSelected={handleSelectedMedia}
          onUmbracoMediaSelected={handleSelectedMedia}
        />
      )}
    </>
  );
};

export default CreatePartnerScreen;
