import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'images/site-logo.svg';
import Image from 'components/Image';
type MobileHeaderTypes = {
  toggleHandler: () => void;
};
const MobileHeader = (props: MobileHeaderTypes) => {
  return (
    <div className='lg:hidden'>
      <div className='flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5'>
        <div>
          <Link to='/'>
            <Image className='h-12 w-auto' src={logo} alt='Workflow' />
          </Link>
        </div>
        <div>
          <button
            onClick={props.toggleHandler}
            type='button'
            className='-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900'
          >
            <span className='sr-only'>Open sidebar</span>
            {/* <!-- Heroicon name: menu --> */}
            <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16' />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
