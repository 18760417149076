import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import TemplateCard from './TemplateCard';
import { Page } from 'models/page';

import { AddPage } from 'commands/AddPage';
import { useTemplates } from 'api/queries/useTemplates';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  containerId: string;
  addPageCommand: AddPage | undefined;
  templateData: Page | undefined;
  setAddPageCommand: React.Dispatch<React.SetStateAction<AddPage | undefined>>;
  setTemplateData: React.Dispatch<React.SetStateAction<Page | undefined>>;
};

const GenerateTemplate = ({ containerId, templateData, setAddPageCommand, setTemplateData }: Props) => {
  const [pageNameValue, setPageNameValue] = useState<string>('');
  const { slug, businessName } = useParams<{ slug: string; businessName: string }>();
  const { data, isLoading } = useTemplates(businessName);
  const [showTemplates, setShowTemplates] = useState<boolean>(false);
  const [isSlugTaken, setIsSlugTaken] = useState<boolean>(false);
  const oktaAuth = useOktaAuth();

  const fullPageSlug = () =>
    `${slug}/${pageNameValue
      .trimStart()
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')}`;

  const selectHandler = (template: Page) => {
    if (template) {
      setTemplateData(template);
    }
  };

  const handleNext = () => {
    if (templateData) setAddPageCommand(new AddPage(pageNameValue.trimStart().toLowerCase(), fullPageSlug(), templateData!.slug, containerId));
  };

  const nameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageNameValue(e.target.value.trimStart());
    if (isSlugTaken) setIsSlugTaken(false);
  };

  const nameSelectedHandler = async () => {
    const isTaken = await axios.get(`${process.env.REACT_APP_API}/Portal/Pages/CheckSlug/${fullPageSlug()}`, {
      headers: {
        Authorization: `Bearer ${oktaAuth.authState.idToken?.idToken}`,
        business: businessName
      }
    });
    if (!isTaken.data) setShowTemplates(true);
    else {
      setIsSlugTaken(true);
    }
  };

  return (
    // this will need to fetch data
    <>
      <div className='space-y-6'>
        <h2 className='text-xl font-medium text-gray-700'>
          Slug: <span className=''>{fullPageSlug()}</span>
        </h2>
        {showTemplates ? (
          <>
            <div className='rounded-md bg-white border-2  border-gray-200 p-6 w-11/12'>
              <h3 className='text-lg font-medium pb-3 mb-6  border-b-2 border-gray-200'>Select a page template</h3>
              <div>
                {isLoading ? (
                  <p>loading..</p>
                ) : (
                  data!.map(template => {
                    return (
                      <TemplateCard
                        key={template.id}
                        template={template}
                        isSelected={templateData?.id === template?.id}
                        selectHandler={selectHandler}
                      />
                    );
                  })
                )}
              </div>
            </div>
            <button
              className='disabled:opacity-50 inline-flex items-center px-4 py-2 my-2 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
              onClick={() => setShowTemplates(false)}
            >
              Back
            </button>
            <button
              disabled={!templateData}
              className='disabled:opacity-50 inline-flex items-center px-4 py-2 my-2 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
              onClick={handleNext}
            >
              Next
            </button>
          </>
        ) : (
          <div className='mt-12'>
            <label className='' htmlFor='slugInput'>
              <span className='font-medium text-gray-700'>Page Title</span>
            </label>
            <div className='flex'>
              <input
                onChange={nameChangeHandler}
                id='slugInput'
                value={pageNameValue}
                className={`focus:ring-blue-lm block  ${
                  isSlugTaken ? 'border-red-500' : 'border-gray-200'
                } border-2 rounded-l-md sm:text-sm p-3 w-7/12`}
                type='text'
              />

              <button
                className=' items-center block px-4 py-2 border border-transparent rounded-r-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none  disabled:opacity-50'
                onClick={nameSelectedHandler}
                disabled={pageNameValue === '' ? true : false}
              >
                Next Step
              </button>
            </div>
            {isSlugTaken ? <p className='text-red-500'>Page title is already being used on your site, please provide a unique page title.</p> : null}
          </div>
        )}
      </div>
    </>
  );
};

export default GenerateTemplate;
