import { Approval } from 'models/approval';

type Props = {
  approval: Approval;
};

const StatusTag = ({ approval }: Props) => {
  const { approved, rejected, requested } = approval;

  return (
    <span
      className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium mb-2 flex-grow-0 ml-2 md:ml-0 ${
        approved ? 'bg-green-100 text-green-700 ' : rejected ? 'bg-red-100 text-red-700 ' : requested && 'bg-yellow-100 text-yellow-700 '
      }`}
    >
      {approved ? 'Approved' : rejected ? 'Rejected' : requested && 'Requested'}
    </span>
  );
};

export default StatusTag;
