import { IMedia, IMediaFolder } from 'models/media';
import React, { useState } from 'react';

import MediaListItem from './MediaListItem';
import BarLoader from 'react-spinners/BarLoader';
import { useMediaLibrary } from 'api/queries/useMediaLibrary';
import ChevronRight from 'components/Svg/ChevronRight';
import { useParams } from 'react-router-dom';
interface MediaFolderProps extends IMediaFolder {
  handleSelectedMedia: (media: IMedia) => void;
  isChild?: boolean;
}

const MediaFolder = ({ isChild, id, name, handleSelectedMedia }: MediaFolderProps) => {
  const { businessName } = useParams<{ businessName: string }>();

  const [isOpen, setOpen] = useState(false);
  const { data, isLoading } = useMediaLibrary(businessName, `childfolder-${id}`, id);

  const hasItems = data?.items.length ? (
    data?.items.map(mediaItem => <MediaListItem isChild={true} handleSelectedMedia={handleSelectedMedia} key={mediaItem.id} item={mediaItem} />)
  ) : isLoading ? (
    <BarLoader loading={true} />
  ) : (
    <p className='p-5'>No media</p>
  );

  return (
    <>
      <li className='cursor-pointer' onClick={() => setOpen(!isOpen)}>
        <div className='block hover:bg-gray-50'>
          <div className='flex items-center px-4 py-4 sm:px-6'>
            <div className='min-w-0 flex-1 flex items-center'>
              <div className='flex-shrink-0 text-gray-300'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className={`${isChild === true ? 'h-8 w-8' : 'h-12 w-12'}  shadow-sm rounded-md`}
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z' />
                </svg>
              </div>
              <div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4'>
                <div className='flex items-center col-span-2'>
                  <p className='text-md font-medium text-navy truncate'>{name}</p>
                </div>
              </div>
            </div>
            <div className={`${isOpen ? 'transform rotate-90' : ''} transition`}>
              <ChevronRight className={'text-gray-500'} />
            </div>
          </div>
        </div>
      </li>
      {isOpen ? hasItems : null}
    </>
  );
};

export default MediaFolder;
