import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';
type SaveModalProps = {
  open: boolean;
  closeModal: () => void;

  submitHandler: () => void;

  isMedia: boolean;
};
const DeleteModal = ({ open, closeModal, submitHandler, isMedia }: SaveModalProps) => {
  const cancelButtonRef = useRef<HTMLDivElement>(null);
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' initialFocus={cancelButtonRef} static open={open} onClose={closeModal}>
        <div className='min-h-screen px-4 text-center'>
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md min-h-300'>
              <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                Delete
              </Dialog.Title>
              {isMedia ? (
                <Dialog.Description className='text-grey-300'>
                  Are you sure you want to delete this media? If it's used anywhere on your website it'll result in a broken image.
                </Dialog.Description>
              ) : (
                <Dialog.Description className='text-grey-300'>Are you sure you want to delete this?</Dialog.Description>
              )}

              <div className='mt-4'>
                <button
                  type='button'
                  className='inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500'
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='inline-flex justify-center px-4 py-2  ml-3 text-sm font-medium text-white  bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500'
                  onClick={submitHandler}
                >
                  Yes please
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteModal;
