import React from 'react';

type Props = {
  children: React.ReactNode;
  heading: string;
};
const BusinessBoard = ({ children, heading }: Props) => {
  return (
    <>
      <div className='block mx-auto w-full bg-white px-5 pb-5 rounded-lg shadow mb-8 max-w-7xl'>
        <h2 className=' text-blue-lm text-2xl py-5 pl-5'>{heading}</h2>
        {children}
      </div>
    </>
  );
};

export default BusinessBoard;
