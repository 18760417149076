import React, { useState } from 'react';
import { MemberEditableDetail } from 'models/member';
import { Command, CommandBatch } from 'commands/Commands';

import PencilSvg from 'components/Svg/PencilSvg';

import MediaPickerDrawer from 'components/Media/MediaPickerDrawer';
import { IMedia } from 'models/media';

type TeamMemberDetailsEditCardProps = {
  detail: MemberEditableDetail;
  detailName: string;
  commands: CommandBatch;
  editCommand: (newValue: string) => Command;
};

const TeamMemberDisclosurePicker = ({ detail, commands, detailName, editCommand }: TeamMemberDetailsEditCardProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [editValue, setEditValue] = useState<string>(detail.value ?? '');

  const handleSelectedValue = (Media: IMedia) => {
    setEditValue(Media.url);
    commands.add(editCommand(Media.url));
    setIsEditing(false);
  };

  return (
    <>
      <div
        className={'relative rounded-lg  hover:shadow-md flex flex-row items-center space-x-3   hover:bg-gray-50 transition-all bg-white shadow-lg'}
      >
        <div className='flex items-center space-x-3 flex-1 px-6 py-5 '>
          <div className='flex-1 min-w-0'>
            <div className='focus:outline-none'>
              <span className=' inset-0' aria-hidden='true'></span>
              <p className={'text-md font-medium text-gray-700'}>{detailName}</p>
              {editValue ? (
                <>
                  <p className='mr-2 text-xs w-50'>{editValue}</p>
                </>
              ) : (
                <p className='text-gray-600'>No File Selected</p>
              )}
            </div>
          </div>
        </div>
        <div className='flex flex-none text-gray-400 pr-6'>
          <div onClick={() => setIsEditing(!isEditing)} className='ml-3 focus:outline-none cursor-pointer  z-20'>
            <PencilSvg />
          </div>
        </div>
      </div>
      {isEditing && <MediaPickerDrawer toggleHandler={() => setIsEditing(false)} onUmbracoMediaSelected={handleSelectedValue}  onLocalMediaSelected={handleSelectedValue} />}
    </>
  );
};

export default TeamMemberDisclosurePicker;
