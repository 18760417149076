import React, { useContext, useState } from 'react';
import { CommandBatch } from 'commands/Commands';
import { EditContext } from 'context/EditContext';
import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';
import { useParams } from 'react-router-dom';
import { useStates } from 'api/queries/useStates';
import { ChangeStates } from 'commands/business/ChangeStates';

type Props = {
  selectedStates: string[];
  commands: CommandBatch;
};

const BusinessStates = ({ selectedStates, commands }: Props) => {
  const { businessName } = useParams<{ businessName: string }>();
  const { data: states } = useStates(businessName);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { setDirty } = useContext(EditContext);
  const [selected, setSelected] = useState(selectedStates);

  const handleCheckedItem = (state: string) => {
    setDirty(true);
    let newArr = selected?.includes(state) ? selected.filter(selectedState => selectedState !== state) : [...selected!, state];

    setSelected(newArr);
    commands.add(new ChangeStates(newArr));
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
        <h3 className='block text-lg text-gray-700 p-1'>States:</h3>
        <div className='flex items-center'>
          {isEditing ? (
            <ul className='flex items-center flex-wrap'>
              {states?.map(state => (
                <li key={state} className='mx-5 text-gray-700'>
                  <label className='flex items-center'>
                    <input onChange={() => handleCheckedItem(state)} type='checkbox' value={state} checked={selected?.includes(state)} />
                    <span className='ml-2'>{state}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <ul className='list-disc flex itens-center flex-wrap'>
              {selected.map(state => (
                <li key={state} className='mx-5 text-gray-700'>
                  {state}
                </li>
              ))}
            </ul>
          )}
          <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} />
        </div>
      </div>
    </>
  );
};

export default BusinessStates;
