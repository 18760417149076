import { NavLink as ReactNavLink, useParams } from 'react-router-dom';
import logo from 'images/site-logo.svg';
import SignOut from './SignOut';
import { useContext } from 'react';
import { UserContext } from 'context/UserContext';
import PageSvg from 'components/Svg/PageSvg';
import ComputerSvg from 'components/Svg/ComputerSvg';
import BriefcaseSvg from 'components/Svg/BriefcaseSvg';
import SignalSvg from 'components/Svg/SignalSvg';
import GearSvg from 'components/Svg/GearSvg';
import PictureSvg from 'components/Svg/PictureSvg';
import PeopleSvg from 'components/Svg/PeopleSvg';
import ClipboardCheckSvg from 'components/Svg/ClipboardCheckSvg';
import HomeSvg from 'components/Svg/HomeSvg';

import StarsSvg from 'components/Svg/StarsSvg';
import Image from 'components/Image';
import ClipboardList from 'components/Svg/ClipboardList';
import AcademicSvg from 'components/Svg/AcademicSvg';
import { useBusinessAuth } from 'api/queries/useBusinessAuth';
import RightArrowSvg from 'components/Svg/RightArrowSvg';
import InfoSvg from 'components/Svg/InfoSvg';

type NavigationLinkProps = {
  icon: JSX.Element;
  text: string;
  to: string;
  exact?: boolean;
  external?: boolean;
};

const NavLink = ({ to, icon, text, exact, external = false }: NavigationLinkProps) => {
  if (external)
    return (
      <a
        className='text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md'
        href={to}
        target='_blank'
        rel='noreferrer'
      >
        {icon}
        {text}
      </a>
    );
  return (
    <ReactNavLink
      to={to}
      exact={exact}
      activeClassName='bg-gray-200 text-gray-900'
      className='text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md'
    >
      {icon}
      {text}
    </ReactNavLink>
  );
};

const NavLinks = () => {
  const { businessName } = useParams<{ businessName: string }>();
  const { user } = useContext(UserContext);
  const { data } = useBusinessAuth(businessName);
  const corporateUser = user.corporateUser;

  const corporateLinks = (
    <>
      <NavLink to={'/admin/businesses'} text={'Websites'} icon={<ComputerSvg className='text-gray-400 group-hover:text-gray-500 mr-3 ' />} />
      <NavLink
        to={'/admin/Approvals'}
        text={'Approval Dashboard'}
        icon={<ClipboardCheckSvg className='text-gray-400 group-hover:text-gray-500 mr-3 ' />}
      />
      <NavLink to={'/admin/redirects'} text={'Redirect Manager'} icon={<RightArrowSvg className='text-gray-400 group-hover:text-gray-500 mr-3 ' />} />
      <NavLink
        to={'/admin/page-template-list-viewer'}
        text={'Page Template Viewer'}
        icon={<ClipboardList className='text-gray-400 group-hover:text-gray-500 mr-3 ' />}
      />
    </>
  );

  return (
    <div className='flex flex-col h-0 flex-1 border-r border-gray-200 bg-blue-tint'>
      <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
        <div className='flex items-center flex-shrink-0 px-4 mt-4 mr-5 mb-8 padding'>
          <Image className='w-80 h-auto' src={logo} alt='Loan Market' />
        </div>
        <nav className='mt-5' aria-label='Sidebar'>
          <div className='px-2 space-y-1 flex flex-col justify-between relative'>
            {businessName && (
              <div>
                <NavLink
                  to={`/${businessName}`}
                  exact={true}
                  text={'Dashboard'}
                  icon={<HomeSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />}
                />
                <NavLink
                  to={`/${businessName}/my-business/`}
                  icon={<GearSvg className='text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6' />}
                  text={'My Business'}
                />
                <NavLink
                  to={`/${businessName}/pages`}
                  icon={<PageSvg className='text-gray-400 group-hover:text-gray-500 mr-3 w-6 h-6' />}
                  text='Pages'
                />
                <NavLink to={`/${businessName}/team`} icon={<PeopleSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />} text='Team' />
                <NavLink
                  to={`/${businessName}/blog-listing`}
                  icon={<SignalSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />}
                  text='Blog'
                />
                <NavLink
                  to={`/${businessName}/partners`}
                  icon={<BriefcaseSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />}
                  text='Partners'
                />
                <NavLink
                  to={`/${businessName}/awards`}
                  exact={true}
                  icon={<StarsSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />}
                  text='Awards'
                />
                <NavLink to={`/${businessName}/media`} icon={<PictureSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />} text='Media' />
                <NavLink
                  to={`/${businessName}/activity`}
                  icon={<ClipboardList className='text-gray-400 group-hover:text-gray-500 mr-3' />}
                  text='Activity'
                />

                {data && (
                  <NavLink
                    to={data!.trainingLink}
                    icon={<AcademicSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />}
                    text='Training'
                    external={true}
                  />
                )}

               { data?.slug && <NavLink
                  to={getSiteMapUrl(businessName, data?.slug, data?.previewUrl, data?.byob)}
                  icon={<InfoSvg className='text-gray-400 group-hover:text-gray-500 mr-3' />}
                  text='Sitemap'
                  external={true}
                />}
              </div>
            )}
          </div>
        </nav>
      </div>
      <div className='px-2 '>
        <div className='flex flex-col '>
          {corporateUser ? (
            corporateLinks
          ) : user.businesses.length >= 2 ? (
            <NavLink to={'/admin/businesses'} text={'Websites'} icon={<ComputerSvg className='text-gray-400 group-hover:text-gray-500 mr-3 ' />} />
          ) : null}
        </div>
        <SignOut />
      </div>
    </div>
  );
};

const getSiteMapUrl = (
  businessSlug: string,
  byobSlug: string,
  previewUrl: string,
  isByob: boolean = false,

) => {
  return isByob ? `https://${byobSlug}/sitemap` : `${previewUrl}/${businessSlug}/sitemap`
}
export default NavLinks;
