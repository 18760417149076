import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { IMedia, IMediaFolder } from 'models/media';
import { useQuery } from 'react-query';

// get media library Loan Market
export const useMediaLibrary = (businessName: string, key?: string, folderId: string = '') => {
  return useQuery<IMediaFolder, Error>(key ?? 'mediaLibrary', async () => {
    const userDetails = await getUserDetails();
    const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Media/${folderId}`, generateGetHeaders(userDetails, businessName));

    return data;
  });
};

export const useBusinessMediaLibrary = (businessName: string) => {
  return useQuery<IMedia[], Error>(`mediaLibrary-${businessName}`, async () => {
    const userDetails = await getUserDetails();
    const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Media/businessMedia`, generateGetHeaders(userDetails, businessName));
    return data;
  });
};

export const useApprovedBusinessMedia = (businessName: string) => {
  return useQuery<IMedia[], Error>(`approvedMediaLibrary-${businessName}`, async () => {
    const userDetails = await getUserDetails();
    const { data } = await axios.get(`${process.env.REACT_APP_API}/Portal/Media/ApprovedMedia`, generateGetHeaders(userDetails, businessName));
    return data;
  });
};
