import React from 'react';

import { useDerivedState } from 'hooks/useDerivedState';

type TextInputProps = {
  value: string;
  id: string;
  label: string;
  handleFieldValue: (value: string, id?: string) => void;
};

const TextInput = ({ value, label, id, handleFieldValue }: TextInputProps) => {
  const [inputValue, setInputValue] = useDerivedState(value || '');

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value;
    setInputValue(e.target.value);
    value = newValue;
    handleFieldValue(newValue, id);
  };

  return (
    <div className='space-y-3 w-full pt-5'>
      <label htmlFor={id} className='block text-md font-medium text-gray-700'>
        {label}
      </label>
      <input
        id={id}
        name='textField'
        type='text'
        placeholder=''
        value={inputValue ?? value}
        className='flex-1 focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 rounded-md sm:text-sm p-3'
        onChange={changeHandler}
      />
    </div>
  );
};

export default TextInput;
