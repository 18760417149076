import React, { useContext, useState } from 'react';
import TrashSvg from 'components/Svg/TrashSvg';

import { redirects } from 'models/redirect';
import PencilSvg from 'components/Svg/PencilSvg';
import { RemoveRedirect } from 'commands/redirect/RemoveRedirect';
import { useCommands } from 'hooks/useCommands';
import InputModal from 'components/Modals/InputModal';
import { EditContext } from 'context/EditContext';
import { useOktaAuth } from '@okta/okta-react';
import { v4 } from 'uuid';
import { UpdateRedirect } from 'commands/redirect/UpdateRedirect';
import { setTimeout } from 'timers';

type RedirectTableItemProps = {
  initialVal?: string;
  index: number;
  redirect: redirects;
};

const RedirectTableItem = (props: RedirectTableItemProps) => {
  const { oldslug, newslug } = props.redirect;
  const index = props.index;

  const { commands } = useCommands();
  const [okay, setOkay] = useState(false);
  const [update, setUpdate] = useState(false);

  const [value, setValue] = useState<string>(props.initialVal ? props.initialVal : '');
  const [updatevalue, setUpdateValue] = useState<string>(props.initialVal ? props.initialVal : '');

  const { setDirty } = useContext(EditContext);
  useOktaAuth();

  const [saveMessage, setSaveMessage] = useState<string>('Must match the current slug redirect');
  const [updateMessage, setUpdateMessage] = useState<string>('Must be a unique Slug or Redirect');

  function clearCache() {
    window.location.reload();
  }

  const handleSend = () => {
    var deleteinput = [];
    deleteinput.push(oldslug, newslug);
    commands.add(new RemoveRedirect(deleteinput), v4());
    commands.send();
    setOkay(false);
    setDirty(true);

    setTimeout(clearCache, 250);
  };

  const handleUpdateSend = () => {
    var updateinput = [];
    updateinput.push(oldslug, newslug, updatevalue);
    commands.add(new UpdateRedirect(updateinput), v4());
    commands.send();
    setUpdate(false);
    setDirty(true);

    setTimeout(clearCache, 250);
  };

  const slugIsValid = async (value: string) => {
    var match = false;
    value === oldslug ? (match = false) : (match = true);
    setSaveMessage(match ? 'Must match the oldslug slug redirect' : 'Delete');
    return match;
  };

  const updateSlugIsValid = async (value: string) => {
    var pass = false;
    value !== '' ? (pass = true) : (pass = false);
    pass === true ? (pass = false) : (pass = true);
    setUpdateMessage(pass ? 'Must be a valid redirect slug' : 'Update');
    return pass;
  };

  return (
    <>
      <tr className={`${index! % 2 === 0 ? 'bg-white' : ' bg-gray-50'}`}>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{oldslug}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className=''>
              <div className='text-sm font-medium text-gray-900 max-w-lg break-words'>{newslug}</div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4'>
          <div className='flex items-center'>
            <div className='bg-gray-200 px-2 pt-1 rounded-md'>
              <a className={'cursor-pointer  inline-block text-blue-200'} onClick={() => setUpdate(true)}>
                <PencilSvg outline />
              </a>
            </div>
            <div>
              <a className={'cursor-pointer inline-block ml-5 text-red-500'} onClick={() => setOkay(true)}>
                <TrashSvg />
              </a>
            </div>
          </div>
        </td>
      </tr>
      {okay ? (
        <InputModal
          saveText={saveMessage}
          title={'Delete Redirect'}
          description={`Enter in the old slug of the redirect to confirm delete.`}
          open={okay}
          inputValue={value}
          textArea={false}
          setInputValue={setValue}
          sendHandler={handleSend}
          customValidation={value => slugIsValid(value)}
          red={true}
          onClose={() => setOkay(false)}
        />
      ) : null}

      {update ? (
        <InputModal
          saveText={updateMessage}
          title={'Update Redirect'}
          description={`Enter in a new unique slug to update current redirect.`}
          open={update}
          inputValue={updatevalue}
          textArea={false}
          setInputValue={setUpdateValue}
          sendHandler={handleUpdateSend}
          customValidation={updatevalue => updateSlugIsValid(updatevalue)}
          red={true}
          onClose={() => setUpdate(false)}
        />
      ) : null}
    </>
  );
};

export default RedirectTableItem;
