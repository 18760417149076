import React, { useState, useContext } from 'react';
import { ChangeSocial } from 'commands/business/social/ChangeSocial';
import { CommandBatch, ICommand } from 'commands/Commands';
import { EditContext } from 'context/EditContext';
import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';
import { Recaptcha } from 'models/recaptcha';

type Props<TCommand extends ICommand> = {
  initialVal?: string;
  label?: string;
  commands: CommandBatch;
  showControls?: boolean;
  groupList?: Recaptcha[];
  commandFactory: (payload: string) => TCommand;
};
const DropDownList = ({ initialVal, label, commands, showControls = true, commandFactory, groupList }: Props<ChangeSocial>) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(initialVal ? initialVal : '');
  const { setDirty } = useContext(EditContext);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    commands.add(commandFactory(e.target.value));
    setDirty(true);
  };

  return (
    <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
      <h3 className='block text-lg text-gray-700 p-1'>{label}:</h3>
      <div className='flex'>
        {open ? (
          <select
            className='flex focus:ring-blue-lm focus:border-blue-lm w-full border-gray-200 border-2 rounded-md sm:text-sm p-1 mr-5 transition-all '
            onBlur={() => setOpen(false)}
            id='SelectCaptchaGroup'
            onChange={e => handleChange(e)}
            value={value}
          >
            <option value={''}>Default Settings</option>
            {groupList?.map(cg => (
              <option value={cg.gId} key={cg.gId}>
                {cg.gId}
              </option>
            ))}
          </select>
        ) : (
          <p className='px-3 pt-2'>{value}</p>
        )}
        {showControls ? <BusinessEditControls isEditing={open} setIsEditing={setOpen} /> : null}
      </div>
    </div>
  );
};

export default DropDownList;
