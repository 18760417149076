import { EntityType } from 'models/approval';

export const generateStartEditPath = (entityType: EntityType) => {
  return entityType === 'BlogArticle'
    ? 'blog-edit/'
    : entityType === 'Page'
    ? 'page-edit/'
    : entityType === 'Partner'
    ? 'partner-edit/'
    : entityType === 'Award'
    ? 'award-edit/'
    : entityType === 'Media'
    ? 'media/'
    : '';
};
