import { useApprovalSummaries } from 'api/queries/useApprovalSummaries';

import ApprovalLogTable from 'components/Admin/ApprovalLog/ApprovalLogTable';
import Layout from 'components/Layout/Layout';
import ErrorModal from 'components/Modals/ErrorModal';

const AdminApprovalScreen = () => {
  const { data, isError, error } = useApprovalSummaries();

  return (
    <Layout pageTitle='Approval dashboard'>
      <ErrorModal open={isError} error={error} />
      {data != null ? <ApprovalLogTable summaries={data} /> : <p>Loading items needing approval, please wait.....</p>}
    </Layout>
  );
};

export default AdminApprovalScreen;
