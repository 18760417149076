import React from 'react';
import { Partner } from 'models/partner';
import Image from 'components/Image';

const PartnerDrawerItem = ({ title, imageUrl, altText }: Partner) => {
  return (
    <div className='h-20 flex items-center w-full space-x-2'>
      <Image className='w-10 h-10 object-cover' src={imageUrl} alt={altText} />
      <p className='font-medium text-gray-800'>{title}</p>
    </div>
  );
};

export default PartnerDrawerItem;
