import { Dialog, Transition } from '@headlessui/react';

import WarningSvg from 'components/Svg/WarningSvg';
import React, { Fragment, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

type Props = {
  open: boolean;
  error: Error | null;
  errorMessage?: string;
};
const ErrorModal = (props: Props) => {
  const { businessName } = useParams<{ businessName: string }>();
  const [showMore, setShowMore] = useState(false);
  const [copyText, setCopyText] = useState('Copy to clipboard');
  const cancelButtonRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const handleClose = () => {
    if (businessName !== undefined) {
      history.push(`/${businessName}`);
    } else {
      history.push(`/`);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(props.error?.stack || '');
    setCopyText('Copied to clipboard!');
    setTimeout(() => {
      setCopyText('Copy to clipboard');
    }, 500);
  };

  return (
    <Transition show={props.open} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' initialFocus={cancelButtonRef} static open={props.open} onClose={handleClose}>
        <div className='min-h-screen px-4 text-center'>
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md min-h-300'>
              <Dialog.Title as='h3' className='text-xl font-medium leading-6 text-red-600 flex items-center'>
                <WarningSvg /> An error has occured
              </Dialog.Title>
              <Dialog.Description className='text-grey-300 my-3 '>
                <p>Error Message:</p>
                <span className='text-red-600 my-2 block '>{props.errorMessage ? props.errorMessage : props.error?.message}</span>
                {props.error && (
                  <div className='flex justify-between text-sm mb-1'>
                    <button className='text-gray-600 underline hover:text-gray-300' onClick={() => setShowMore(!showMore)}>
                      {showMore ? 'Show less' : 'More information'}
                    </button>
                    {showMore ? (
                      <button className='text-gray-600 underline hover:text-gray-300' onClick={handleCopy}>
                        {copyText}
                      </button>
                    ) : null}
                  </div>
                )}

                {showMore ? (
                  <div className='overflow-y-scroll w-full text-sm py-1 px-2 bg-gray-200 rounded h-52'>
                    {props.error?.stack ? props.error?.stack : props.error}
                  </div>
                ) : null}
              </Dialog.Description>

              <div className='mt-4'>
                <button
                  type='button'
                  className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 transition-all'
                  onClick={handleClose}
                >
                  Go back to home page
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ErrorModal;
