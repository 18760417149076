import Layout from 'components/Layout/Layout';

import BarLoader from 'react-spinners/BarLoader';
import NotificationMessage, { NotificationStyles } from 'components/Notification';

import AwardDrawerItem from 'components/Awards/AwardDrawerItem';
import AwardCard from 'components/Awards/AwardCard';
import ReorderableSelectedList from 'components/ReorderableSelectedList';
import { AddAwardToBusiness } from 'commands/award/AddAwardToBusiness';
import { RemoveAwardFromBusiness } from 'commands/award/RemoveAwardFromBusiness';
import { useParams } from 'react-router-dom';
import AddHeader from 'components/Layout/AddHeader';

import { useAvailableAwards, useSelectedAwards } from 'api/queries/useAwards';
import ErrorModal from 'components/Modals/ErrorModal';
import { useCommands } from 'hooks/useCommands';
import { SetOrder } from 'commands/award/SetOrder';
import { useEffect } from 'react';

const AwardsScreen = () => {
  const { commands, showError, showSuccess, errorMessage, setShowError, setShowSuccess } = useCommands();
  const { businessName } = useParams<{ businessName: string }>();

  const {
    data: selectedAwardsData,
    isLoading: isSelectedAwardsLoading,
    isError: isSelectedError,
    error: selectedError
  } = useSelectedAwards(businessName);

  const {
    data: availableAwardsData,
    isLoading: isAvailableAwardsLoading,
    isError: isAvailableError,
    error: availableError
  } = useAvailableAwards(businessName);

  useEffect(() => {
    if (showSuccess) {
      window.location.reload();
    }
  }, [showSuccess]);
  return (
    <>
      <Layout pageTitle={'Your Awards'} buttons={<AddHeader linkText='Create award' linkUrl={`/${businessName}/award-create`} />}>
        <div className='pb-5 mx-auto w-9/12'>
          <NotificationMessage type={NotificationStyles.Danger} show={showError} setShow={setShowError} message={errorMessage!} />

          <ErrorModal open={isSelectedError || isAvailableError} error={selectedError || availableError} />

          <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='Successful!' />
          <div className='relative max-w-7xl mx-auto'>
            <BarLoader height='4px' width='100%' loading={isAvailableAwardsLoading || isSelectedAwardsLoading} color='#00AAE5' />
            <ReorderableSelectedList
              itemName='award'
              removeCommand={item => new RemoveAwardFromBusiness(item.id)}
              addCommand={item => new AddAwardToBusiness(item.id)}
              drawerComponent={AwardDrawerItem}
              commands={commands}
              selectedData={selectedAwardsData!}
              availableData={availableAwardsData!}
              reorderCommand={reorderedList => new SetOrder(reorderedList)}
              reorderableCard={AwardCard}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AwardsScreen;
