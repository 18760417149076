import PencilSvg from 'components/Svg/PencilSvg';
import EyeSvg from 'components/Svg/EyeSvg';
import CheckSvg from 'components/Svg/CheckSvg';
import CloseSvg from 'components/Svg/CloseSvg';
import { Member } from 'models/member';
import Image from 'components/Image';
import TrashSvg from 'components/Svg/TrashSvg';
import { useContext, useState } from 'react';
import { UserContext } from 'context/UserContext';
import DialogModal from 'components/Modals/DialogModal';
import { useCommands } from 'hooks/useCommands';
import { useLeadBroker } from 'api/queries/useLeadBroker';
import { useParams } from 'react-router-dom';
import DeleteTeamMember from 'commands/teamMembers/DeleteTeamMember';
import { v4 } from 'uuid';

interface TeamMemberCardProps {
  member: Member;
  handleShowHide: (id: string) => void;
  toggleHandler: () => any;
  promoteHandler: (id: string) => void;
}
const TeamMemberCard = ({ member, handleShowHide, toggleHandler, promoteHandler }: TeamMemberCardProps) => {
  const { user } = useContext(UserContext);
  const [del, setDel] = useState(false);
  const [err, setErr] = useState(false);
  const { businessName } = useParams<{ businessName: string }>();
  const { commands } = useCommands();
  const { data } = useLeadBroker(businessName);

  const handleDel = () => {
    setDel(true);
  };

  function clearCache() {
    window.location.reload();
  }

  const handleErr = () => {
    setErr(false);
    setTimeout(clearCache, 500);
  };

  const delSend = () => {
    setDel(false);
    const input = [];
    input.push(businessName, member.employeeId);
    if (member.myCrmId === data?.toString()) {
      setErr(true);
    } else {
      commands.add(new DeleteTeamMember(input), v4()).send();
      setTimeout(clearCache, 750);
    }
  };

  return (
    <div
      className={`relative rounded-lg  hover:shadow-md flex flex-row items-center focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 hover:bg-gray-50 transition-all h-full ${
        !member.visible ? 'bg-gray-100' : 'bg-white shadow-lg'
      }`}
    >
      <div className='flex items-center space-x-3 flex-1 px-6 py-5 '>
        <div className='flex-shrink-0'>
          <Image className='h-10 w-10 rounded-full object-cover' src={member.imageUrl} alt='' />
        </div>
        <div className='flex-1 min-w-0'>
          <span className='absolute inset-0' aria-hidden='true'></span>
          <p className='text-md text-gray-700 font-medium'>{member.name}</p>
          <p className='text-sm text-gray-500 truncate'>{member.role.value}</p>
        </div>
      </div>
      <div className='sm:flex sm:justify-between h-full'>
        <button className='z-20 focus:outline-none' onClick={() => promoteHandler(member.employeeId)}>
          <div className='flex flex-col h-full px-9 items-center ml-5  text-sm text-gray-500 sm:mt-0 justify-center '>
            <span className='text-gray-400'>{member.editor ? <CheckSvg /> : <CloseSvg />}</span>
            <p className='font-medium'>Editor</p>
          </div>
        </button>
      </div>
      <div className='flex-none text-gray-400'>
        <div className='flex flex-row space-x-11'>
          <button className='z-20 focus:outline-none ' onClick={() => handleShowHide(member.employeeId)}>
            <EyeSvg isHidden={!member.visible} />
          </button>
        </div>
      </div>
      <div className='sm:flex sm:justify-between h-full'>
        <button className='z-20 focus:outline-none ' onClick={toggleHandler}>
          <div className='flex h-full px-9 items-center ml-5  text-sm text-gray-500 sm:mt-0 bg-gray-100 rounded-l-lg '>
            <span className='text-navy'>
              <PencilSvg />
            </span>
          </div>
        </button>
      </div>
      {user.corporateUser === true ? (
        <div className='sm:flex sm:justify-between h-full'>
          <button className='z-20 focus:outline-none ' onClick={handleDel}>
            <div className='flex h-full px-9 items-center rounded-r-lg bg-red-100 text-sm text-red-500 sm:mt-0'>
              <span className='text-red-500'>
                <TrashSvg />
              </span>
            </div>
          </button>
        </div>
      ) : null}
      {del === true ? (
        <DialogModal
          open={del}
          title='Warning - You are deleting a Team Member'
          actionText={'Delete Team Member'}
          closeModal={() => setDel(false)}
          description={'Deleting the Team Member is permenant, they must also not be the current lead broker of the business.'}
          submitHandler={delSend}
          isRed={true}
        />
      ) : err === true ? (
        <DialogModal
          open={err}
          title='Error - Unable to delete team member'
          actionText={'Continue'}
          closeModal={() => setErr(false)}
          description={
            'Unable to delete Team Member as they are the lead broker, they must first be removed from the lead broker setting on the "My Business" page.'
          }
          submitHandler={handleErr}
        />
      ) : null}
    </div>
  );
};

export default TeamMemberCard;
