import { useState } from 'react';

import DeleteModal from './ButtonModals/DeleteModal';
import TrashSvg from 'components/Svg/TrashSvg';
import { useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { useHistory } from 'react-router-dom';

type DeleteProps = {
  addDeleteCommand: () => void;
  isDropDown?: boolean;
  autoClear: boolean;
  fullWidth?: boolean;
  isMedia: boolean;
  setSmall?: boolean;
  useModal?: boolean;
};
const DeleteButton = ({ addDeleteCommand, isDropDown, autoClear, fullWidth, isMedia, setSmall = false, useModal = true }: DeleteProps) => {
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const history = useHistory();
  // Button will only be accessible to corporate users
  // Button should open a modal to input a message as to why this item was rejected

  const deleteHandler = () => {
    addDeleteCommand();
    if (autoClear === true) {
      history.goBack();
    }
    if (isMedia === true) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setOpen(false);
  };
  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  if (!user.corporateUser) {
    return null;
  }

  return (
    <>
      {isDropDown ? (
        <button onClick={openModal} className=' flex rounded-md items-center w-full px-2 py-2 text-sm text-red-500 hover:bg-red-100'>
          <TrashSvg />
        </button>
      ) : (
        <div className={`${setSmall ? '' : 'w-full'}`}>
          <button
            onClick={openModal}
            className={`inline-flex my-2 items-center py-2 border disabled:opacity-50 bg-red-500 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
              fullWidth ? ' w-full items-center mx-0' : 'px-4 mx-2 '
            }`}
          >
            <TrashSvg className='mx-auto' />
          </button>
        </div>
      )}
      {useModal ? <DeleteModal open={open} closeModal={closeModal} submitHandler={deleteHandler} isMedia={isMedia} /> : null}
    </>
  );
};

export default DeleteButton;
