import React, { lazy, useEffect, useState } from 'react';
import { CommandBatch } from 'commands/Commands';
import { Field, FieldType } from 'models/Field';
import { ChangeValue } from 'commands/ChangeValue';
import ToggleInput from './BooleanInput';
import { fieldValidator } from 'components/util/fieldValidator';

type BlockProps = {
  alias: string;
  fields: Field[];
  buId: string;
  commands: CommandBatch;
  blockState: { blockId: string; isValid: boolean }[];
  setBlockState: React.Dispatch<
    React.SetStateAction<
      {
        blockId: string;
        isValid: boolean;
      }[]
    >
  >;
};

const valueFields = (field: Field) => field.name !== 'bd_Show';
const blockToggle = (field: Field) => field.name === 'bd_Show';

// Import input components based on Input Type
const importInput = (inputType: FieldType) =>
  lazy(() => {
    const inputName = FieldType[inputType]; // do not remove, required for treeshaker to keep this import
    return import(`../Inputs/${inputName}Input`).catch(() => import(`./NullView`));
  });

const Block = ({ alias, fields, commands, buId, blockState, setBlockState }: BlockProps) => {
  const blockId = buId;
  // Check if block have toggle
  const hasToggle = fields.some(blockToggle);
  const storedBdShow = fields.find(blockToggle)?.value;

  // Convert show value into boolean
  const showValueOf = (bdShowValue: string | null | undefined) => (bdShowValue !== 'false' ? true : false);

  const [inputs, setInputs] = useState<object>();
  const [showBlock, setShowBlock] = useState(showValueOf(storedBdShow));
  const [fieldState, setFieldState] = useState(fields);

  const handleToggleBlock = (id: string, showOrHide: boolean) => {
    commands.add(new ChangeValue(id, showOrHide.toString().toLowerCase()));

    const blockFieldsValid = fieldValidator(fieldState);
    const blockToChange = { blockId: buId, isValid: !showOrHide || blockFieldsValid };

    setShowBlock(showOrHide);
    setBlockState([...blockState.filter(block => block.blockId !== blockId), blockToChange]);
  };

  const handleFieldValue = (value: string, id: string, fieldType?: FieldType) => {
    const modifiedFields = [...fields];
    const fieldIndex = fieldState.findIndex(field => field.id === id);
    const modifiedField = { ...modifiedFields[fieldIndex] };

    modifiedField.value = value;
    modifiedFields[fieldIndex] = modifiedField;

    if (fieldType !== FieldType.Media) {
      commands.add(new ChangeValue(id, value));
    }
    fields = modifiedFields; // Sync props with modified fields

    const blockFieldsValid = fieldValidator(modifiedFields);

    let blockValidation: boolean;

    if (showBlock) {
      blockValidation = hasToggle && blockFieldsValid;
    } else {
      blockValidation = blockFieldsValid;
    }

    const blockToChange = {
      blockId: blockId,
      isValid: hasToggle ? blockValidation : true
    };

    setFieldState(modifiedFields);
    setBlockState(prevState => [...prevState.filter(block => block.blockId !== blockId), blockToChange]);
  };

  useEffect(() => {
    setBlockState([
      ...blockState.filter(block => block.blockId !== blockId),
      {
        blockId: buId,
        isValid: showBlock === false || (fieldValidator(fields) && !!showBlock)
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Ignore warning that is caused here

  useEffect(() => {
    async function loadInputs() {
      // Map over fields for import and add them to state
      const componentPromises = fields.filter(valueFields).map(async field => {
        const Input = await importInput(field.type);
        return (
          <Input
            mediaName={field.mediaName}
            id={field.id}
            handleFieldValue={handleFieldValue}
            label={field.displayName}
            value={field.value || ''}
            allowedValues={field.allowedValues}
            commands={commands}
            key={field.id}
            isField={true}
            transformToCommand={(newValue: string) => new ChangeValue(field.id, newValue)}
          />
        );
      });
      Promise.all(componentPromises).then(setInputs);
    }
    loadInputs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Ignore warning that is caused here

  const borderChanger = () => {
    if (showBlock === false || (fieldValidator(fieldState) && showBlock)) {
      return 'border-gray-200';
    } else {
      return 'border-yellow-300';
    }
  };

  const warning = () => {
    if (showBlock === false || (fieldValidator(fieldState) && showBlock)) {
      return null;
    } else {
      return <span className='text-yellow-400 absolute right-10 top-2'>Empty fields will fallback to default</span>;
    }
  };

  return (
    <fieldset className={`relative border-solid border-2 pt-1 px-5 pb-5 rounded mx-5 max-w-7xl ${hasToggle ? borderChanger() : ''}`}>
      {hasToggle ? warning() : null}
      <legend className='p-2 font-bold text-gray-500 text-lg'>{alias}</legend>
      {hasToggle && (
        <>
          <ToggleInput
            id={fields.filter(field => field.name === 'bd_Show')[0].id}
            handleToggleBlock={handleToggleBlock}
            commands={commands}
            isBlockToggle={true}
            label={showBlock ? 'shown' : 'hidden'}
            value={showBlock}
          />
          <div className='w-full border-gray-200 border-b-2 mt-4' />
        </>
      )}
      <React.Suspense fallback='loading...'>{inputs}</React.Suspense>
    </fieldset>
  );
};

export default Block;
