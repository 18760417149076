import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';
import axios from 'axios';
import { Member } from 'models/member';
import { useQuery } from 'react-query';

// get team members
export const useTeamMembers = (businessName: string) => {
  return useQuery<Member[], Error>(
    'teamMembers',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Businesses/Team`,
        generateGetHeaders(userDetails, businessName)
      );
      return data;
    },
    { enabled: !!businessName, refetchOnWindowFocus: false }
  );
};
