import React, { useContext, useEffect, useState } from 'react';
import { Command, CommandBatch } from 'commands/Commands';

import { EditContext } from 'context/EditContext';
import { Award } from 'models/award';
import { Partner } from 'models/partner';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import AddDrawer from './AddDrawer/AddDrawer';

import { rePositionItem } from './util/rePositionItem';
import CheckSvg from './Svg/CheckSvg';
import InformationCard from './InformationCard';
import DialogModal from './Modals/DialogModal';

interface ReorderableSelectedListProps<T extends Award | Partner> {
  commands: CommandBatch;
  selectedData: T[];
  availableData: T[];
  itemName: string;
  addCommand: (item: T) => Command;
  removeCommand: (item: T) => Command;
  drawerComponent: (data: T) => JSX.Element;
  reorderableCard: (data: T) => JSX.Element;
  reorderCommand: (reorderedList: {}[]) => Command;
}

const ReorderableSelectedList = <T extends Award | Partner>(props: ReorderableSelectedListProps<T>) => {
  const {
    selectedData: originalSelected,
    availableData: originalAvailable,
    itemName,
    commands,
    addCommand,
    removeCommand,
    reorderableCard,
    reorderCommand
  } = props;
  const [toggleAddDrawer, setToggleAddDrawer] = useState<boolean>(false);
  const [reorderedDataItems, setReorderedDataItems] = useState<T[]>();
  const [openModal, setOpenModal] = useState(false);
  const [availableItems, setAvailableItems] = useState<T[]>(originalAvailable);
  const [selectedItems, setSelectedItems] = useState<T[]>(originalSelected);
  const [allItems, setAllItems] = useState<T[]>();

  const { setDirty, isDirty } = useContext(EditContext);

  useEffect(() => {
    if (originalSelected !== undefined && originalAvailable !== undefined) {
      setAllItems([...originalSelected!, ...originalAvailable!]);
      setSelectedItems(originalSelected);
      setAvailableItems(originalAvailable);
    }
  }, [originalSelected, originalAvailable]);

  const handleRemoveItems = React.useCallback(
    (itemToRemove: T) => {
      const newSelectedItems = selectedItems!.filter(item => item !== itemToRemove);
      const newAvailableItems = allItems!.filter(item => !newSelectedItems.includes(item));
      setSelectedItems(newSelectedItems);
      setAvailableItems(newAvailableItems);
      setDirty(true);
      commands.add(
        reorderCommand(
          newSelectedItems.map((item, index) => {
            return { reorderedId: item.id, order: index };
          })
        )
      );
    },
    [allItems, selectedItems, setDirty, commands, reorderCommand]
  );

  const handleAddItems = (itemsToAdd: T[]) => {
    const newSelectedItems = [...selectedItems!, ...itemsToAdd];
    const newAvailableItems = availableItems!.filter(item => !itemsToAdd.includes(item));
    setSelectedItems(newSelectedItems);
    setAvailableItems(newAvailableItems);
    setDirty(true);
    setToggleAddDrawer(false);
    commands.add(
      reorderCommand(
        newSelectedItems.map((item, index) => {
          return { reorderedId: item.id, order: index };
        })
      )
    );
  };

  const handleOnDragEnd = (dropResult: DropResult) => {
    const reorderedData = rePositionItem(dropResult, reorderedDataItems!);
    setReorderedDataItems(reorderedData);
    commands.add(
      reorderCommand(
        reorderedData.map((item, index) => {
          return { reorderedId: item.id, order: index };
        })
      )
    );
  };

  const handlePublish = () => {
    const addedItems = selectedItems.filter(item => !originalSelected!.includes(item));
    const removedItems = originalSelected!.filter(item => !selectedItems.includes(item));
    addedItems.forEach(item => commands.add(addCommand(item)));
    removedItems.forEach(item => commands.add(removeCommand(item)));

    commands.send();
  };

  useEffect(() => {
    setReorderedDataItems(
      selectedItems?.map(item => {
        return {
          ...item,
          handleRemoveItems: () => handleRemoveItems(item)
        };
      })
    );
  }, [allItems, selectedItems, handleRemoveItems]);

  return (
    <div>
      <InformationCard>
        Use this page to manage the {itemName}s that are displayed on your website. To create a new {itemName} click the{' '}
        <strong>Create new {itemName}</strong> button. To add the {itemName} to your website click on the <strong>Choose displayed {itemName}</strong>{' '}
        button. To change the order your {itemName}s are displayed on your website drag and drop the tiles below into your preferred order. Click on
        the pen icon to edit the {itemName} information or the cross to delete them.
      </InformationCard>
      <div className='flex flex-row items-center mb-6 justify-end '>
        <div className='flex mb-3 justify-end'>
          <button
            className='inline-flex items-center px-4 py-2 my-2 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
            disabled={availableItems && availableItems!.length <= 0}
            onClick={() => setToggleAddDrawer(true)}
          >
            Choose displayed {itemName}s
          </button>
          <button
            onClick={() => setOpenModal(true)}
            disabled={!isDirty}
            className='inline-flex items-center px-4 py-2 my-2 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-lm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50'
          >
            <div className='justify-center flex mx-auto items-center'>
              <CheckSvg />
              <span>Save order</span>
            </div>
          </button>
        </div>
      </div>
      <div className='relative max-w-7xl mx-auto'>
        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={() => setDirty(true)}>
          <Droppable droppableId={itemName}>
            {provided => (
              <div className='justify-center' {...provided.droppableProps} ref={provided.innerRef}>
                {reorderedDataItems?.map((child: T, index: number) => {
                  return (
                    <Draggable key={child.id} draggableId={child.id} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {reorderableCard(child)}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {toggleAddDrawer && (
          <AddDrawer
            title={` ${itemName}s`}
            description={`Click on the ${itemName}s you would like to display on your website then click add`}
            data={availableItems!}
            addHandler={handleAddItems}
            toggleHandler={() => setToggleAddDrawer(false)}
            drawerItem={(item: T) => props.drawerComponent(item)}
          />
        )}
        <DialogModal
          open={openModal}
          description='Are you sure you want to publish changes?'
          title={`Publish displayed ${itemName}s`}
          actionText={`Publish ${itemName}s`}
          submitHandler={handlePublish}
          closeModal={() => setOpenModal(false)}
        />
      </div>
    </div>
  );
};

export default ReorderableSelectedList;
