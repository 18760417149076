import { generateGetHeaders } from 'api/generateGetHeaders';
import { getUserDetails } from 'api/getUserDetails';

import axios from 'axios';
import { BusinessDetails } from 'models/business';
import { useQuery } from 'react-query';

// Get individual business details
export const useBusinessDetails = (businessSlug: string) => {
  return useQuery<BusinessDetails, Error>(
    'businessDetails',
    async () => {
      const userDetails = await getUserDetails();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/Portal/Businesses/`,
        generateGetHeaders(userDetails, businessSlug)
      );
      return data;
    },
    {
      enabled: true,
      refetchOnWindowFocus: false
    }
  );
};
